import React, { useEffect, useState } from 'react';
import { Button, Form, message, Row, Select } from 'antd';
import SubjectPoint from './subject-point';
import { connect } from 'react-redux';
import { updateStudentTablePoint } from 'modules/Register/service';
import TablePointImages from './table-point-images';
import { get, uniq } from 'lodash';
import store from 'app/store';
import { getErrorMessages } from 'app/utils/functions';

const FOREIGN_LANGUAGES = [
  'subject_english',
  'subject_german',
  'subject_japanese',
  'subject_french',
  'subject_chine',
  'subject_russian',
  'subject_other_language_1',
  'subject_other_language_2',
];

const StudentTablePoint = ({
  languageSubjects = [
    {
      id: 12,
      key: 'subject_english',
      value: 'Tiếng Anh',
    },
    {
      id: 100,
      key: 'subject_german',
      value: 'Tiếng Đức',
    },
    {
      id: 101,
      key: 'subject_japanese',
      value: 'Tiếng Nhật',
    },
    {
      id: 102,
      key: 'subject_french',
      value: 'Tiếng Pháp',
    },
    {
      id: 103,
      key: 'subject_chine',
      value: 'Tiếng Trung',
    },
    {
      id: 104,
      key: 'subject_russian',
      value: 'Tiếng Nga',
    },
    {
      id: 105,
      key: 'subject_other_language_1',
      value: 'Ngoại ngữ khác 1',
      type: 'other',
      typeTitle: 'Tên ngoại ngữ khác 1',
    },
    {
      id: 106,
      key: 'subject_other_language_2',
      value: 'Ngoại ngữ khác 2',
      type: 'other',
      typeTitle: 'Tên ngoại ngữ khác 2',
    },
  ],
  subjects = [
    {
      id: 10,
      key: 'subject_math',
      value: 'Toán',
    },
    {
      id: 9,
      key: 'subject_physics',
      value: 'Vật lý',
    },
    {
      id: 17,
      key: 'subject_chemistry',
      value: 'Hóa học',
    },
    {
      id: 14,

      key: 'subject_biology',
      value: 'Sinh học',
    },
    {
      id: 11,
      key: 'subject_informatic',
      value: 'Tin học',
    },
    {
      id: 15,
      key: 'subject_literature',
      value: 'Ngữ văn',
    },
    {
      id: 16,

      key: 'subject_history',
      value: 'Lịch sử',
    },
    {
      id: 19,
      key: 'subject_geography',
      value: 'Địa lý',
    },
    {
      id: 20,
      key: 'subject_technology',
      value: 'Công nghệ',
    },
    {
      id: 21,
      key: 'subject_civic',
      value: 'GDCD',
    },
  ],
  grades = [10, 11, 12],
  onSuccess,
  onError,
  onValuesChange,
  showSaveButton = true,
  user,
  disableHocky,
  form,
}) => {
  const initialValues = {
    ...get(user, 'table_point.points'),
    ...get(user, 'table_point.images'),
  };

  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const initialValues = {
      ...get(user, 'table_point.points'),
      ...get(user, 'table_point.images'),
    };

    const defaultSelectedLanguage = Object.keys(initialValues)
      .map((subjectKey) => {
        const haveData = FOREIGN_LANGUAGES.find((key) =>
          subjectKey.includes(key),
        );

        if (haveData) {
          return haveData;
        }

        return null;
      })
      .filter((i) => !!i);

    setSelectedLanguage(uniq(defaultSelectedLanguage));
    form.setFieldsValue({ languages: uniq(defaultSelectedLanguage) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [tablePointImages, setTablePointImages] = useState({});

  useEffect(() => {
    if (user?.table_point?.images) {
      const tmp = {};

      Object.keys(user?.table_point?.images).forEach((key) => {
        tmp[key] = {
          photo: user?.table_point?.images[key],
          photoFile: null,
        };
      });

      setTablePointImages(tmp);
    }
  }, [user]);

  const selectLanguageOptions = languageSubjects.map((i) => ({
    value: i.key,
    label: i.value,
  }));

  const updateTablePoint = async (values) => {
    setSaving(true);
    const tablePointImageFiles = {};
    grades.forEach((grade) => {
      const name = `table-point-image-${grade}`;
      if (tablePointImages[name]) {
        tablePointImageFiles[name] = tablePointImages[name].photoFile;
      }
    });

    const tablePoint = {};

    Object.keys(values).forEach((key) => {
      if (key.indexOf('table-point-image') < 0) {
        tablePoint[key] = values[key];
      }
    });

    delete tablePoint.languages;

    const result = await updateStudentTablePoint(
      user.id,
      tablePoint,
      tablePointImageFiles,
    );

    setSaving(false);

    if (result.data.code === 200) {
      if (onSuccess) {
        onSuccess(result.data.data);
      } else {
        message.success('Cập nhật bảng điểm thành công');
      }
      store.dispatch({
        type: 'UPDATE_USER',
        user: {
          ...user,
          table_point: result.data.data.table_point,
        },
      });

      return result.data.data;
    } else {
      if (onError) {
        onError(result.data);
      } else {
        message.error(getErrorMessages(result.data));
      }
    }

    return false;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        updateTablePoint(values);
      }}
      onValuesChange={(values) => {
        onValuesChange && onValuesChange(values);
      }}
      initialValues={initialValues}
    >
      <div className="legend">Nhập bảng điểm</div>
      {showSaveButton && (
        <p>Lưu ý: Đối tượng thí sinh tự do cần nhập điểm Học kỳ 2 Lớp 12</p>
      )}
      {subjects.map((subject, index) => {
        return (
          <SubjectPoint
            key={index}
            subject={subject}
            grades={grades}
            disableHocky={disableHocky}
            showSaveButton={showSaveButton}
          />
        );
      })}
      <div className="section-vnu">
        <Row>
          {selectLanguageOptions.length > 0 && (
            <Form.Item
              name="languages"
              label="Ngoại ngữ (Có thể chọn nhiều)"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngoại ngữ',
                },
              ]}
            >
              <Select
                mode="multiple"
                options={selectLanguageOptions}
                style={{ width: '100%', minWidth: 260, maxWidth: '100%' }}
                value={selectedLanguage}
                onChange={(value) => {
                  setSelectedLanguage(value);
                }}
                placeholder="Chọn ngoại ngữ"
              />
            </Form.Item>
          )}
        </Row>
      </div>

      {languageSubjects.map((subject, index) => {
        return selectedLanguage.includes(subject.key) ? (
          <SubjectPoint
            key={index}
            subject={subject}
            grades={grades}
            disableHocky={disableHocky}
            showSaveButton={showSaveButton}
          />
        ) : null;
      })}
      <TablePointImages
        form={form}
        initialValues={user.table_point}
        setTablePointImages={setTablePointImages}
        tablePointImages={tablePointImages}
        grades={grades}
      />
      {showSaveButton && (
        <Button type="primary" htmlType="submit" loading={saving}>
          Cập nhật bảng điểm
        </Button>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(StudentTablePoint);
