import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { createPartner, getPartner } from '../service';
import { Button, Form, Input, Select, Card, message } from 'antd';
import { getErrorMessages } from '../../../app/utils/functions';
import config from '../../../app/config';

const { Option } = Select;

class PartnerForm extends Component {
  formRef = React.createRef();

  state = {
    tooltipOpen: false,
    id: null,
    loading: false,
    partner: {},
    saveLoading: false,
  };

  componentDidMount() {
    const id =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null;
    if (id) {
      this.setState({ id });
      this.getPartnerInfo(id);
    }
  }

  getPartnerInfo = async (id) => {
    this.setState({ loading: true });
    const response = await getPartner(id);
    if (response.data.code == 200) {
      const partner = response.data.data.partner;
      partner.status = `${partner.status}`;
      this.setState({ partner }, () => {
        this.formRef.current.setFieldsValue(partner);
      });
    } else {
      message.error('Có lỗi xảy ra, vui lòng thử lại');
      this.props.history.push('/partners');
    }

    this.setState({ loading: false });
  };

  back = () => {
    this.props.history.push('/partners');
  };

  onFinish = async (values) => {
    this.setState({ saveLoading: true });
    const data = values;

    if (this.state.id) {
      data['id'] = this.state.id;
      delete data.service_code;
    }

    data.status = parseInt(data.status);

    const response = await createPartner(values);
    if (response.data.code == 200) {
      this.setState(
        {
          saveLoading: false,
        },
        () => {
          this.props.history.push('/partners');
        },
      );
    } else {
      this.setState(
        {
          saveLoading: false,
        },
        () => {
          if (
            typeof response.data.errors != 'undefined' &&
            Object.keys(response.data.errors).length > 0
          ) {
            Object.values(response.data.errors).forEach((element) => {
              message.error(element);
            });
          } else {
            message.error(getErrorMessages(response.data));
          }
        },
      );
    }
  };

  copy = () => {
    navigator.clipboard.writeText(this.state.partner.secret_code);
    message.success(`Đã copy mã bảo bật`);
  };

  changeVal = (_, allValues) => {
    this.setState({
      partner: allValues,
    });
  };

  render() {
    const { id, loading, saveLoading, partner } = this.state;

    return (
      <Container className="page-exam">
        <Row>
          <Col lg={12}>
            <div className="social__header">
              <div className="social__title">
                <span className="title-classes title-classes-light">
                  {id !== null ? 'Sửa' : 'Thêm'} đối tác
                </span>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col>
          <Col lg={6}>
            <Card>
              <Form
                layout="vertical"
                name="basic"
                className="partner-form"
                initialValues={partner}
                onValuesChange={this.changeVal}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
                autoComplete="off"
              >
                {loading && <>...</>}
                {!loading && (
                  <>
                    <Form.Item
                      name="name"
                      label="Tên đối tác"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập tên đối tác',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Row>
                      <Col lg={8}>
                        <Form.Item
                          name="service_code"
                          label="Mã đối tác"
                          normalize={(value) => value.toUpperCase()}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập mã đối tác',
                            },
                            {
                              min: 2,
                              max: 20,
                              message: 'Mã đối tác từ 2 tới 20 ký tự',
                            },
                            {
                              min: 2,
                              max: 20,
                              pattern: /^[A-Z0-9]*$/,
                              message: 'Mã đối tác viết in hoa, liền nhau',
                            },
                          ]}
                        >
                          <Input disabled={id !== null} />
                        </Form.Item>
                      </Col>
                      <Col lg={4}>
                        <Form.Item
                          name="status"
                          label="Trạng thái"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng chọn trạng thái',
                            },
                          ]}
                        >
                          <Select>
                            <Option value=""> -- Chọn trạng thái -- </Option>
                            <Option value="1">Hoạt động</Option>
                            <Option value="0">Dừng hoạt động</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    {id !== null && Object.keys(partner).length > 0 && (
                      <>
                        <Button
                          className="mr-2 mb-2 b-r-4 color-light"
                          onClick={() => this.copy()}
                        >
                          Copy mã
                        </Button>
                        <Button className="b-r-4 mb-2">
                          Mã bảo mật:&nbsp;
                          {this.state.tooltipOpen ? (
                            <b className="ml-2">
                              {this.state.partner.secret_code}
                            </b>
                          ) : (
                            '******'
                          )}
                          &nbsp;
                          <span
                            className="ml-2 secondary"
                            onClick={() =>
                              this.setState({
                                tooltipOpen: !this.state.tooltipOpen,
                              })
                            }
                          >
                            {this.state.tooltipOpen ? 'Ẩn' : 'Hiện'}
                          </span>
                        </Button>
                      </>
                    )}

                    <Form.Item label="Mô tả" name="description">
                      <Input.TextArea rows={4} />
                    </Form.Item>

                    <Col lg={12}>
                      <Row style={{ justifyContent: 'space-between' }}>
                        <Button
                          type="default"
                          size="large"
                          onClick={() => this.back()}
                        >
                          Hủy
                        </Button>
                        <Button type="primary" htmlType="submit" size="large">
                          {saveLoading ? (
                            <div>
                              <img
                                alt=""
                                className="icon-loading"
                                src={config.getLoadingDefault()}
                              />
                              Đang gửi
                            </div>
                          ) : (
                            <span>Lưu lại</span>
                          )}
                        </Button>
                      </Row>
                    </Col>
                  </>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PartnerForm);
