import axios from 'axios';
import config from '../../app/config';
import QS from '../../app/utils/query-string';

export function getAListRegisterProfileUrls(params) {
  const apiUrl = `${config.getApiDomain()}/admin/exams`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
    params: params,
  }).then((response) => response);
}

export function saveRegisterProfileUrl(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/admin/save-exam`,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function getRegisterProfileUrl(id) {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/admin/exam`,
    params: { id },
  }).then((response) => response);
}
