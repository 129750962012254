import React from 'react';
import { Col, Row, Form, Input } from 'antd';

const SubjectPoint = ({
  subject,
  grades = [],
  disableHocky = false,
  showSaveButton,
}) => {
  if (!subject || !grades.length) {
    return <Row>Chưa cấu hình</Row>;
  }

  return (
    <div className="section-vnu">
      <Row>
        <b>{subject.value}</b>
      </Row>
      {subject.type == 'other' && (
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              label={`${subject.typeTitle || 'môn'}`}
              name={`${subject.key}_${subject.type}`}
              rules={[
                {
                  required: true,
                  message: `Vui lòng nhập điểm Tên ${
                    subject.typeTitle || 'môn'
                  }`,
                },
                {
                  max: 100,
                  message: `Tên ${
                    subject.typeTitle || 'môn'
                  } không quá 100 ký tự`,
                },
              ]}
            >
              <Input placeholder={`${subject.typeTitle || 'môn'}`} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        {grades.map((grade, index) => {
          return (
            <Col xs={24} md={8} key={index}>
              <Row>
                <b>Lớp {grade}</b>
              </Row>
              <Row gutter={16}>
                {[1, 2].map((hocKy, index) => {
                  let disabled = false;
                  if (
                    disableHocky &&
                    disableHocky.hocKy === hocKy &&
                    disableHocky.grade === grade
                  ) {
                    disabled = true;
                  }
                  let required = true;
                  if (showSaveButton && grade == 12 && hocKy == 2) {
                    required = false;
                  }
                  console.log('showSaveButton', showSaveButton);
                  return (
                    <Col xs={24} md={12} key={index}>
                      <Form.Item
                        label={`Học kỳ ${hocKy}`}
                        name={`${subject.key}_${grade}_hk${hocKy}`}
                        rules={[
                          {
                            required: !disabled && required,
                            message: `Vui lòng nhập điểm ${subject.value} ${grade} học kỳ ${hocKy}`,
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                typeof value === 'undefined' ||
                                value === ''
                              ) {
                                return Promise.resolve();
                              }

                              const intValue = parseFloat(value);

                              if (intValue < 0 || intValue > 10) {
                                return Promise.reject('Dữ liệu không hợp lệ');
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                        normalize={(value) => {
                          if (value != '' && !isNaN(value)) {
                            value = parseFloat(value);
                          }
                          if (value > 0) {
                            value = parseInt(value * 10) / 10;
                          }

                          return value;
                        }}
                      >
                        <Input
                          type="number"
                          disabled={disabled}
                          placeholder={`Điểm HK ${hocKy}`}
                        />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SubjectPoint;
