import React, { Component } from 'react';
import { Container, Row, Col, TabContent, TabPane, Media } from 'reactstrap';
import {
  Card,
  Image,
  Button,
  Badge,
  message,
  Tooltip,
  Avatar,
  Breadcrumb,
} from 'antd';
import { connect } from 'react-redux';
import { UserSwitchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ic_microscope from '../../../assets/img/theme/microscope.png';
import ic_paper from '../../../assets/img/theme/paper.png';
import ic_paper_diploma from '../../../assets/img/theme/paper-diploma.png';
import config from '../../../app/config';
import { getErrorMessages } from '../../../app/utils/functions';
import {
  viewProfile,
  getDataListExam,
  deleteConnective,
  listConnectiveClass,
} from '../service';
import Loading from '../../../Loading';

import XCard from '../../../lib/xcbtControls/Card';
import studying from 'themes/default/svg/studying.svg';
import TestTakerItem from '../Components/TestTakerItem';
import { Link } from 'react-router-dom';
import theme from 'themes';
import PATHS from 'themes/paths';

const { Meta } = Card;
// class CodeTaker extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { tooltipOpen: false, tooltipOpen2: false };
//   }
//   toggle = (type) => {
//     let { tooltipOpen } = this.state;
//     this.setState({
//       tooltipOpen: !tooltipOpen,
//     });

//   }
//   render() {
//     return (
//       <span onClick={() => this.toggle()}>
//         <i className="fa fa-barcode mr-1" aria-hidden="true"></i>
//         Mã dự thi: {this.state.tooltipOpen ? (<b>{this.props.item.code}</b>) : (<i className="fa fa-eye"></i>)}
//       </span>
//     );
//   }
// }

class ProfileDetail extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      request: [],
      activeTab: '1',
      activeTabExam: '1',
      openRequest: false,
      collapse: false,
      display: 1,
      loading: true,
      dropdownOpen: false,
      user: {},
      lstUser: [],
      lstClass: [],
      data: '',
      dataClass: '',
      total: '',
      showClass: false,
    };
  }

  toggleChangeUser = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  collapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  showAllClass = () => {
    this.setState({ showClass: true });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleTabExam(tab) {
    if (this.state.activeTabExam !== tab) {
      this.setState({
        activeTabExam: tab,
      });
    }
  }

  getConnective = async () => {
    const self = this;
    const response = await viewProfile(this.props.match.params.id);

    if (response && response.data.code == 200) {
      self.setState({
        request: response.data.data.data,
        loading: false,
        user: response.data.data.user,
        lstUser: response.data.data.list_user,
      });
      this.getListConnectiveClass();
      this.getListExam();
    } else {
      message.error(getErrorMessages(response.data));
      setTimeout(() => {
        window.location = PATHS.connective;
      }, 2000);
    }
  };

  getListConnectiveClass = async () => {
    const response = await listConnectiveClass(this.props.match.params.id);
    if (response.data.code == 200) {
      this.setState({ lstClass: response.data.data });
    } else {
      return message.error(getErrorMessages(response.data));
    }
  };

  componentDidMount() {
    if (!this.props.user) {
      message.error('Có lỗi xảy ra!');
    } else {
      this.getConnective();
    }
  }

  getListExam = async () => {
    const self = this;
    const response = await getDataListExam(this.props.match.params.id);
    if (response.data.code == 200) {
      self.setState({
        exam: response.data.data.exam,
        dataClass: response.data.data.list_class,
        total: response.data.data.total,
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  delConnective = async (id) => {
    const { user, request } = this.state;
    const username = user.fullname ? user.fullname : user.username;
    const requestId = request[`${id}`].id || '';
    if (requestId) {
      const response = await deleteConnective(requestId);
      if (response.data.code == 200) {
        message.success(`Đã bỏ theo dõi ${username}`);
        window.location.href = PATHS.profile;
      } else {
        message.error(response.data.message);
      }
    } else {
      message.error('Không tìm thấy hồ sơ');
    }
  };

  render() {
    const {
      showClass,
      loading,
      user,
      lstUser,
      lstClass,
      exam,
      total,
      activeTab,
      dropdownOpen,
    } = this.state;
    const username = user.fullname ? user.fullname : user.username;
    const activeSort = 2;

    let lstControl0;
    let lstControl1 = [];
    if (exam && exam.todo && Object.entries(exam.todo).length > 0) {
      lstControl0 = exam.todo.filter((item) => item.control_mode == 0);
      lstControl1 = exam.todo.filter((item) => item.control_mode == 1);
      lstControl0.sort((a, b) => new Date(a.to_time) - new Date(b.to_time));
    }
    if (exam && exam.cancel && Object.entries(exam.cancel).length > 0) {
      exam.cancel.sort((a, b) => new Date(b.to_time) - new Date(a.to_time));
    }

    return (
      <>
        {!loading ? (
          <Container>
            <Row>
              <Col xs={12}>
                <Breadcrumb className="mb-1">
                  <Breadcrumb.Item>
                    <Link to={PATHS.connective}>Tài khoản liên kết</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="primary">
                    {username}
                  </Breadcrumb.Item>
                </Breadcrumb>
                <div className="social__header">
                  <div className="social__title">
                    <span className="title-classes">{username} </span>
                    <div className="action-group-right">
                      {dropdownOpen && (
                        <div className="list-user-change align-center flex">
                          {Object.values(lstUser).length > 0 &&
                            Object.values(lstUser).map((item) => (
                              <a
                                href={`/profile/${item.id}`}
                                className="px-0 mr-2"
                                key={item.id}
                              >
                                <Tooltip
                                  title={
                                    item.fullname
                                      ? item.fullname
                                      : item.username
                                  }
                                >
                                  <Avatar
                                    size="large"
                                    src={
                                      item.avatar
                                        ? item.avatar
                                        : config.getAvatarDefault()
                                    }
                                  />
                                </Tooltip>
                              </a>
                            ))}
                          <CloseCircleOutlined
                            className="f-s-l danger"
                            onClick={() => {
                              this.toggleChangeUser();
                            }}
                          />
                        </div>
                      )}
                      {!dropdownOpen && Object.values(lstUser).length > 0 && (
                        <Button
                          type="primary"
                          className="b-r-4 mr-2"
                          icon={<UserSwitchOutlined />}
                          onClick={() => {
                            this.toggleChangeUser();
                          }}
                        >
                          Đổi tài khoản
                        </Button>
                      )}
                      {/* {!dropdownOpen &&<Button type="primary" danger className="b-r-4" icon={<ApiOutlined />} onClick={()=>this.delConnective(user.id)}>
                    Bỏ theo dõi
                  </Button>} */}
                      {/* <Dropdown className="dropdown-user list-user-change" isOpen={this.state.dropdownOpen} toggle={this.toggleChangeUser}>
                    <DropdownToggle className="view-profile btn-add btn btn-secondary btn-sm text-right">
                      <i className="fa fa-user-circle mr-1" aria-hidden="true"></i>Đổi tài khoản
                    </DropdownToggle>
                    <DropdownMenu right className="mt-2 dropdown-menu-lg">
                      {Object.values(lstUser).length>0 && Object.values(lstUser).map((item) => {
                          return (
                            <DropdownItem key={item.id}><a href={"/profile/"+item.id}> <img className="user-avatar rounded-circle mr-2" loading="lazy" src={item.avatar ? item.avatar : config.getAvatarDefault()} alt="" width="36" height="36"/> {item.username}</a></DropdownItem>
                          );
                      })}
                    </DropdownMenu>
                  </Dropdown> */}
                    </div>
                  </div>
                  <hr className="line mt-3" />
                </div>
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-2">
                <Card className="x-card card-info-profile">
                  <div className="statistic-card text-center">
                    <Media
                      key={Math.random()}
                      className="img-fluid rounded-circle shadow-sm avatar-img"
                      object
                      src={
                        user.avatar ? user.avatar : config.getAvatarDefault()
                      }
                    />
                    <Meta
                      className="text-center mt-2"
                      title={user.fullname ? user.fullname : user.username}
                      description={`Mã hồ sơ: ${user.code}`}
                    />
                  </div>
                </Card>
              </Col>
              {!theme.config.excludePaths.includes(PATHS.classes) && (
                <Col xs={12} md={6} lg={3} className="mb-2">
                  <Card className="x-card card-info-profile">
                    <div className="statistic-card flex flex-column">
                      <div className="text-right block">
                        <Image src={ic_microscope} size={56} preview={false} />
                      </div>
                      <div className="bage-custom primary-light">
                        {lstClass &&
                        lstClass.active &&
                        lstClass.active.length > 0
                          ? lstClass.active.length
                          : 0}
                      </div>
                      <div>Lớp học</div>
                    </div>
                  </Card>
                </Col>
              )}

              <Col xs={12} md={6} lg={3} className="mb-2">
                <Card className="x-card card-info-profile">
                  <div className="statistic-card flex flex-column">
                    <div className="text-right block">
                      <Image src={ic_paper_diploma} size={56} preview={false} />
                    </div>
                    <div className="bage-custom success-light">
                      {total && total.done ? total.done : 0}
                    </div>
                    <div>Bài đã làm</div>
                  </div>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={3}>
                <Card className="x-card card-info-profile">
                  <div className="statistic-card flex flex-column">
                    <div className="text-right block">
                      <Image src={ic_paper} size={56} preview={false} />
                    </div>
                    <div className="bage-custom warning-light">
                      {lstClass && lstClass.total ? lstClass.total.must : 0}
                    </div>
                    <div>Bài cần làm</div>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col xs={12}>
                <div className="social__header">
                  <div className="social__title">
                    <span className="title-classes">
                      Lớp của
                      {username}
                    </span>
                    <div className="action-group-right">
                      {/* <div className="site-button-ghost-wrapper mr-3">
                    <Button className={`${activeTab === '1' ? 'active' : ''} mr-2`} onClick={() => { this.toggle('1'); }}>
                      Đã tham gia <Badge count={12} className="site-badge-count" />
                    </Button>
                    <Button className={`${activeTab === '2' ? 'active' : ''}`} onClick={() => { this.toggle('2'); }}>
                      Chờ phê duyệt <Badge count={5} className="site-badge-count" />
                    </Button>
                  </div> */}
                    </div>
                  </div>
                  <hr className="line mt-3" />
                </div>
              </Col>
            </Row>
            <Row className="view-class">
              {lstClass && lstClass.active && lstClass.active.length > 0 ? (
                <>
                  {lstClass.active.map((value, index) => {
                    const item = value;
                    const teacherName =
                      lstClass.class[`${item.code}`] &&
                      lstClass.class[`${item.code}`].main_teacher_name
                        ? lstClass.class[`${item.code}`].main_teacher_name
                        : '';

                    return (
                      <Col
                        xs="12"
                        md="6"
                        lg="4"
                        xl="3"
                        className={`${
                          !showClass && index >= 7 ? 'hidden' : ''
                        } mb-4`}
                        key={item.id}
                      >
                        <XCard
                          edit={false}
                          hideAction
                          item={item}
                          teacherName={teacherName}
                          lstClass={lstClass}
                          deleteConnectiveClass={this.deleteConfirm}
                        />
                      </Col>
                    );
                  })}
                  {!showClass && lstClass.active.length >= 8 && (
                    <Col xs="12" md="6" lg="4" xl="3" className="mb-4">
                      <Card className="x-card card-classes flex align-center text-center">
                        <div className="">
                          <Image
                            src={ic_microscope}
                            size={56}
                            preview={false}
                          />
                          <p>
                            <Button
                              className="black bold mt-2"
                              type="link"
                              onClick={() => {
                                this.showAllClass();
                              }}
                            >
                              Xem thêm
                            </Button>
                          </p>
                        </div>
                      </Card>
                    </Col>
                  )}
                </>
              ) : (
                <div className="p-1 text-auto text-center">
                  <Image src={studying} preview={false} />
                  <p>
                    <i>
                      <span className="secondary">{username}</span> chưa đăng ký
                      học
                    </i>
                  </p>
                </div>
              )}
            </Row>

            <Row className="mt-5 share-exam">
              <Col xs={12}>
                <div className="social__header">
                  <div className="social__title">
                    <span className="title-classes">
                      Bài thi của {username}{' '}
                    </span>
                    <div className="action-group-right">
                      <div className="site-button-ghost-wrapper mr-3">
                        <Button
                          className={`${
                            activeTab === '1' ? 'active' : ''
                          } mr-2`}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          Chưa thi{' '}
                          <Badge
                            count={
                              total && total.must >= 0 && total.todo >= 0
                                ? parseInt(total.must) + parseInt(total.todo)
                                : 0
                            }
                            className="site-badge-count"
                          />
                        </Button>
                        <Button
                          className={`${activeTab === '2' ? 'active' : ''}`}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          Đã thi{' '}
                          <Badge
                            count={total && total.done ? total.done : 0}
                            className="site-badge-count"
                          />
                        </Button>
                        <Button
                          className={`${activeTab === '3' ? 'active' : ''}`}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          Quá hạn{' '}
                          <Badge
                            count={total && total.cancel ? total.cancel : 0}
                            className="site-badge-count"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <hr className="line mt-3" />
                </div>
              </Col>
            </Row>
            <TabContent className="tab-cus" activeTab={activeTab}>
              <TabPane tabId="1">
                {exam &&
                  exam.doing &&
                  Object.entries(exam.doing).length > 0 && (
                    <div className="item-exam-by-class mb-4">
                      <Row>
                        {Object.entries(exam.doing).map(([k, item]) => (
                          <Col
                            key={k}
                            xs="12"
                            md={activeSort == 2 ? '6' : '12'}
                            lg={activeSort == 2 ? '4' : '12'}
                            xl={activeSort == 2 ? '3' : '12'}
                            className="mb-3"
                          >
                            <TestTakerItem
                              customer
                              item={item}
                              display={activeSort}
                              type={1}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                {exam && exam.must && Object.entries(exam.must).length > 0 && (
                  <Row>
                    {exam.must.map((item) => (
                      <Col
                        xs="12"
                        md={activeSort == 2 ? '6' : '12'}
                        lg={activeSort == 2 ? '4' : '12'}
                        xl={activeSort == 2 ? '3' : '12'}
                        className="mb-3"
                        key={item.id}
                      >
                        <TestTakerItem
                          customer
                          expiredToday
                          item={item}
                          display={activeSort}
                          type={1}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
                {exam && exam.todo && Object.entries(exam.todo).length > 0 && (
                  <div className="item-exam-by-class mb-4">
                    <Row>
                      {lstControl1.map((item) => (
                        <Col
                          key={item.id}
                          xs="12"
                          md={activeSort == 2 ? '6' : '12'}
                          lg={activeSort == 2 ? '4' : '12'}
                          xl={activeSort == 2 ? '3' : '12'}
                          className="mb-3"
                        >
                          <TestTakerItem
                            customer
                            item={item}
                            display={activeSort}
                            type={1}
                          />
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      {lstControl0.map((item) => (
                        <Col
                          key={item.id}
                          xs="12"
                          md={activeSort == 2 ? '6' : '12'}
                          lg={activeSort == 2 ? '4' : '12'}
                          xl={activeSort == 2 ? '3' : '12'}
                          className="mb-3"
                        >
                          <TestTakerItem
                            customer
                            item={item}
                            display={activeSort}
                            type={1}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
                {exam &&
                  exam.todo &&
                  exam.doing &&
                  Object.entries(exam.todo).length == 0 &&
                  Object.entries(exam.doing).length == 0 && (
                    <div className="p-1 text-auto text-center">
                      <Image src={studying} preview={false} />
                      <p>
                        <i>
                          {' '}
                          <span className="secondary">{username}</span> không có
                          bài thi nào
                        </i>
                      </p>
                    </div>
                  )}
              </TabPane>
              <TabPane tabId="2">
                {exam && exam.done && Object.entries(exam.done).length > 0 && (
                  <div className="item-exam-by-class mb-4 done-exam">
                    <Row>
                      {Object.entries(exam.done).map(([k, item]) => (
                        <Col
                          key={k}
                          xs="12"
                          md={activeSort == 2 ? '6' : '12'}
                          lg={activeSort == 2 ? '4' : '12'}
                          xl={activeSort == 2 ? '3' : '12'}
                          className="mb-3"
                        >
                          <TestTakerItem
                            customer
                            item={item}
                            display={activeSort}
                            type={2}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
                {exam && exam.done && Object.entries(exam.done).length == 0 && (
                  <div className="p-1 text-auto text-center">
                    <Image src={studying} preview={false} />
                    <p>
                      <i>
                        {' '}
                        <span className="secondary">{username}</span> không có
                        bài thi nào
                      </i>
                    </p>
                  </div>
                )}
              </TabPane>
              <TabPane tabId="3">
                {exam &&
                  exam.cancel &&
                  Object.entries(exam.cancel).length > 0 && (
                    <div className="item-exam-by-class mb-4">
                      <Row>
                        {Object.entries(exam.cancel).map(([k, item]) => (
                          <Col
                            key={k}
                            xs="12"
                            md={activeSort == 2 ? '6' : '12'}
                            lg={activeSort == 2 ? '4' : '12'}
                            xl={activeSort == 2 ? '3' : '12'}
                            className="mb-3"
                          >
                            <TestTakerItem
                              item={item}
                              display={activeSort}
                              type={3}
                              hideBoomark
                              bookmarkItem={this.bookmarkItem}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                {exam &&
                  exam.cancel &&
                  Object.entries(exam.cancel).length == 0 && (
                    <div className="p-1 text-auto text-center">
                      <Image src={studying} preview={false} />
                      <p>
                        <i>
                          {' '}
                          <span className="secondary">{username}</span> không có
                          bài thi nào
                        </i>
                      </p>
                    </div>
                  )}
              </TabPane>
            </TabContent>
          </Container>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileDetail);
