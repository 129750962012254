import React, { Component } from 'react';
import {
  Tooltip,
  Button,
  Badge,
  Card,
  message,
  Popover,
  Typography,
} from 'antd';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  PicCenterOutlined,
  AppstoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { formatVndMoney, getErrorMessages } from 'app/utils/functions';
import {
  getDataListExam,
  getListActiveExam,
  getListDoneExam,
  getListExpireExam,
  bookmarkExam,
} from '../../service';
import TestTakerItem from '../../Components/TestTakerItem';
import QS from 'app/utils/query-string';
import studying from 'themes/default/svg/studying.svg';
import svgNoData from 'themes/default/svg/no-data.svg';
import svgCongratulation from 'themes/default/svg/congratulation.svg';
import EmptyData from '../../../Common/EmptyData';
import { getListExamRegistered } from '../../../Register/service';
import { Link } from 'react-router-dom';
import theme from 'themes';
import { KTLoading } from 'core/ui';
import PATHS from 'themes/paths';

const { Text } = Typography;
class TestTaker extends Component {
  constructor(props) {
    super(props);
    this.onShowCode = this.onShowCode.bind(this);
    this.state = {
      activeTab: null,
      activeSort: '1',
      display: 1,
      exam: '',
      lstClass: '',
      total: '',
      showDueExam: false,
      orders: null,
      //Tab1
      tab1: {},
      loadingTab1: false,
      //Tab2
      tab2: {},
      loadingTab2: false,
      //Tab3
      tab3: {},
      loadingTab3: false,
      timestamp: Date.now(),
      prefixTime: 0,
      showCodeRepo: {},
    };
  }

  timer = null;

  componentDidMount() {
    const search = QS.parse(this.props.location.search)
      ? QS.parse(this.props.location.search)
      : {};
    this.toggle(search.type || '1');
    if (this.props.location && this.props.location.pathname) {
      localStorage.setItem('home_url', this.props.location.pathname);
    }
    this.timer = setInterval(() => {
      const exam = this.state.tab1.exam;
      if (
        this.state.activeTab == 1 &&
        exam &&
        (exam.todo.length || exam.doing.length || exam.must.length)
      ) {
        console.log(this.state.tab1.exam, 'this.state.tab1.exam');

        this.setState({
          timestamp: Date.now() - this.state.prefixTime,
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onShowCode(examId) {
    this.setState({
      showCodeRepo: {
        ...this.state.showCodeRepo,
        [examId]: !this.state.showCodeRepo[examId],
      },
    });
  }

  repairOrders(orders) {
    if (Array.isArray(orders)) {
      return orders.map((order) => {
        try {
          if (order.transaction_data) {
            order.transaction_data = JSON.parse(order.transaction_data);
          }

          if (order.customer_invoice_data) {
            order.customer_invoice_data = JSON.parse(
              order.customer_invoice_data,
            );
          }

          return order;
        } catch (error) {
          order.transaction_data = {};

          return order;
        }
      });
    }

    return [];
  }

  getExamsRegistered = async () => {
    const response = await getListExamRegistered();
    if (response.data.code == 200) {
      this.setState({ orders: this.repairOrders(response.data.data.orders) });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.props.history.replace(`?type=${tab}`);
      this.setState(
        {
          activeTab: tab,
          showDueExam: tab == 4,
        },
        () => {
          // Xài if vì chưa chắc value is number
          if (this.state.activeTab == 1) {
            this.getListExamTab1();
          } else if (this.state.activeTab == 2) {
            this.getListExamTab2();
          } else if (this.state.activeTab == 3) {
            this.getListExamTab3();
          } else if (this.state.activeTab == 5) {
            this.getExamsRegistered();
          }
        },
      );
    }
  };

  toggleSort = (tab) => {
    if (this.state.activeSort !== tab) {
      this.setState({
        activeSort: tab,
      });
    }
  };

  changeView = (tab) => {
    this.setState({
      display: tab,
    });
  };

  getListExam = async () => {
    const self = this;
    const response = await getDataListExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        exam: response.data.data.exam,
        lstClass: response.data.data.list_class,
        total: response.data.data.total,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getListExamTab1 = async () => {
    this.setState({
      loadingTab1: true,
    });
    const self = this;
    const response = await getListActiveExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        tab1: {
          exam: response.data.data.exam,
          lstClass: response.data.data.list_class,
          total: response.data.data.total,
        },
        timestamp: response.data.data.timestamp * 1000,
        prefixTime: Date.now() - response.data.data.timestamp * 1000,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({
      loadingTab1: false,
    });
  };

  getListExamTab2 = async () => {
    this.setState({
      loadingTab2: true,
    });
    const self = this;
    const response = await getListDoneExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        tab2: {
          exam: response.data.data.exam,
          lstClass: response.data.data.list_class,
          total: response.data.data.total,
        },
        timestamp: response.data.data.timestamp * 1000,
        prefixTime: Date.now() - response.data.data.timestamp * 1000,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({
      loadingTab2: false,
    });
  };

  getListExamTab3 = async () => {
    this.setState({
      loadingTab3: true,
    });
    const self = this;
    const response = await getListExpireExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        tab3: {
          exam: response.data.data.exam,
          lstClass: response.data.data.list_class,
          total: response.data.data.total,
        },
        timestamp: response.data.data.timestamp * 1000,
        prefixTime: Date.now() - response.data.data.timestamp * 1000,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({
      loadingTab3: false,
    });
  };

  bookmarkItem = async (id) => {
    const self = this;
    const response = await bookmarkExam(id);
    if (response.data.code == 200) {
      self.getListExamTab3();
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    const {
      orders,
      total,
      activeSort,
      activeTab,
      loadingTab1,
      loadingTab2,
      loadingTab3,
      timestamp,
      showCodeRepo,
    } = this.state;
    let lstControl0;
    let lstControl1 = [];

    let exam;
    if (activeTab == 1) {
      exam = this.state.tab1.exam;
    } else if (activeTab == 2) {
      exam = this.state.tab2.exam;
    } else if (activeTab == 3) {
      exam = this.state.tab3.exam;
    }

    if (exam && exam.todo && Object.entries(exam.todo).length > 0) {
      lstControl0 = exam.todo.filter((item) => item.control_mode == 0);
      lstControl1 = exam.todo.filter((item) => item.control_mode == 1);

      lstControl0.sort((a, b) => new Date(a.to_time) - new Date(b.to_time));
    }
    if (exam && exam.cancel && Object.entries(exam.cancel).length > 0) {
      exam.cancel.sort((a, b) => new Date(b.to_time) - new Date(a.to_time));
    }

    return (
      <>
        <Container className="page-exam">
          <Row>
            <Col xs={12}>
              <div className="social__header">
                <div className="social__title">
                  <span className="title-classes title-classes-light">
                    Bài thi của tôi
                  </span>
                  <div className="action-group-right">
                    <div className="site-button-ghost-wrapper mr-3">
                      {((orders != null && orders.length) > 0 ||
                        theme.config.showOrderTab) && (
                        <Button
                          className={`${
                            activeTab === '5' ? 'active' : ''
                          } mr-2`}
                          onClick={() => {
                            this.toggle('5');
                          }}
                        >
                          Bài thi đã đăng ký{' '}
                          <Badge
                            count={orders != null ? orders.length : ''}
                            className="site-badge-count"
                          />
                        </Button>
                      )}
                      <Button
                        className={`${activeTab === '1' ? 'active' : ''} mr-2`}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Chưa làm{' '}
                        {/* <Badge
                          count={
                            total && total.todo >= 0 && total.must >= 0
                              ? parseInt(total.must) + parseInt(total.todo)
                              : 0
                          }
                          className="site-badge-count"
                        /> */}
                      </Button>
                      <Button
                        className={`${activeTab === '2' ? 'active' : ''}`}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        Đã làm{' '}
                        {/* <Badge
                          count={total && total.done ? total.done : 0}
                          className="site-badge-count"
                        /> */}
                      </Button>
                      <Button
                        className={`${activeTab === '3' ? 'active' : ''}`}
                        onClick={() => {
                          this.toggle('3');
                        }}
                      >
                        Quá hạn{' '}
                        {/* <Badge
                          count={total && total.cancel ? total.cancel : 0}
                          className="site-badge-count"
                        /> */}
                      </Button>
                    </div>
                    {activeTab != 5 && (
                      <div className="site-button-ghost-wrapper mr-3 sort-box">
                        <Button
                          className={`${
                            activeSort === '1' ? 'active' : ''
                          } mr-2`}
                          onClick={() => {
                            this.toggleSort('1');
                          }}
                        >
                          <PicCenterOutlined />
                        </Button>
                        <Button
                          className={`${activeSort === '2' ? 'active' : ''}`}
                          onClick={() => {
                            this.toggleSort('2');
                          }}
                        >
                          <AppstoreOutlined />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="line mt-3" />
              </div>
              <TabContent
                className="tab-cus"
                activeTab={activeTab}
                key={this.state.timestamp}
              >
                <TabPane tabId="1">
                  {loadingTab1 && <KTLoading />}
                  {exam &&
                    exam.doing &&
                    Object.entries(exam.doing).length > 0 && (
                      <div className="item-exam-by-class">
                        <Row>
                          {Object.entries(exam.doing).map(([k, item]) => (
                            <Col
                              key={k}
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={1}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {exam &&
                    exam.must &&
                    Object.entries(exam.must).length > 0 && (
                      <Row>
                        {exam.must.map((item) => (
                          <Col
                            xs="12"
                            md={activeSort == 2 ? '6' : '12'}
                            lg={activeSort == 2 ? '4' : '12'}
                            xl={activeSort == 2 ? '3' : '12'}
                            className="mb-3"
                            key={item.id}
                          >
                            <TestTakerItem
                              onShowCode={this.onShowCode}
                              isShowCode={showCodeRepo[item.id]}
                              timestamp={timestamp}
                              expiredToday
                              item={item}
                              display={activeSort}
                              type={1}
                              bookmarkItem={this.bookmarkItem}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  {exam &&
                    exam.todo &&
                    Object.entries(exam.todo).length > 0 && (
                      <div className="item-exam-by-class mb-4">
                        <Row>
                          {lstControl1.map((item) => (
                            <Col
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                              key={item.id}
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={1}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          {lstControl0.map((item) => (
                            <Col
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                              key={item.id}
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={1}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {!loadingTab1 && (
                    <>
                      {exam &&
                      exam.cancel &&
                      Object.entries(exam.cancel).length == 0 &&
                      total &&
                      total.done > 0 &&
                      exam.must &&
                      exam.todo >= 0 &&
                      exam.doing >= 0 &&
                      Object.entries(exam.todo).length +
                        Object.entries(exam.must).length +
                        Object.entries(exam.doing).length ==
                        0 ? (
                        <EmptyData
                          img={svgCongratulation}
                          text={
                            <>
                              <h3>
                                Chúc mừng,{' '}
                                {this.props.user.fullname
                                  ? this.props.user.fullname
                                  : this.props.user.username}
                              </h3>
                              <p>
                                Bạn đã hoàn thành mọi bài thi, hãy giữ vững
                                phong độ nhé
                              </p>
                            </>
                          }
                        />
                      ) : (
                        <>
                          {exam &&
                            exam.must &&
                            exam.todo &&
                            exam.doing &&
                            Object.entries(exam.must).length == 0 &&
                            Object.entries(exam.todo).length == 0 &&
                            Object.entries(exam.doing).length == 0 && (
                              <EmptyData
                                img={studying}
                                text={<p>Không có bài thi nào</p>}
                              />
                            )}
                        </>
                      )}
                    </>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {loadingTab2 && <KTLoading />}
                  {exam &&
                    exam.done &&
                    Object.entries(exam.done).length > 0 && (
                      <div className="item-exam-by-class mb-4 done-exam">
                        <Row>
                          {Object.entries(exam.done).map(([k, item]) => (
                            <Col
                              key={k}
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={2}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {!loadingTab2 &&
                    exam &&
                    exam.done &&
                    Object.entries(exam.done).length == 0 && (
                      <EmptyData
                        img={studying}
                        text={<p>Không có bài thi nào</p>}
                      />
                    )}
                </TabPane>
                <TabPane tabId="3">
                  {loadingTab3 && <KTLoading />}
                  {exam &&
                    exam.cancel &&
                    Object.entries(exam.cancel).length > 0 && (
                      <div className="item-exam-by-class mb-4">
                        <Row>
                          {Object.entries(exam.cancel).map(([k, item]) => (
                            <Col
                              key={k}
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={3}
                                bookmarkItem={this.bookmarkItem}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {!loadingTab3 &&
                    exam &&
                    exam.cancel &&
                    Object.entries(exam.cancel).length == 0 && (
                      <EmptyData
                        img={studying}
                        text={<p>Không có bài thi nào</p>}
                      />
                    )}
                </TabPane>
                <TabPane tabId="4">
                  {exam &&
                    exam.must &&
                    Object.entries(exam.must).length > 0 && (
                      <Row>
                        {exam.must.map((item) => (
                          <Col
                            xs="12"
                            md={activeSort == 2 ? '6' : '12'}
                            lg={activeSort == 2 ? '4' : '12'}
                            xl={activeSort == 2 ? '3' : '12'}
                            className="mb-3"
                            key={item.id}
                          >
                            <TestTakerItem
                              onShowCode={this.onShowCode}
                              isShowCode={showCodeRepo[item.id]}
                              timestamp={timestamp}
                              item={item}
                              display={activeSort}
                              type={1}
                              bookmarkItem={this.bookmarkItem}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  {exam &&
                    exam.must &&
                    Object.entries(exam.must).length == 0 && (
                      <EmptyData
                        img={studying}
                        text={<p>Không có bài thi nào</p>}
                      />
                    )}
                </TabPane>
                <TabPane tabId="5">
                  {orders != null && orders.length > 0 && (
                    <Row>
                      {orders.map((item) => {
                        return (
                          <Col
                            xs="12"
                            md={6}
                            lg={4}
                            xl={3}
                            className="mb-3"
                            key={item.id}
                          >
                            <Card
                              className="x-card"
                              title={
                                <Tooltip
                                  placement="top"
                                  title={item.invoice_description}
                                >
                                  {item.invoice_description}
                                </Tooltip>
                              }
                              bordered={false}
                              actions={[
                                <div className="m-2">
                                  {item.status == 1 ? (
                                    <Button
                                      block
                                      className="b-r-4"
                                      type="primary"
                                      ghost
                                      onClick={() => {
                                        this.toggle('1');
                                      }}
                                    >
                                      Xem kế hoạch
                                    </Button>
                                  ) : (
                                    <Link
                                      className="ant-btn-block"
                                      to={`/dk/${item.customer_invoice_data['url']}/${item.id}`}
                                    >
                                      <Button block className="b-r-4">
                                        Tiếp tục đăng ký
                                      </Button>
                                    </Link>
                                  )}
                                </div>,
                              ]}
                            >
                              <div className="info-order">
                                {item.status == 0 && item.payment_code && (
                                  <>
                                    Mã thanh toán{' '}
                                    <Popover
                                      content={
                                        <>
                                          Sử dụng mã thanh toán{' '}
                                          <b>{item.payment_code}</b> để thực
                                          hiện thanh toán
                                          <br />
                                          tại các đại lý thu tiền ủy quyền để
                                          hoàn tất thủ tục đăng ký.
                                        </>
                                      }
                                    >
                                      <QuestionCircleOutlined />
                                    </Popover>{' '}
                                    : <b>{item.payment_code}</b>&nbsp;
                                    <Button
                                      type="dashed"
                                      size="small"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          item.payment_code,
                                        );
                                        message.success(
                                          `Đã sao chép mã thanh toán "${item.payment_code}"`,
                                        );
                                      }}
                                    >
                                      Copy
                                    </Button>
                                    <br />
                                  </>
                                )}
                                {item.payment_id && (
                                  <>
                                    Mã đơn hàng: FIS.TSD-
                                    {item.id}-{item.payment_id}
                                    <br />
                                  </>
                                )}
                                {item.transaction_data &&
                                  item.transaction_data.transactionNo && (
                                    <>
                                      Mã giao dịch:{' '}
                                      {item.transaction_data.transactionNo}
                                      <br />
                                    </>
                                  )}
                                {item.transaction_data &&
                                  item.transaction_data.paygate && (
                                    <>
                                      Cổng thanh toán:{' '}
                                      {item.transaction_data.paygate}
                                      <br />
                                    </>
                                  )}
                                Tạo lúc:{' '}
                                {moment(item.invoice_created_ts).format(
                                  'DD/MM/YYYY HH:mm',
                                )}
                                <br />
                                {item.invoice_paid_ts && (
                                  <>
                                    Thanh toán lúc:{' '}
                                    {moment(item.invoice_paid_ts).format(
                                      'DD/MM/YYYY HH:mm',
                                    )}
                                    <br />
                                  </>
                                )}
                                Số tiền thanh toán:{' '}
                                <b className="">
                                  {formatVndMoney(item.invoice_total_amount)}
                                </b>
                                <br />
                                Trạng thái:{' '}
                                <Text
                                  strong
                                  type={item.status == 1 ? 'success' : 'danger'}
                                >
                                  {item.status == 1
                                    ? 'Đã thanh toán'
                                    : item.status == -1
                                    ? 'Đã hủy'
                                    : 'Chưa thanh toán'}
                                </Text>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                  {orders != null && orders.length == 0 && (
                    <EmptyData
                      img={svgNoData}
                      text={
                        <p>
                          Bạn chưa đăng ký kỳ thi nào. &nbsp;
                          <Button type="primary">
                            <Link to={PATHS.listExam}>Đăng ký thi</Link>
                          </Button>
                        </p>
                      }
                    />
                  )}
                  {orders == null && <KTLoading />}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TestTaker);
