import React, { Component } from 'react';
import { message, Button, Drawer, Avatar, Image } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import config from '../../../app/config';
import icNetwork from 'themes/default/svg/icon-network.svg';
import { searchUserByCode, requestUser } from '../service.js';
import RequestForm from './ClassFormRequest';

class ProfileFriend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleDrawer: false,
      visible: 1,
      user: '',
      type: '',
    };
  }

  openRequest = () => {
    this.setState({ visibleDrawer: true });
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  onClose = () => {
    this.setState({
      visibleDrawer: false,
      visible: 1,
      user: '',
    });
  };

  onBack = () => {
    this.setState({
      visible: 1,
    });
  };

  showDrawer = async (visible, code, type) => {
    if (!code) {
      return message.error('Bạn chưa nhập mã hồ sơ');
    }
    if (!code || code == this.props.user.code) {
      return message.error('Không tìm thấy hồ sơ');
    }
    const response = await searchUserByCode(code);
    if (response.data.code == 200) {
      this.setState({ type, user: response.data.data, visible });
    } else {
      message.error(response.data.message);
      this.setState({ user: '' });
    }
  };

  onSubmitRequest = async () => {
    const self = this;
    const { user, type } = this.state;
    if (!user || user.id == this.props.user.id) {
      return message.error('Không tìm thấy hồ sơ');
    }
    const response = await requestUser(user.id, type);
    if (response.data.code == 200) {
      message.success('Đã gửi yêu cầu liên kết');
      this.onClose();
      setTimeout(() => {
        if (self.props.onClose) {
          self.props.onClose();
        }
      }, 2000);
    } else {
      message.error(response.data.message);
    }
  };

  render() {
    const { visibleDrawer, visible, user } = this.state;

    return (
      <>
        {this.props.icon ? (
          <span onClick={() => this.openRequest()}>{this.props.icon}</span>
        ) : (
          <Button
            type="primary"
            className="btn-request-user b-r-4"
            onClick={() => this.openRequest()}
          >
            <UsergroupAddOutlined /> Thêm
          </Button>
        )}

        <Drawer
          closeIcon={
            <i className="fa fa-times-circle danger" aria-hidden="true" />
          }
          title=""
          width={400}
          onClose={this.onClose}
          visible={visibleDrawer}
          bodyStyle={{ paddingBottom: 80, marginTop: 80 }}
        >
          {visible == 2 && (
            <div className="text-center">
              <Avatar
                size={{
                  xs: 24,
                  sm: 32,
                  md: 40,
                  lg: 64,
                  xl: 80,
                  xxl: 80,
                }}
                src={user.avatar ? user.avatar : config.getAvatarDefault()}
              />
              <p className="mt-2">
                Bạn muốn gửi yêu cầu liên kết{' '}
                <span className="secondary">
                  {user.fullname ? `${user.fullname} - ` : ''} {user.username}
                </span>
              </p>
              <div className="btn-gr-join flex flex-content-evenly">
                <Button className="primary" onClick={this.onBack}>
                  Nhập lại mã hồ sơ
                </Button>
                <Button type="primary" onClick={this.onSubmitRequest}>
                  Gửi yêu cầu
                </Button>
              </div>
            </div>
          )}

          {visible == 1 && (
            <div className="text-center">
              <Image
                size={{
                  xs: 24,
                  sm: 32,
                  md: 40,
                  lg: 64,
                  xl: 80,
                  xxl: 80,
                }}
                src={icNetwork}
                preview={false}
                shape="square"
              />
              <RequestForm
                visibleDrawer={visibleDrawer}
                type={2}
                showDrawer={this.showDrawer}
              />
            </div>
          )}
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileFriend);
