import axios from 'axios';
import store from './store';
import config from './config';
import QS from './utils/query-string';
import { logout } from '../modules/User/service';
import PATHS from 'themes/paths';
import theme from 'themes';
import { clearLocalStorage } from './utils/functions';

let refreshTokenInterval;

const auth = {
  async init() {
    const refreshToken = localStorage.getItem('authen_refresh_token');

    if (refreshToken) {
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
        data: QS.stringify({
          grant_type: 'refresh_token',
          client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
          refresh_token: refreshToken,
        }),
      });
      if (res.status == 200) {
        const verify = await this.verifyUser(
          res.data.access_token,
          res.data.refresh_token,
        );
        if (!verify.success) {
          return false;
        }

        return true;
      }

      return false;
    }

    return false;
  },

  async verifyUser(accessToken, refreshToken) {
    const self = this;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const response = await axios.get(
      `${config.getApiDomain()}/user/info?${
        theme.config.auth.withFriend ? 'withFriend=1' : ''
      }`,
    );
    if (response.data.code == 200) {
      localStorage.setItem('authen_access_token', accessToken);
      localStorage.setItem('authen_refresh_token', refreshToken);
      store.dispatch({ type: 'UPDATE_USER_TOKEN', userToken: accessToken });
      store.dispatch({ type: 'UPDATE_USER', user: response.data.data.user });

      if (refreshTokenInterval) {
        clearInterval(refreshTokenInterval);
      }
      refreshTokenInterval = setInterval(() => {
        self.init();
        console.log('Refresh token');
      }, 60000 * 5);

      return { success: true, data: response.data.data.user };
    }
    localStorage.setItem('authen_access_token', '');
    localStorage.setItem('authen_refresh_token', '');
    store.dispatch({ type: 'UPDATE_USER_TOKEN', userToken: '' });
    store.dispatch({ type: 'UPDATE_USER', user: {} });

    return { success: false, data: response.data };
  },

  logout(redirect = true) {
    logout();
    localStorage.setItem('authen_access_token', '');
    localStorage.setItem('authen_refresh_token', '');
    localStorage.setItem('refresh_event', Math.random());
    store.dispatch({ type: 'UPDATE_USER_TOKEN', userToken: '' });
    store.dispatch({ type: 'UPDATE_USER', user: {} });
    clearLocalStorage();
    if (redirect) {
      window.location = PATHS.login;
    }
  },
};

export default auth;
