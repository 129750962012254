import React, { Component } from 'react';
import {
  Tooltip,
  Button,
  Badge,
  Card,
  message,
  Popover,
  Typography,
  Modal,
  Checkbox,
} from 'antd';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  PicCenterOutlined,
  AppstoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { formatVndMoney, getErrorMessages } from 'app/utils/functions';
import {
  getDataListExam,
  getListActiveExam,
  getListDoneExam,
  getListExpireExam,
  bookmarkExam,
} from '../../service';
import VerifyEmailAlert from '../../Components/VerifyEmailAlert';
import TestTakerItem from '../../Components/TestTakerItem';
import QS from 'app/utils/query-string';
import studying from 'themes/default/svg/studying.svg';
import svgNoData from 'themes/default/svg/no-data.svg';
import svgCongratulation from 'themes/default/svg/congratulation.svg';
import EmptyData from '../../../Common/EmptyData';
import { getListExamRegistered } from '../../../Register/service';
import { Link } from 'react-router-dom';
import theme from 'themes';
import { KTLoading } from 'core/ui';
import PATHS from 'themes/paths';
import findHustExamVenue from 'modules/Register/Components/helpers/find-hust-exam-venue';

const { Text } = Typography;

const test_location = {
  HN: 'Hà Nội',
  HP: 'Hải Phòng',
  TN: 'Thái Nguyên',
  VINH: 'TP Vinh',
};

class TestTaker extends Component {
  constructor(props) {
    super(props);
    this.onShowCode = this.onShowCode.bind(this);
    this.state = {
      activeTab: null,
      activeSort: '1',
      display: 1,
      exam: '',
      lstClass: '',
      total: '',
      showDueExam: false,
      orders: null,
      //Tab1
      tab1: {},
      loadingTab1: false,
      //Tab2
      tab2: {},
      loadingTab2: false,
      //Tab3
      tab3: {},
      loadingTab3: false,
      timestamp: Date.now(),
      prefixTime: 0,
      showCodeRepo: {},
      showSelectSubjectModal: false,
      updateItemSelected: {},
      updateSubjects: [],
    };
  }

  timer = null;

  componentDidMount() {
    const search = QS.parse(this.props.location.search)
      ? QS.parse(this.props.location.search)
      : {};
    this.toggle(search.type || '1');
    if (this.props.location && this.props.location.pathname) {
      localStorage.setItem('home_url', this.props.location.pathname);
    }
    /*   this.timer = setInterval(() => {
      this.setState({
        timestamp: Date.now() - this.state.prefixTime,
      });
    }, 1000); */
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onShowCode(examId) {
    this.setState({
      showCodeRepo: {
        ...this.state.showCodeRepo,
        [examId]: !this.state.showCodeRepo[examId],
      },
    });
  }

  repairOrders(orders) {
    if (Array.isArray(orders)) {
      return orders.map((order) => {
        try {
          if (order.transaction_data) {
            order.transaction_data = JSON.parse(order.transaction_data);
          }

          if (order.customer_invoice_data) {
            order.customer_invoice_data = JSON.parse(
              order.customer_invoice_data,
            );
          }

          return order;
        } catch (error) {
          order.transaction_data = {};

          return order;
        }
      });
    }

    return [];
  }

  getListExamRegistered = async () => {
    const response = await getListExamRegistered();
    if (response.data.code == 200) {
      this.setState({ orders: this.repairOrders(response.data.data.orders) });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.props.history.replace(`?type=${tab}`);
      this.setState(
        {
          activeTab: tab,
          showDueExam: tab == 4,
        },
        () => {
          // Xài if vì chưa chắc value is number
          if (this.state.activeTab == 1) {
            this.getListExamTab1();
          } else if (this.state.activeTab == 2) {
            this.getListExamTab2();
          } else if (this.state.activeTab == 3) {
            this.getListExamTab3();
          } else if (this.state.activeTab == 5) {
            this.getListExamRegistered();
          }
        },
      );
    }
  };

  toggleSort = (tab) => {
    if (this.state.activeSort !== tab) {
      this.setState({
        activeSort: tab,
      });
    }
  };

  changeView = (tab) => {
    this.setState({
      display: tab,
    });
  };

  getListExam = async () => {
    const self = this;
    const response = await getDataListExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        exam: response.data.data.exam,
        lstClass: response.data.data.list_class,
        total: response.data.data.total,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getListExamTab1 = async () => {
    this.setState({
      loadingTab1: true,
    });
    const self = this;
    const response = await getListActiveExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        tab1: {
          exam: response.data.data.exam,
          lstClass: response.data.data.list_class,
          total: response.data.data.total,
        },
        timestamp: response.data.data.timestamp * 1000,
        prefixTime: Date.now() - response.data.data.timestamp * 1000,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({
      loadingTab1: false,
    });
  };

  getListExamTab2 = async () => {
    this.setState({
      loadingTab2: true,
    });
    const self = this;
    const response = await getListDoneExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        tab2: {
          exam: response.data.data.exam,
          lstClass: response.data.data.list_class,
          total: response.data.data.total,
        },
        timestamp: response.data.data.timestamp * 1000,
        prefixTime: Date.now() - response.data.data.timestamp * 1000,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({
      loadingTab2: false,
    });
  };

  getListExamTab3 = async () => {
    this.setState({
      loadingTab3: true,
    });
    const self = this;
    const response = await getListExpireExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({
        tab3: {
          exam: response.data.data.exam,
          lstClass: response.data.data.list_class,
          total: response.data.data.total,
        },
        timestamp: response.data.data.timestamp * 1000,
        prefixTime: Date.now() - response.data.data.timestamp * 1000,
      });
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    this.setState({
      loadingTab3: false,
    });
  };

  bookmarkItem = async (id) => {
    const self = this;
    const response = await bookmarkExam(id);
    if (response.data.code == 200) {
      self.getListExamTab3();
    } else {
      toast.error(getErrorMessages(response.data), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  /*   onSaveSubjectClick = async () => {
    let subjectData = ['subject_math'];
    if (this.state.updateSubjects.indexOf('subject_khtn') !== -1) {
      subjectData.push('subject_khtn');
    }
    if (this.state.updateSubjects.indexOf('subject_english') !== -1) {
      subjectData.push('subject_english');
    }

    if (subjectData.length <= 1) {
      return message.error('Bạn phải chọn ít nhất một môn thi tự chọn.');
    }

    const result = await updateSubjects(this.state.updateItemSelected.id, {
      subjectData: subjectData,
    });
    if (result.data.code == 200) {
      message.success('Cập nhật thành công!');
      this.getOrders();
      this.setState({
        showSelectSubjectModal: false,
      });
    } else {
      message.error(getErrorMessages(result.data));
    }
  };
 */
  render() {
    const {
      orders,
      total,
      activeSort,
      activeTab,
      loadingTab1,
      loadingTab2,
      loadingTab3,
      timestamp,
      showCodeRepo,
    } = this.state;
    let lstControl0;
    let lstControl1 = [];

    let exam;
    if (activeTab == 1) {
      exam = this.state.tab1.exam;
    } else if (activeTab == 2) {
      exam = this.state.tab2.exam;
    } else if (activeTab == 3) {
      exam = this.state.tab3.exam;
    }

    if (exam && exam.todo && Object.entries(exam.todo).length > 0) {
      lstControl0 = exam.todo.filter((item) => item.control_mode == 0);
      lstControl1 = exam.todo.filter((item) => item.control_mode == 1);

      lstControl0.sort((a, b) => new Date(a.to_time) - new Date(b.to_time));
    }
    if (exam && exam.cancel && Object.entries(exam.cancel).length > 0) {
      exam.cancel.sort((a, b) => new Date(b.to_time) - new Date(a.to_time));
    }

    return (
      <>
        <Container className="page-exam">
          <Row>
            {!this.props.user.email_verified && (
              <Col xs={12} className="mb-3">
                <VerifyEmailAlert />
              </Col>
            )}
            <Col xs={12}>
              <div className="social__header">
                <div className="social__title">
                  <span className="title-classes title-classes-light">
                    Bài thi của tôi
                  </span>
                  <div className="action-group-right">
                    <div className="site-button-ghost-wrapper mr-3">
                      {/*                       {((orders != null && orders.length) > 0 ||
                        theme.config.showOrderTab) && (
                        <Button
                          className={`${
                            activeTab === '5' ? 'active' : ''
                          } mr-2`}
                          onClick={() => {
                            this.toggle('5');
                          }}
                        >
                          Bài thi đã đăng ký{' '}
                          <Badge
                            count={orders != null ? orders.length : ''}
                            className="site-badge-count"
                          />
                        </Button>
                      )} */}
                      <Button
                        className={`${activeTab === '1' ? 'active' : ''} mr-2`}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Chưa thi{' '}
                        {/* <Badge
                          count={
                            total && total.todo >= 0 && total.must >= 0
                              ? parseInt(total.must) + parseInt(total.todo)
                              : 0
                          }
                          className="site-badge-count"
                        /> */}
                      </Button>
                      <Button
                        className={`${activeTab === '2' ? 'active' : ''}`}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        Đã thi{' '}
                        {/* <Badge
                          count={total && total.done ? total.done : 0}
                          className="site-badge-count"
                        /> */}
                      </Button>
                      <Button
                        className={`${activeTab === '3' ? 'active' : ''}`}
                        onClick={() => {
                          this.toggle('3');
                        }}
                      >
                        Quá hạn{' '}
                        {/* <Badge
                          count={total && total.cancel ? total.cancel : 0}
                          className="site-badge-count"
                        /> */}
                      </Button>
                    </div>
                    {activeTab != 5 && (
                      <div className="site-button-ghost-wrapper mr-3 sort-box">
                        <Button
                          className={`${
                            activeSort === '1' ? 'active' : ''
                          } mr-2`}
                          onClick={() => {
                            this.toggleSort('1');
                          }}
                        >
                          <PicCenterOutlined />
                        </Button>
                        <Button
                          className={`${activeSort === '2' ? 'active' : ''}`}
                          onClick={() => {
                            this.toggleSort('2');
                          }}
                        >
                          <AppstoreOutlined />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="line mt-3" />
              </div>
              <TabContent
                className="tab-cus"
                activeTab={activeTab}
                key={this.state.timestamp}
              >
                <TabPane tabId="1">
                  {loadingTab1 && <KTLoading />}
                  {exam &&
                    exam.doing &&
                    Object.entries(exam.doing).length > 0 && (
                      <div className="item-exam-by-class">
                        <Row>
                          {Object.entries(exam.doing).map(([k, item]) => (
                            <Col
                              key={k}
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={1}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {exam &&
                    exam.must &&
                    Object.entries(exam.must).length > 0 && (
                      <Row>
                        {exam.must.map((item) => (
                          <Col
                            xs="12"
                            md={activeSort == 2 ? '6' : '12'}
                            lg={activeSort == 2 ? '4' : '12'}
                            xl={activeSort == 2 ? '3' : '12'}
                            className="mb-3"
                            key={item.id}
                          >
                            <TestTakerItem
                              onShowCode={this.onShowCode}
                              isShowCode={showCodeRepo[item.id]}
                              timestamp={timestamp}
                              expiredToday
                              item={item}
                              display={activeSort}
                              type={1}
                              bookmarkItem={this.bookmarkItem}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  {exam &&
                    exam.todo &&
                    Object.entries(exam.todo).length > 0 && (
                      <div className="item-exam-by-class mb-4">
                        <Row>
                          {lstControl1.map((item) => (
                            <Col
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                              key={item.id}
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={1}
                              />
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          {lstControl0.map((item) => (
                            <Col
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                              key={item.id}
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={1}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {!loadingTab1 && (
                    <>
                      {exam &&
                      exam.cancel &&
                      Object.entries(exam.cancel).length == 0 &&
                      total &&
                      total.done > 0 &&
                      exam.must &&
                      exam.todo >= 0 &&
                      exam.doing >= 0 &&
                      Object.entries(exam.todo).length +
                        Object.entries(exam.must).length +
                        Object.entries(exam.doing).length ==
                        0 ? (
                        <EmptyData
                          img={svgCongratulation}
                          text={
                            <>
                              <h3>
                                Chúc mừng,{' '}
                                {this.props.user.fullname
                                  ? this.props.user.fullname
                                  : this.props.user.username}
                              </h3>
                              <p>
                                Bạn đã hoàn thành mọi bài thi, hãy giữ vững
                                phong độ nhé
                              </p>
                            </>
                          }
                        />
                      ) : (
                        <>
                          {exam &&
                            exam.must &&
                            exam.todo &&
                            exam.doing &&
                            Object.entries(exam.must).length == 0 &&
                            Object.entries(exam.todo).length == 0 &&
                            Object.entries(exam.doing).length == 0 && (
                              <EmptyData
                                img={studying}
                                text={<p>Không có bài thi nào</p>}
                              />
                            )}
                        </>
                      )}
                    </>
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {loadingTab2 && <KTLoading />}
                  {exam &&
                    exam.done &&
                    Object.entries(exam.done).length > 0 && (
                      <div className="item-exam-by-class mb-4 done-exam">
                        <Row>
                          {Object.entries(exam.done).map(([k, item]) => (
                            <Col
                              key={k}
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={2}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {!loadingTab2 &&
                    exam &&
                    exam.done &&
                    Object.entries(exam.done).length == 0 && (
                      <EmptyData
                        img={studying}
                        text={<p>Không có bài thi nào</p>}
                      />
                    )}
                </TabPane>
                <TabPane tabId="3">
                  {loadingTab3 && <KTLoading />}
                  {exam &&
                    exam.cancel &&
                    Object.entries(exam.cancel).length > 0 && (
                      <div className="item-exam-by-class mb-4">
                        <Row>
                          {Object.entries(exam.cancel).map(([k, item]) => (
                            <Col
                              key={k}
                              xs="12"
                              md={activeSort == 2 ? '6' : '12'}
                              lg={activeSort == 2 ? '4' : '12'}
                              xl={activeSort == 2 ? '3' : '12'}
                              className="mb-3"
                            >
                              <TestTakerItem
                                onShowCode={this.onShowCode}
                                isShowCode={showCodeRepo[item.id]}
                                timestamp={timestamp}
                                item={item}
                                display={activeSort}
                                type={3}
                                bookmarkItem={this.bookmarkItem}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  {!loadingTab3 &&
                    exam &&
                    exam.cancel &&
                    Object.entries(exam.cancel).length == 0 && (
                      <EmptyData
                        img={studying}
                        text={<p>Không có bài thi nào</p>}
                      />
                    )}
                </TabPane>
                <TabPane tabId="4">
                  {exam &&
                    exam.must &&
                    Object.entries(exam.must).length > 0 && (
                      <Row>
                        {exam.must.map((item) => (
                          <Col
                            xs="12"
                            md={activeSort == 2 ? '6' : '12'}
                            lg={activeSort == 2 ? '4' : '12'}
                            xl={activeSort == 2 ? '3' : '12'}
                            className="mb-3"
                            key={item.id}
                          >
                            <TestTakerItem
                              onShowCode={this.onShowCode}
                              isShowCode={showCodeRepo[item.id]}
                              timestamp={timestamp}
                              item={item}
                              display={activeSort}
                              type={1}
                              bookmarkItem={this.bookmarkItem}
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  {exam &&
                    exam.must &&
                    Object.entries(exam.must).length == 0 && (
                      <EmptyData
                        img={studying}
                        text={<p>Không có bài thi nào</p>}
                      />
                    )}
                </TabPane>
                <TabPane tabId="5">
                  {orders != null && orders.length > 0 && (
                    <Row>
                      {orders.map((item) => {
                        let showAdditionalRegisterUrl = true;
                        orders.forEach((o) => {
                          if (
                            o.id != item.id &&
                            o.registerProfileUrl.url ==
                              item.registerProfileUrl?.configs
                                ?.current_additional_url
                          ) {
                            showAdditionalRegisterUrl = false;
                          }
                        });

                        return (
                          <Col
                            xs="12"
                            md={6}
                            lg={6}
                            xl={4}
                            className="mb-3"
                            key={item.id}
                          >
                            <Card
                              className="x-card"
                              title={
                                <Tooltip
                                  placement="top"
                                  title={item.invoice_description}
                                >
                                  {item.invoice_description}
                                </Tooltip>
                              }
                              bordered={false}
                              actions={[
                                <div className="m-2">
                                  {item.status == 1 ? (
                                    <>
                                      {item.registerProfileUrl.test_type ==
                                      1 ? (
                                        <Button
                                          block
                                          className="b-r-4"
                                          type="primary"
                                          ghost
                                          onClick={() => {
                                            this.toggle('1');
                                          }}
                                        >
                                          Xem kế hoạch
                                        </Button>
                                      ) : (
                                        <Link
                                          className="ant-btn ant-btn-primary ant-btn-background-ghost ant-btn-block b-r-4"
                                          to={`/dang-ky-dia-diem-thi/${
                                            item.registerProfileUrl?.configs
                                              ?.parent_url ||
                                            item.registerProfileUrl.url
                                          }`}
                                          type="primary"
                                        >
                                          Đăng ký địa điểm thi / Chi tiết lịch
                                          thi
                                        </Link>
                                      )}
                                    </>
                                  ) : (
                                    <Link
                                      className="ant-btn-block"
                                      to={`/dk/${item.customer_invoice_data['url']}/${item.id}`}
                                    >
                                      <Button block className="b-r-4">
                                        Tiếp tục đăng ký
                                      </Button>
                                    </Link>
                                  )}
                                </div>,
                              ]}
                            >
                              <div className="info-order">
                                {item.order_id && (
                                  <>
                                    Mã đơn hàng: {item.order_id}
                                    <br />
                                  </>
                                )}
                                Tạo lúc:{' '}
                                {moment(item.invoice_created_ts).format(
                                  'DD/MM/YYYY HH:mm',
                                )}
                                <br />
                                {item.invoice_paid_ts && (
                                  <>
                                    Thanh toán lúc:{' '}
                                    {moment(item.invoice_paid_ts).format(
                                      'DD/MM/YYYY HH:mm',
                                    )}
                                    <br />
                                  </>
                                )}
                                Số tiền thanh toán:{' '}
                                <b className="">
                                  {formatVndMoney(item.invoice_total_amount)}
                                </b>
                                <br />
                                Trạng thái:{' '}
                                <Text
                                  strong
                                  type={item.status == 1 ? 'success' : 'danger'}
                                >
                                  {item.status == 1
                                    ? 'Đã thanh toán'
                                    : item.status == -1
                                    ? 'Đã hủy'
                                    : 'Chưa thanh toán'}
                                </Text>
                                {item.status == 1 &&
                                  !!item.customer_invoice_data?.subjectData &&
                                  item?.profile?.code == 'HUST' && (
                                    <>
                                      <br />
                                      <i>
                                        Vui lòng kiểm tra thông tin dự thi qua
                                        email
                                      </i>
                                    </>
                                  )}{' '}
                                {item.status == 1 &&
                                  !!item.customer_invoice_data?.testDayData && (
                                    <>
                                      <br />
                                      <p className="m-0">
                                        Đợt thi đã đăng ký:
                                        {(item.customer_invoice_data
                                          ?.testDayData?.test_day_1 == 0 ||
                                          item.customer_invoice_data
                                            ?.testDayData?.test_day_2 == 0 ||
                                          item.customer_invoice_data
                                            ?.testDayData?.test_day_3 == 0) &&
                                          item?.registerProfileUrl?.configs
                                            ?.current_additional_url &&
                                          showAdditionalRegisterUrl && (
                                            <Link
                                              to={`/dk/${item?.registerProfileUrl?.configs?.current_additional_url}`}
                                            >
                                              <Tooltip title="Lưu ý: Mỗi học sinh chỉ được đăng ký bổ sung một lần.">
                                                <b className="bold text-right text-primary">
                                                  <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                  ></i>{' '}
                                                  Đăng ký bổ sung
                                                </b>
                                              </Tooltip>
                                            </Link>
                                          )}
                                      </p>
                                      {item.customer_invoice_data?.testDayData
                                        ?.test_day_1 == 1 && (
                                        <p className="m-0">
                                          <b>
                                            <i
                                              className="fa fa-angle-right"
                                              aria-hidden="true"
                                            ></i>
                                            {item?.registerProfileUrl?.configs
                                              ?.test_days?.length > 0 &&
                                              item?.registerProfileUrl?.configs?.test_days.map(
                                                (t) => {
                                                  if (t.id == 1) {
                                                    return t.name;
                                                  }
                                                },
                                              )}
                                          </b>
                                        </p>
                                      )}
                                      {item.customer_invoice_data?.testDayData
                                        ?.test_day_2 == 1 && (
                                        <p className="m-0">
                                          <b>
                                            <i
                                              className="fa fa-angle-right"
                                              aria-hidden="true"
                                            ></i>{' '}
                                            {item?.registerProfileUrl?.configs
                                              ?.test_days?.length > 0 &&
                                              item?.registerProfileUrl?.configs?.test_days.map(
                                                (t) => {
                                                  if (t.id == 2) {
                                                    return t.name;
                                                  }
                                                },
                                              )}
                                          </b>
                                        </p>
                                      )}
                                      {item.customer_invoice_data?.testDayData
                                        ?.test_day_3 == 1 && (
                                        <p className="m-0">
                                          <b>
                                            <i
                                              className="fa fa-angle-right"
                                              aria-hidden="true"
                                            ></i>{' '}
                                            {item?.registerProfileUrl?.configs
                                              ?.test_days?.length > 0 &&
                                              item?.registerProfileUrl?.configs?.test_days.map(
                                                (t) => {
                                                  if (t.id == 3) {
                                                    return t.name;
                                                  }
                                                },
                                              )}
                                          </b>
                                        </p>
                                      )}
                                    </>
                                  )}
                                <p className="m-0">
                                  Hồ sơ:{' '}
                                  <Link
                                    to={`/profile/${item?.profile?.code.toLowerCase()}/${
                                      item?.profile?.id
                                    }`}
                                  >
                                    <u>Xem hồ sơ / cập nhật</u>
                                  </Link>
                                </p>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                  {orders != null && orders.length == 0 && (
                    <EmptyData
                      img={svgNoData}
                      text={
                        <p>
                          Bạn chưa đăng ký kỳ thi nào. &nbsp;
                          <Button type="primary">
                            <Link to={PATHS.listExam}>Đăng ký thi</Link>
                          </Button>
                        </p>
                      }
                    />
                  )}
                  {orders == null && <KTLoading />}
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
        {/* <Modal
          visible={this.state.showSelectSubjectModal}
          onOk={() => {
            this.setState({
              showSelectSubjectModal: false,
            });
          }}
          onCancel={() => {
            this.setState({
              showSelectSubjectModal: false,
            });
          }}
          footer={null}
          keyboard
          maskClosable
          closable
          width={400}
          centered
          className="modal-setting-info modal-confirm-register"
          closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
        >
          <div className="btn-block">
            <b>Chọn bài thi:</b>
            <br />
            <br />
            <Checkbox
              checked={this.state.updateSubjects.indexOf('subject_khtn') !== -1}
              onChange={(e) => {
                console.log(this.state.updateSubjects.indexOf('subject_khtn'));

                if (
                  e.target.checked &&
                  this.state.updateSubjects.indexOf('subject_khtn') === -1
                ) {
                  let updateSubjects = this.state.updateSubjects;
                  updateSubjects.push('subject_khtn');
                  console.log(updateSubjects);
                  this.setState({
                    updateSubjects: updateSubjects,
                  });
                }
                if (
                  !e.target.checked &&
                  this.state.updateSubjects.indexOf('subject_khtn') !== -1
                ) {
                  let updateSubjects = this.state.updateSubjects;
                  updateSubjects.splice(
                    this.state.updateSubjects.indexOf('subject_khtn'),
                    1,
                  );
                  this.setState({
                    updateSubjects: updateSubjects,
                  });
                }
              }}
            >
              Khoa học tự nhiên
            </Checkbox>
            <Checkbox
              checked={
                this.state.updateSubjects.indexOf('subject_english') !== -1
              }
              onChange={(e) => {
                if (
                  e.target.checked &&
                  this.state.updateSubjects.indexOf('subject_english') === -1
                ) {
                  let updateSubjects = this.state.updateSubjects;
                  updateSubjects.push('subject_english');
                  this.setState({
                    updateSubjects: updateSubjects,
                  });
                }
                if (
                  !e.target.checked &&
                  this.state.updateSubjects.indexOf('subject_english') !== -1
                ) {
                  let updateSubjects = this.state.updateSubjects;
                  updateSubjects.splice(
                    this.state.updateSubjects.indexOf('subject_english'),
                    1,
                  );
                  this.setState({
                    updateSubjects: updateSubjects,
                  });
                }
              }}
            >
              Tiếng Anh
            </Checkbox>
            <br />
            <br />
            <div>
              <Button
                type="primary"
                className="b-r-8 mt-2"
                onClick={this.onSaveSubjectClick}
              >
                Lưu lại
              </Button>
            </div>
          </div>
        </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TestTaker);
