import React, { useState, useEffect } from 'react';
import {
  Space,
  Tag,
  Row,
  Button,
  Typography,
  Modal,
  Col,
  message,
  Skeleton,
  Card,
  Tooltip,
} from 'antd';

import classNames from 'classnames';

import { useHistory } from 'react-router';
import moment from 'moment';
import { formatDate, formatVndMoney } from 'app/utils/functions';
import { getExamPlanDetail } from 'modules/Register/service';
import ExamTypes from '../Components/ExamTypes';
import { useParams } from 'react-router-dom';
const { Text } = Typography;

const ExamPlanDetail = ({ examPlanId }) => {
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState({});
  const [examPlan, setExamPlan] = useState({});
  const [billing, setBilling] = useState({});
  const [testSite, setTestSite] = useState({});

  useEffect(() => {
    console.log('zzz', examPlanId);
    getData();
  }, []);

  const getData = async () => {
    let response = await getExamPlanDetail(examPlanId);

    setLoading(false);

    if (response.data?.code == 200) {
      setExam(response.data.data.exam);
      setExamPlan(response.data.data.examPlan);
      setBilling(response.data.data.billing);
      setTestSite(response.data.data.testSite);
    }
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      <div className="social__header">
        <div className="social__title">
          <span className="title-classes title-classes-light">
            Thông tin đăng ký, lịch thi {exam.name}
          </span>
        </div>
        <hr className="line mt-3" />
      </div>

      <Row>
        <Col span={12}>
          <p>
            <b>Thông tin đăng ký</b>
          </p>
          <p>Lệ phí thi: {formatVndMoney(billing.invoice_total_amount)}</p>
          <p>
            Trạng thái thanh toán:{' '}
            <Text>
              <Tag color={'success'} style={{ margin: 0 }}>
                Đã thanh toán
              </Tag>
            </Text>
          </p>
          <p>
            <span>Địa điểm:</span> {testSite?.name}, {testSite?.address}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <b>Thông tin dự thi</b>
          </p>
          {examPlan?.status === -1 && (
            <>
              <p>
                {examPlan?.note ||
                  'Thông tin đăng ký dự thi của bạn đã bị từ chối'}
              </p>
            </>
          )}

          {(examPlan === false ||
            examPlan?.status == 0 ||
            examPlan?.status == 1) && (
            <>
              <p>
                <span>Hội đồng thi:</span>{' '}
                {examPlan?.test_site_name || <i>Chưa có thông tin</i>}
              </p>
              <p>
                <span>Thời gian làm thủ tục:</span>{' '}
                {examPlan?.checkin_time ? (
                  moment(examPlan?.checkin_time).format('HH:mm DD/MM/YYYY')
                ) : (
                  <i>Chưa có thông tin</i>
                )}
              </p>
              {!!examPlan?.enter_room_time && (
                <p>
                  <span>Thời gian vào phòng thi:</span>{' '}
                  {examPlan?.enter_room_time ? (
                    moment(examPlan?.enter_room_time).format('HH:mm DD/MM/YYYY')
                  ) : (
                    <i>Chưa có thông tin</i>
                  )}
                </p>
              )}
              <p>
                <span>Thời gian bắt đầu thi:</span>{' '}
                {examPlan?.start_time ? (
                  moment(examPlan?.start_time).format('HH:mm DD/MM/YYYY')
                ) : (
                  <i>Chưa có thông tin</i>
                )}
              </p>
              <p>
                <span>Phòng thi:</span>{' '}
                {examPlan?.room_name || <i>Chưa có thông tin</i>}
              </p>
              <p>
                <span>Số báo danh:</span>{' '}
                {examPlan?.student_number || <i>Chưa có thông tin</i>}
              </p>
              {!!examPlan?.inform_url && (
                <p>
                  <span>Giấy báo dự thi:</span>{' '}
                  <a
                    href={examPlan?.inform_url}
                    className="text-primary"
                    target="_blank"
                  >
                    Xem tại đây
                  </a>{' '}
                  (Thi sinh cần in và đem giấy báo dự thi đến địa điểm thi)
                </p>
              )}
              {!!examPlan?.test_site_name || (
                <p>
                  <i>
                    Chi tiết địa điểm và thời gian thi sẽ được cập nhật sau khi
                    có lịch thi cụ thể.
                  </i>
                </p>
              )}
              {examPlan?.status === 1 && !!examPlan?.note && (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: examPlan?.note || '',
                    }}
                  ></p>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ExamPlanDetail;
