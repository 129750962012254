import React, { useEffect, useState } from 'react';
import { Row, Col, message, Button } from 'antd';
import ExamCard from './RegisterExamCard';
import { getListExams, guestGetListExams } from 'modules/Register/service';
import { get } from 'lodash';
import { KTLoading } from 'core/ui';
import imgTestOnline from 'themes/default/svg/online-test.svg';
import imgNoTestOnline from 'themes/default/svg/no-online-test.svg';
import PATHS from 'themes/paths';
import { Link } from 'react-router-dom';

const ListExamBody = ({
  isGuest,
  showCopyRight = false,
  emailVerified = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [exams, setExams] = useState([]);
  const [testingToken, setTestingToken] = useState('');

  const componentDidMount = async () => {
    setLoading(true);

    const response = isGuest ? await guestGetListExams() : await getListExams();

    setLoaded(true);

    if (response.data.code === 200) {
      const exams = response.data.data.exams.map((e) => {
        try {
          e.test_urls = e.test_urls ? JSON.parse(e.test_urls) : [];
        } catch (error) {
          e.test_urls = [];
        }

        return e;
      });

      setExams(exams);

      setTestingToken(response.data.data?.testingToken);
    } else {
      message.error('Có lỗi xảy ra, vui lòng tải lại trang (F5)');
    }
    setLoading(false);
  };

  useEffect(() => {
    componentDidMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row
        gutter={16}
        justify="space-between"
        align="middle"
        style={{ minHeight: 255 }}
      >
        {loading && <KTLoading />}
        {!loading && loaded && exams.length === 0 && (
          <div style={{ width: '100%', textAlign: 'center', paddingTop: 8 }}>
            {isGuest && (
              <>
                <p>Hiện tại chưa có bài thi nào mở đăng ký.</p>
                <p>
                  Thí sinh&nbsp;
                  <Link to={PATHS.login}>
                    <Button size="small" type="primary">
                      Đăng nhập
                    </Button>
                  </Link>
                  &nbsp;để theo dõi/thi các bài thi đã đăng ký!
                </p>
                <p>
                  <img
                    style={{ width: 320, maxWidth: '100%' }}
                    src={imgTestOnline}
                    alt="Online test"
                  />
                </p>
              </>
            )}
            {!isGuest && (
              <>
                <p>
                  <img
                    style={{ width: 320, maxWidth: '100%' }}
                    src={imgNoTestOnline}
                    alt="Online test"
                  />
                </p>
                <p>Hiện tại chưa có bài thi nào mở đăng ký.</p>
              </>
            )}
          </div>
        )}
        {!loading && (
          <>
            {exams.map((exam, index) => {
              return (
                <Col key={index} xs={24} sm={24} md={12}>
                  <div style={{ marginBottom: 16 }}>
                    <ExamCard
                      exam={exam}
                      isGuest={isGuest}
                      showActions={true}
                      testingToken={testingToken}
                      emailVerified={emailVerified}
                    />
                  </div>
                </Col>
              );
            })}
          </>
        )}
      </Row>
      {showCopyRight && (
        <Row
          style={{
            width: '100%',
            fontSize: 12,
            textAlign: 'center',
            display: 'block',
            marginTop: 20,
          }}
        >
          Copyright © 2021 by TSA.HUST
          <br />
          Operated by FPT IS | Powered by Khaothi.Online
        </Row>
      )}
    </>
  );
};

export default ListExamBody;
