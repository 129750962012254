import theme from 'themes';
import Dashboard from './modules/Dashboard/Screens/Dashboard';
import Connective from './modules/User/Screens/Connective';
import Classes from './modules/User/Screens/Classes';
import TestTakerDetail from './modules/User/Screens/TestTakerDetail';
import ProfileDetail from './modules/User/Screens/ProfileDetail';
import ClassesDetail from './modules/User/Screens/ClassesDetail';
import ChangePassword from './modules/User/Screens/ChangePassword';
/* import Syll from './modules/Profile/Screens/Syll';
import VnuHcm from './modules/Profile/Screens/VnuHcm'; */
import ListOrder from './modules/AdminOrder/Screens/ListOrder';
import ListPartner from './modules/AdminPartner/Screens/ListPartner';
import PartnerForm from './modules/AdminPartner/Screens/PartnerForm';
import ListExam from 'modules/Exam/Screens/ListExam';
import PATHS from 'themes/paths';
import AdminListExam from 'modules/AdminExam/Screens/AdminListExam';
import AdminExamForm from 'modules/AdminExam/Screens/AdminExamForm';

import LoginKT from './modules/User/Screens/kt/Login';
import RegisterKT from './modules/User/Screens/kt/Register';
import ProfileKT from './modules/User/Screens/kt/Profile';
import TestTakerKT from './modules/User/Screens/kt/TestTaker';

import LoginHust from './modules/User/Screens/hust/Login';
import RegisterHust from './modules/User/Screens/hust/Register';
import ProfileHust from './modules/User/Screens/hust/Profile';
import TestTakerHust from './modules/User/Screens/hust/TestTaker';
import Reference from 'modules/Register/Screens/Reference';
import TablePoint from 'modules/User/Screens/TablePoint';

let Login = LoginKT;
let Register = RegisterKT;
let Profile = ProfileKT;
let TestTaker = TestTakerKT;

switch (theme.config.themeName) {
  case 'hust':
    Login = LoginHust;
    Register = RegisterHust;
    Profile = ProfileHust;
    TestTaker = TestTakerHust;
    break;

  default:
    break;
}

const routes = [
  {
    path: PATHS.reference,
    auth: false,
    component: Reference,
  },
  { path: PATHS.login, auth: false, component: Login },
  {
    path: PATHS.register,
    auth: false,
    component: Register,
  },
  {
    path: PATHS.profile,
    exact: true,
    name: 'Hồ sơ cá nhân',
    component: Profile,
  },
  {
    path: PATHS.listExam,
    exact: true,
    component: ListExam,
  },
  /*   { path: '/profile/vnuhcm', name: 'Hồ sơ VNU-HCM', component: VnuHcm },
  { path: '/profile/vnuhcm/:id', name: 'Hồ sơ VNU-HCM', component: VnuHcm },
  { path: '/profile/syll', name: 'Hồ sơ VNU-HCM', component: Syll },
  { path: '/profile/syll/:id', name: 'Hồ sơ VNU-HCM', component: Syll }, */
  {
    path: PATHS.changePassword,
    name: 'Đổi mật khẩu',
    component: ChangePassword,
  },
  { path: '/profile/:id', name: 'Hồ sơ cá nhân', component: ProfileDetail },
  { path: PATHS.connective, component: Connective },
  {
    path: PATHS.classes,
    exact: true,
    component: Classes,
  },
  {
    path: PATHS.classesDetail,
    exact: true,
    component: ClassesDetail,
  },
  {
    path: PATHS.testTaker,
    exact: true,
    component: TestTaker,
  },
  {
    path: PATHS.testTakerDetail,
    exact: true,
    component: TestTakerDetail,
  },
  {
    path: PATHS.tablePoint,
    exact: true,
    component: TablePoint,
  },

  {
    isRoot: true,
    path: '/orders',
    exact: true,
    name: 'Quản lý đăng ký',
    component: ListOrder,
  },
  {
    isRoot: true,
    path: '/admin/exams',
    exact: true,
    name: 'Quản lý kỳ thi',
    component: AdminListExam,
  },
  {
    isRoot: true,
    path: '/admin/exams/create',
    exact: true,
    name: 'Thêm kỳ thi',
    component: AdminExamForm,
  },
  {
    isRoot: true,
    path: '/admin/exams/edit/:id',
    exact: true,
    name: 'Sửa kỳ thi',
    component: AdminExamForm,
  },
  {
    isRoot: true,
    path: '/partners',
    exact: true,
    name: 'Đối tác',
    component: ListPartner,
  },
  {
    isRoot: true,
    path: '/partners/create',
    exact: true,
    name: 'Thêm đối tác',
    component: PartnerForm,
  },
  {
    isRoot: true,
    path: '/partners/edit/:id',
    exact: true,
    name: 'Sửa đối tác',
    component: PartnerForm,
  },
  { path: '/', name: '', component: Dashboard },
  { isRoot: true, path: '/', name: '', component: ListOrder },
].filter((item) => !theme.config.excludePaths.includes(item.path));

export default routes;
