const PROFILE_TYPE = {
  hust: {
    name: 'Hồ sơ dự thi',
    code: 'HUST',
  },
  basic: {
    name: 'Hồ sơ cơ bản',
    code: 'BASIC',
  },
  vnuhcm: {
    name: 'Hồ sơ dự thi',
    code: 'VNUHCM',
  },
};

export default PROFILE_TYPE;
