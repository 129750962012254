import axios from 'axios';
import config from '../../app/config';
import QS from '../../app/utils/query-string';

export function getOrders(params) {
  const apiUrl = `${config.getApiDomain()}/admin/orders`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
    params: params,
  }).then((response) => response);
}
export function updateOrderStatus(id, status) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/admin/update-order-status`,
    data: QS.stringify({
      id: id,
      status: status,
    }),
  });
}

export function exportOrders(params) {
  const apiUrl = `${config.getApiDomain()}/admin/export-orders`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
    params: params,
  }).then((response) => response);
}

export function getRegisterProfileUrls() {
  const apiUrl = `${config.getApiDomain()}/admin/exams`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}
