import React, { Component } from 'react';
import {
  Dropdown,
  Tooltip,
  Card,
  Avatar,
  Button,
  Badge,
  Menu,
  Input,
  message,
  Tag,
} from 'antd';
import {
  SyncOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
  FormOutlined,
  MoreOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import config from '../../app/config';
import { get } from 'lodash';
import { changeNameClass } from '../../modules/User/service';
import { buildRouter, getErrorMessages } from '../../app/utils/functions';
import PATHS from 'themes/paths';

class xCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editClass: false,
      nameClass: this.props.item.custom_name,
      item: this.props.item,
    };
  }

  deleteConnectiveClass = (item) => {
    if (this.props.deleteConnectiveClass) {
      this.props.deleteConnectiveClass(item);
    }
  };

  editToggle = () => {
    const { editClass } = this.state;
    this.setState(
      { editClass: !editClass, nameClass: this.state.item.custom_name },
      () => {
        if (this.state.editClass) {
          this.textInput.focus();
        }
      },
    );
  };

  saveNameClass = async (reset = 0) => {
    const { nameClass } = this.state;
    if (!nameClass && reset == 0) {
      return message.error('Tên lớp học không được để trống');
    }
    const response = await changeNameClass(
      this.props.item.id,
      this.state.nameClass,
      reset,
    );
    if (response.data.code == 200) {
      message.success(response.data.message);
      this.setState({
        editClass: false,
        nameClass: response.data.data.class.custom_name,
        item: {
          ...this.state.item,
          custom_name: response.data.data.class.custom_name,
        },
      });
    } else {
      return message.error(getErrorMessages(response.data));
    }
  };

  onFormChange = (event) => {
    const nameClass = event.target.value;
    this.setState({ nameClass });
  };

  render() {
    const { item, teacherName, lstClass, edit, hideAction, hideExtra } =
      this.props;
    const { editClass, nameClass } = this.state;

    let mustArr = get(lstClass, `exam['${item.class_id}'].must`, []).filter(
      (e) => e.subscription == item.subscription,
    );

    let todoArr = get(lstClass, `exam['${item.class_id}'].todo`, []).filter(
      (e) => e.subscription == item.subscription,
    );

    if (!Array.isArray(mustArr)) {
      mustArr = [];
    }

    if (!Array.isArray(todoArr)) {
      todoArr = [];
    }

    const countExam = todoArr.length + mustArr.length;
    let classItem = null;

    if (countExam) {
      classItem = mustArr.length ? mustArr[0] : todoArr[0];
    }

    const countMustToday = mustArr.length;
    const grade = lstClass.class[`${item.code}`].grade || [];
    const subject = lstClass.class[`${item.code}`].subject || [];

    return (
      <Card
        className={
          `${countExam > 0 ? 'card-body-position' : ''}` +
          ' x-card card-classes'
        }
        title={
          !editClass ? (
            <Tooltip placement="top" title={nameClass}>
              {!hideAction ? (
                <Link
                  color="link"
                  to={buildRouter(PATHS.classesDetail, { id: item.id })}
                  className="px-0"
                >
                  {nameClass}
                </Link>
              ) : (
                nameClass
              )}
            </Tooltip>
          ) : (
            <Input
              ref={(input) => {
                this.textInput = input;
              }}
              placeholder=""
              defaultValue={nameClass}
              bordered={false}
              onChange={this.onFormChange}
            />
          )
        }
        extra={
          !hideAction ? (
            <>
              {!editClass ? (
                <Dropdown
                  placement="bottomRight"
                  trigger={['click']}
                  overlay={
                    <Menu className="extra-action">
                      {edit && (
                        <Menu.Item onClick={() => this.editToggle()}>
                          <FormOutlined className="mr-2" />
                          Đổi tên lớp
                        </Menu.Item>
                      )}
                      <Menu.Item
                        danger
                        onClick={() => this.deleteConnectiveClass(item)}
                      >
                        <ExportOutlined className="mr-2" />{' '}
                        {edit ? 'Rời khỏi lớp' : 'Hủy đăng ký'}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MoreOutlined />
                </Dropdown>
              ) : (
                [
                  <Tooltip
                    placement="top"
                    title="Lưu lại"
                    key="save"
                    onClick={() => this.saveNameClass(0)}
                  >
                    <CheckCircleOutlined className="primary f-s-l mr-1" />
                  </Tooltip>,
                  <Tooltip
                    placement="top"
                    title="Đặt lại tên mặc định"
                    key="reset"
                    onClick={() => this.saveNameClass(1)}
                  >
                    <SyncOutlined className="warning f-s-l mr-1" />
                  </Tooltip>,
                  <Tooltip placement="top" title="Hủy" key="cancel">
                    <CloseCircleOutlined
                      className="danger f-s-l mr-1"
                      onClick={() => this.editToggle()}
                    />
                  </Tooltip>,
                ]
              )}
            </>
          ) : (
            []
          )
        }
        actions={
          hideExtra
            ? []
            : [
                <div className="flex space-around mb-2" key="edit1">
                  <div className="group-label-exam">
                    <Button className="btn-info" shape="circle">
                      {edit ? (
                        <Link
                          color="link"
                          to={buildRouter(PATHS.classesDetail, { id: item.id })}
                          className="px-0"
                        >
                          {countExam}
                        </Link>
                      ) : (
                        countExam
                      )}
                    </Button>
                    <span>Bài chưa làm</span>
                  </div>
                  <div className="group-label-exam">
                    <Button
                      className="danger"
                      type="primary"
                      danger
                      shape="circle"
                    >
                      {edit ? (
                        <Link
                          color="link"
                          to={buildRouter(`${PATHS.classesDetail}?type=4`, {
                            id: item.id,
                          })}
                          className="px-0"
                        >
                          {countMustToday}
                        </Link>
                      ) : (
                        countMustToday
                      )}
                    </Button>
                    <span>Cần làm</span>
                  </div>
                </div>,
              ]
        }
      >
        <div className="mb-2 tags">
          {grade && grade.length > 1 ? (
            <Dropdown
              overlay={
                <Menu>
                  {grade &&
                    grade.map((item) => (
                      <Menu.Item key={item}>{item}</Menu.Item>
                    ))}
                </Menu>
              }
            >
              <Tag className="bg-primary">
                Khối lớp <Badge count={grade.length} />
              </Tag>
            </Dropdown>
          ) : (
            <>{grade[0] && <Tag className="bg-primary">{grade[0]}</Tag>}</>
          )}
          {subject && subject.length > 1 ? (
            <Dropdown
              overlay={
                <Menu>
                  {subject &&
                    subject.map((item) => (
                      <Menu.Item key={item}>{item}</Menu.Item>
                    ))}
                </Menu>
              }
            >
              <Tag className="bg-success">
                Môn học <Badge count={subject.length} />
              </Tag>
            </Dropdown>
          ) : (
            <>{subject[0] && <Tag className="bg-success">{subject[0]}</Tag>}</>
          )}
        </div>
        <p>
          Mã lớp: <b>{item.code} </b>
        </p>
        <p>
          Sĩ số:{' '}
          {lstClass.class[`${item.code}`] &&
          lstClass.class[`${item.code}`].student_count
            ? lstClass.class[`${item.code}`].student_count
            : 0}
        </p>
        <p className="">
          <Avatar icon={<UserOutlined />} className="avatar-gv mr-2" />{' '}
          {teacherName ? <b>{teacherName}</b> : 'Đang cập nhật'}
        </p>

        {countExam > 0 && edit && (
          <Dropdown
            className=""
            placement="bottomRight"
            trigger={['click']}
            overlay={
              <Menu className="extra-action noti-exam-lst">
                <Menu.Item>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${config.getApiDomainE()}?code=${
                      classItem.code
                    }&url_callback=${process.env.REACT_APP_DOMAIN}test-taker/${
                      classItem.id
                    }`}
                    className="f-s-m"
                  >
                    {classItem.name}
                  </a>
                </Menu.Item>
              </Menu>
            }
          >
            <Tooltip placement="left" title="Bài thi bạn cần làm">
              <ExclamationCircleOutlined className="warning f-s-xl icon-noti-exam" />
            </Tooltip>
          </Dropdown>
        )}
      </Card>
    );
  }
}
export default xCard;
