const nations = [
  { value: '1', label: 'Kinh (Việt)' },
  { value: '54', label: 'Người nước ngoài' },
  { value: '2', label: 'Tày' },
  { value: '3', label: 'Thái' },
  { value: '4', label: 'Hoa (Hán)' },
  { value: '5', label: 'Khơ- Me' },
  { value: '6', label: 'Mường' },
  { value: '7', label: 'Nùng' },
  { value: '8', label: 'HMông (Mèo)' },
  { value: '9', label: 'Dao' },
  { value: '10', label: 'Gia- Rai' },
  { value: '11', label: 'Ngái' },
  { value: '12', label: 'Ê- đê' },
  { value: '13', label: 'Ba Na' },
  { value: '14', label: 'Xơ - Đăng' },
  { value: '15', label: 'Sán Chay (Cao Lan-Sán Chỉ)' },
  { value: '16', label: 'Cơ - Ho' },
  { value: '17', label: 'Chăm (Chàm)' },
  { value: '18', label: 'Sán Dìu' },
  { value: '19', label: 'Hrê' },
  { value: '20', label: 'Mnông' },
  { value: '21', label: 'Ra- glai' },
  { value: '22', label: 'Xtiêng' },
  { value: '23', label: 'Bru- Vân Kiều' },
  { value: '24', label: 'Thổ' },
  { value: '25', label: 'Giáy' },
  { value: '26', label: 'Cơ- Tu' },
  { value: '27', label: 'Gié Triêng' },
  { value: '28', label: 'Mạ' },
  { value: '29', label: 'Khơ- Mú' },
  { value: '30', label: 'Co' },
  { value: '31', label: 'Tà-Ôi' },
  { value: '32', label: 'Chơ- Ro' },
  { value: '33', label: 'Kháng' },
  { value: '34', label: 'Xinh- Mun' },
  { value: '35', label: 'Hà Nhì' },
  { value: '36', label: 'Churu' },
  { value: '37', label: 'Lào' },
  { value: '38', label: 'LaHa' },
  { value: '39', label: 'Phù Lá' },
  { value: '40', label: 'La Hủ' },
  { value: '41', label: 'Lự' },
  { value: '42', label: 'Lô Lô' },
  { value: '43', label: 'Chứt' },
  { value: '44', label: 'Mảng' },
  { value: '45', label: 'Pà Thẻn' },
  { value: '46', label: 'Cơ Lao' },
  { value: '47', label: 'Cống' },
  { value: '48', label: 'Bố Y' },
  { value: '49', label: 'Si La' },
  { value: '50', label: 'Pu Péo' },
  { value: '51', label: 'Brâu' },
  { value: '52', label: 'Ơ Đu' },
  { value: '53', label: 'Rơ măm' },
  { value: '-1', label: 'Khác' },
];

export default nations;
