import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { DefaultLayout } from './containers';
import ChangePassword from './modules/User/Screens/ChangePassword';
import store from './app/store';
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import Hust from './modules/Profile/Screens/Hust';
import ListExamGuest from 'modules/Exam/Screens/ListExamGuest';
import RegisterExam from 'modules/Register/Screens/RegisterExam';
import Basic from 'modules/Profile/Screens/Basic';
import theme from 'themes';
import PATHS from 'themes/paths';
import { KTGaListener } from 'core/ui';
import { isCustomDomain } from './app/utils/functions';
import Reference from 'modules/Register/Screens/Reference';

import LoginKT from './modules/User/Screens/kt/Login';
import RegisterKT from './modules/User/Screens/kt/Register';

import LoginHust from './modules/User/Screens/hust/Login';
import RegisterHust from './modules/User/Screens/hust/Register';
import { FacebookOutlined } from '@ant-design/icons';

let Login = LoginKT;
let Register = RegisterKT;

switch (theme.config.themeName) {
  case 'hust':
    Login = LoginHust;
    Register = RegisterHust;
    break;

  default:
    break;
}

class App extends Component {
  render() {
    let basename = null;

    if (isCustomDomain()) {
      basename = '/my';
    }

    const { login } = this.props;

    return (
      <ConfigProvider locale={viVN}>
        <Provider store={store}>
          <BrowserRouter basename={basename}>
            <KTGaListener>
              <Switch>
                <Route exact path={PATHS.reference} component={Reference} />
                <Route exact path={PATHS.exam} component={ListExamGuest} />
                <Route exact path={PATHS.examDetail} component={RegisterExam} />
                <Route
                  exact
                  path={PATHS.examDetailContinue}
                  component={RegisterExam}
                />
                {!login && <Route exact path={PATHS.login} component={Login} />}
                {!login && (
                  <Route exact path={PATHS.register} component={Register} />
                )}
                {login && (
                  <Route exact path={PATHS.login}>
                    <Redirect
                      to={{ pathname: theme.config.auth.loginRedirect }}
                    />
                  </Route>
                )}
                {login && (
                  <Route exact path={PATHS.register}>
                    <Redirect
                      to={{ pathname: theme.config.auth.loginRedirect }}
                    />
                  </Route>
                )}
                {login && (
                  <Route
                    exact
                    path={PATHS.changePassword}
                    component={ChangePassword}
                  />
                )}
                <Route
                  exact
                  path="/profile/hust"
                  name="HUST"
                  component={Hust}
                />
                {login && (
                  <Route
                    exact
                    path="/profile/hust/:id"
                    name="HUST"
                    component={Hust}
                  />
                )}
                <Route
                  exact
                  path="/profile/basic"
                  name="Basic"
                  component={Basic}
                />
                {login && (
                  <Route
                    exact
                    path="/profile/basic/:id"
                    name="Basic"
                    component={Basic}
                  />
                )}

                {login && (
                  <Route
                    path="/"
                    name="DefaultLayout"
                    component={DefaultLayout}
                  />
                )}
                {!login && (
                  <Route path="/">
                    <Redirect to={{ pathname: theme.config.guestHomePath }} />
                  </Route>
                )}
              </Switch>
            </KTGaListener>

            {process.env.REACT_APP_FACEBOOK_PAGE_NAME &&
              process.env.REACT_APP_FACEBOOK_PAGE_NAME?.indexOf('@@') > 0 && (
                <div
                  style={{
                    position: 'fixed',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bottom: 0,
                    right: 15,
                    zIndex: 99,
                    width: 224,
                    height: 28,
                    background: '#3b5997',
                    padding: 1,
                    fontSize: 15,
                    borderRadius: '4px 4px 0 0',
                    textAlign: 'center',
                  }}
                >
                  <a
                    href={process.env.REACT_APP_FACEBOOK_PAGE_URL}
                    style={{ color: '#fff' }}
                  >
                    {/*  <img src="/assets/img/bkqr.png" style={{ maxWidth: '100%' }} /> */}
                    <span style={{ padding: 2 }}>
                      <FacebookOutlined
                        style={{ marginTop: -1, fontSize: 20 }}
                      />{' '}
                      {process.env.REACT_APP_FACEBOOK_PAGE_NAME}
                    </span>
                  </a>
                </div>
              )}
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    );
  }
}

export default App;
