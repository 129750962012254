import store from './store';
import ic_user from '../assets/img/theme/ic_user.png';
import load from '../themes/default/loading.svg';
import PATHS from 'themes/paths';

const config = {
  getAll() {
    return store.getState().config;
  },
  getAvatarDefault() {
    return ic_user;
  },
  getLoadingDefault() {
    return load;
  },
  getConfig(configKey) {
    if (typeof this.getAll()[configKey] != 'undefined') {
      return this.getAll()[configKey];
    }

    return false;
  },
  getConfigNameByValue(configKey, configVal) {
    let name = null;
    const configs = this.getConfig(configKey);
    configs.forEach((element) => {
      if (element.value == configVal) {
        name = element.name;
      }
    });

    return name;
  },
  getApiDomain() {
    return process.env.REACT_APP_API_DOMAIN;
  },
  getApiDomainE() {
    return process.env.REACT_APP_E_DOMAIN;
  },
  getApiDomainOrc() {
    return process.env.REACT_APP_ORC_API_DOMAIN;
  },
  getUrlLoginGG(returnUrl) {
    let redirect_uri = `${process.env.REACT_APP_DOMAIN}${PATHS.login}`;
    if (returnUrl) {
      redirect_uri = `${redirect_uri}?returnUrl=${returnUrl}`;
    }

    return `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${
      process.env.REACT_APP_KEYCLOAK_REALM
    }/protocol/openid-connect/auth?client_id=${
      process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    }&redirect_uri=${redirect_uri}&state=${this.uuidv4()}&response_type=code&scope=openid&kc_idp_hint=google`;
  },
  getUrlLoginFB(returnUrl) {
    let redirect_uri = `${process.env.REACT_APP_DOMAIN}${PATHS.login}`;
    if (returnUrl) {
      redirect_uri = `${redirect_uri}?returnUrl=${returnUrl}`;
    }

    return `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${
      process.env.REACT_APP_KEYCLOAK_REALM
    }/protocol/openid-connect/auth?client_id=${
      process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    }&redirect_uri=${redirect_uri}&state=${this.uuidv4()}&response_type=code&scope=openid&kc_idp_hint=facebook`;
  },
  getApiDomainA(subscription) {
    if (subscription == 'P1') {
      return `${process.env.REACT_APP_API_A_DOMAIN}/ap1`;
    }

    return `${process.env.REACT_APP_API_A_DOMAIN}/ap3`;
  },
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c == 'x' ? r : (r & 0x3) | 0x8;

      return v.toString(16);
    });
  },
};

export default config;
