import React, { Component } from 'react';
import { Input } from 'antd';
import { Row, Col } from 'reactstrap';
import 'moment/locale/vi';
import TemplateItem from './TemplateItem';

class TemplateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: this.props.data,
    };
  }

  onSearch = (e) => {
    const filterTemplate = this.props.data.filter(
      (item) =>
        !item.code.toLowerCase().indexOf(e.target.value.toLowerCase()) ||
        !item.name.toLowerCase().indexOf(e.target.value.toLowerCase()),
    );
    this.setState({ template: filterTemplate });
  };

  render() {
    const { template } = this.state;

    return (
      <Row>
        <Col xs="12" className="mb-4 filter-temp">
          <Input
            placeholder="Tìm kiếm"
            className="filter-tem-right"
            allowClear
            style={{ width: '100%' }}
            onChange={this.onSearch}
          />
        </Col>
        {template.map((item) => (
          <Col xs="12" md={4} key={item.id}>
            <TemplateItem item={item} />
          </Col>
        ))}
        {template.length <= 0 && (
          <div className="text-center">Không có dữ liệu</div>
        )}
      </Row>
    );
  }
}
export default TemplateList;
