import { Menu } from 'antd';
import {
  AppstoreAddOutlined,
  CreditCardOutlined,
  ExperimentOutlined,
} from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

const AdminMenu = ({ location }) => (
  <Menu
    theme="light"
    className="menu-sidebar"
    mode="inline"
    defaultSelectedKeys={['/orders']}
    selectedKeys={[location.pathname]}
  >
    <Menu.Item
      key="/orders"
      icon={<AppstoreAddOutlined />}
      className={
        location.pathname.includes('orders') ? 'ant-menu-item-selected' : ''
      }
    >
      <Link to="/orders">Quản lý đăng ký</Link>
    </Menu.Item>
    <Menu.Item
      key="/admin/exams"
      icon={<ExperimentOutlined />}
      className={
        location.pathname.includes('/admin/exams')
          ? 'ant-menu-item-selected'
          : ''
      }
    >
      <Link to="/admin/exams">Quản lý kỳ thi</Link>
    </Menu.Item>
    <Menu.Item
      key="/partners"
      icon={<CreditCardOutlined />}
      className={
        location.pathname.includes('partners') ? 'ant-menu-item-selected' : ''
      }
    >
      <Link to="/partners">Đối tác</Link>
    </Menu.Item>
  </Menu>
);

export default AdminMenu;
