import {
  EnvironmentOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
} from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/vi';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import config from '../../../app/config';
import { Link } from 'react-router-dom';
import {
  getErrorMessages,
  isVietnamesePhoneNumber,
} from '../../../app/utils/functions';
import {
  createProfile,
  guestCreateProfile,
  getCities,
  getDistricts,
  getSchools,
  getWards,
  viewProfile,
} from '../service';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PROFILE_TYPE from '../ProfileType';
import PATHS from 'themes/paths';
import FormHighSchool from '../Components/HighSchoolForm';

const dateFormatList = ['DD/MM/YYYY'];
const { Option } = Select;

function saveTempProfile(data) {
  // localStorage.setItem('basic_v1', JSON.stringify(data));
  // localStorage.setItem('basic_v1', `${Date.now()}`);
}

function removeTempProfile(data) {
  localStorage.removeItem('basic_v1');
  localStorage.removeItem('basic_v1');
}

function getTempProfile() {
  const strData = localStorage.getItem('basic_v1');

  try {
    const data = JSON.parse(strData);
    data.cmnd_img_before = '';
    data.cmnd_img_after = '';

    return data;
  } catch (error) {
    return {};
  }
}

const SUBJECTS_REQUIRED = ['subject_math'];
const SUBJECTS_OPTIONAL = ['subject_khtn', 'subject_english'];

class BasicForm extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    let profileObj = {};
    if (props.user) {
      if (!this.props.view) {
        profileObj = {
          email: this.props.user.email,
          name: this.props.user.fullname,
          phone: this.props.user.phone,
          birthday: this.props.user.birthday
            ? moment(this.props.user.birthday)
            : null,
        };
      }
    }
    this.state = {
      province: {
        data: [],
        ttcn: [],
      },
      district: {
        ttcn: [],
        dctt: [],
        dcll: [],
        _thpt: [],
      },
      ward: {
        ttcn: [],
        dctt: [],
        dcll: [],
      },
      school: {
        _thpt: [],
      },
      profileObj,
      profileError: {},
      resetForm: false,
      upImg: false,
      parseObj: {},
    };
  }

  componentDidMount() {
    if (this.props.isGuest) {
      this.props.history.push(PATHS.login);
      return;
    }

    this.getCities(1, 'data');
    this.getCities(0, 'ttcn', true);
    if (this.props.profile) {
      this.initProfile(this.props.profile);
    } else if (this.props.id) {
      this.viewProfile(this.props.id);
    } else if (
      this.props.history.location.state &&
      this.props.history.location.state.email
    ) {
      let tempProfile = getTempProfile();
      tempProfile.email = this.props.history.location.state.email;
      tempProfile.reEmail = tempProfile.email;

      this.initProfile(tempProfile);
    } else {
      let tempProfile = getTempProfile();
      tempProfile.reEmail = '';
      this.initProfile({
        ...tempProfile,
        'test_subjects[subject_math]': true,
      });
    }

    if (!this.props.view) {
      window.addEventListener('beforeunload', this.onUnload);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = (e) => {
    if (this.isEmptyProfile(this.state.profileObj)) {
      e.preventDefault();

      e.returnValue = 'Những thay đổi bạn đã thực hiện có thể không được lưu.';
    }
  };

  isEmptyProfile(profile) {
    const notEmptyKey = Object.keys(profile).find(
      (key) => !isEmpty(profile[key]),
    );

    return !!notEmptyKey;
  }

  resetField(field, value = '') {
    this.formRef.current.setFieldsValue({ [field]: value });
  }

  resetFields(fields, value = '') {
    const updateValues = {};
    fields.forEach((field) => {
      this.resetField(field, value);
      updateValues[field] = value;
    });

    const profileObj = {
      ...this.formRef.current.getFieldValue(),
      ...updateValues,
    };

    this.setState({ profileObj });
  }

  repairUpdateDate(dataInput, fields) {
    const data = dataInput;
    fields.forEach((field) => {
      try {
        const value = dataInput[field];

        data[field] = moment(value, 'DD/MM/YYYY').isValid()
          ? moment(value, 'DD/MM/YYYY').format('MM-DD-YYYY')
          : value;
      } catch (error) {
        data[field] = '';
      }
    });

    return data;
  }

  repairInitDate(dataInput, fields) {
    const data = dataInput;
    fields.forEach((field) => {
      try {
        if (dataInput[field]) {
          data[field] = moment(dataInput[field]).isValid()
            ? moment(dataInput[field])
            : '';
        }
      } catch (error) {
        data[field] = '';
      }
    });

    return data;
  }

  initProfile = (initProfile) => {
    let profile = { ...(initProfile || {}) };
    if (profile.obj_type) {
      profile.obj_type = JSON.parse(profile.obj_type);
    }
    if (profile.hkttKV1) {
      profile.hkttKV1 = JSON.parse(profile.hkttKV1);
    }
    if (profile.hkttXaKhoKhan) {
      profile.hkttXaKhoKhan = JSON.parse(profile.hkttXaKhoKhan);
    }
    this.getDistricts(1, profile.province, 'ttcn');
    this.getWards(profile.district, 'ttcn');
    this.getDistricts(1, profile.province_dctt, 'dctt');
    this.getWards(profile.district_dcll, 'dcll');
    this.getWards(profile.district_dctt, 'dctt');
    this.getDistricts(1, profile.province_dcll, 'dcll');
    this.getDistricts('', profile.province_thpt, '_thpt', true);
    this.getSchools(profile.province_thpt, profile.district_thpt, '_thpt');
    const parseObj = {
      birthday: profile.birthday,
      createDateCmnd: profile.createDateCmnd,
    };
    profile = this.repairInitDate(profile, ['birthday', 'createDateCmnd']);
    profile = this.repairInitSubjects(profile);
    this.setState({
      profileObj: profile,
      parseObj,
      imgUrlAfter: profile.cmnd_img_after,
      imgUrlBefore: profile.cmnd_img_before,
    });

    this.formRef.current.setFieldsValue(profile);
  };

  viewProfile = async (id) => {
    const response = await viewProfile(id);
    if (response.data.code == 200) {
      let profile = response.data.data.profiles;
      if (profile.code != 'BASIC') {
        message.error(
          'Địa chỉ truy cập không đúng. Hệ thống đang chuyển hướng',
        );

        return setTimeout(function () {
          window.location = this.getReturnUrl();
        }, 2000);
      }
      if (profile.data) {
        profile = profile.data;
        this.initProfile(profile);
      }
    } else {
      message.error(getErrorMessages(response.data));
      setTimeout(function () {
        window.location = this.getReturnUrl();
      }, 2000);
    }
  };

  getReturnUrl() {
    if (this.props.location && this.props.location.returnUrl) {
      return this.props.location.returnUrl;
    }

    return this.props.returnUrl || PATHS.profile;
  }

  getCities = async (type = '', child, allowOther = false) => {
    const response = await getCities(type);
    if (response.data.code == 200) {
      const cities = response.data.data.cities.map((i) => ({
        value: i.code || i.provinceid,
        label: i.name,
      }));

      this.setState({
        province: {
          ...this.state.province,
          [child]: !allowOther
            ? cities
            : [...cities, { value: '-1', label: 'Khác' }],
        },
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  getDistricts = async (type = '', id, child, allowOther = false) => {
    const response = await getDistricts(type, id);
    if (response.data.code == 200) {
      const districts = response.data.data.districts.map((i) => ({
        value: i.code || i.districtid,
        label: i.name,
      }));

      this.setState({
        district: {
          ...this.state.district,
          [child]: !allowOther
            ? districts
            : [...districts, { value: '-1', label: 'Khác' }],
        },
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  getWards = async (id, child) => {
    const response = await getWards(id);
    if (response.data.code == 200) {
      const wards = response.data.data.wards.map((i) => ({
        value: i.code || i.wardid,
        label: i.name,
      }));

      this.setState({
        ward: { ...this.state.ward, [child]: wards },
      });
    }
  };

  getSchools = async (city, district, type) => {
    const response = await getSchools(city, district);
    if (response.data.code == 200) {
      const schools = response.data.data.schools.map((i) => ({
        value: i.code,
        label: i.name,
      }));

      this.setState({
        school: {
          ...this.state.school,
          [type]: [...schools, { value: '-1', label: 'Khác' }],
        },
      });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  changeVal = (changedValues, allValues) => {
    if (changedValues.province) {
      this.getDistricts(1, changedValues.province, 'ttcn');
    }
    if (changedValues.district) {
      this.getWards(changedValues.district, 'ttcn');
    }
    if (changedValues.province_dctt) {
      this.getDistricts(1, changedValues.province_dctt, 'dctt');
    }
    if (changedValues.district_dcll) {
      this.getWards(changedValues.district_dcll, 'dcll');
    }
    if (changedValues.district_dctt) {
      this.getWards(changedValues.district_dctt, 'dctt');
    }
    if (changedValues.province_dcll) {
      this.getDistricts(1, changedValues.province_dcll, 'dcll');
    }
    if (changedValues.province_thpt) {
      this.getDistricts('', changedValues.province_thpt, '_thpt', true);
    }
    if (changedValues.district_thpt) {
      this.getSchools(
        this.state.profileObj.province_thpt,
        changedValues.district_thpt,
        '_thpt',
      );
    }
    this.setState({
      profileObj: allValues,
    });
    saveTempProfile(allValues);
  };

  onFormProfile = async () => {};

  uploadImage = (options, type) => {
    const imageFile = options.file;
    if (imageFile) {
      if (
        !imageFile.type ||
        !['image/jpeg', 'image/jpg', 'image/png'].includes(
          imageFile.type.toLowerCase(),
        )
      ) {
        message.error('Vui lòng chọn ảnh PNG hoặc JPG');
        this.resetField(`cmnd_img_${type}`);

        return this.setState({
          upImg: false,
        });
      }

      const isLt2M = imageFile.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error('Ảnh không quá 2MB!');
        this.resetField(`cmnd_img_${type}`);

        return this.setState({
          upImg: false,
        });
      }

      let formData;
      formData = new FormData();
      formData.append('file', imageFile);

      axios({
        method: 'post',
        url: `${config.getApiDomain()}/profile/upload-file`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => {
          if (response.data.code != 200) {
            const profileObj =
              type === 'before'
                ? {
                    ...this.state.profileObj,
                    cmnd_img_before: '',
                  }
                : {
                    ...this.state.profileObj,
                    cmnd_img_after: '',
                  };
            this.setState({
              upImg: false,
              resetForm: !this.state.resetForm,
              profileObj,
            });

            return message.error(getErrorMessages(response.data));
          }
          if (type === 'before') {
            this.setState({
              imgUrlBefore: response.data.data.location,
              upImg: false,
            });
          } else {
            this.setState({
              imgUrlAfter: response.data.data.location,
              upImg: false,
            });
          }

          return message.success(response.data.message);
        })
        .catch((error) => {
          const profileObj =
            type === 'before'
              ? {
                  ...this.state.profileObj,
                  cmnd_img_before: '',
                }
              : {
                  ...this.state.profileObj,
                  cmnd_img_after: '',
                };
          this.setState({ upImg: false, profileObj });
          message.error(getErrorMessages(error.response));
        });
    }
  };

  handleChange = (info, type) => {
    if (info.file.status === 'uploading') {
      this.setState({ upImg: type });
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  repairString(dataInput, fields) {
    const data = dataInput;
    fields.forEach((field) => {
      data[field] = typeof data[field] == 'string' ? data[field].trim() : '';
    });

    return data;
  }

  repairSubmitTestSubject = (values) => {
    const data = { ...values };

    data.test_subjects = [];
    SUBJECTS_REQUIRED.forEach((subject) => {
      if (data[`test_subjects[${subject}]`]) {
        data.test_subjects.push(subject);
        delete data[`test_subjects[${subject}]`];
      }
    });

    data.test_subjects_optional = [];
    SUBJECTS_OPTIONAL.forEach((subject) => {
      if (data[`test_subjects[${subject}]`]) {
        data.test_subjects_optional.push(subject);
        delete data[`test_subjects[${subject}]`];
      }
    });

    return data;
  };

  repairInitSubjects(dataInput) {
    const data = { ...dataInput };
    if (Array.isArray(data.test_subjects)) {
      data.test_subjects.forEach((subject) => {
        data[`test_subjects[${subject}]`] = true;
      });
    } else {
      data.test_subjects = [];
    }

    if (Array.isArray(data.test_subjects_optional)) {
      data.test_subjects_optional.forEach((subject) => {
        data[`test_subjects[${subject}]`] = true;
      });
    } else {
      data.test_subjects_optional = [];
    }

    return data;
  }

  onFinish = async (_values) => {
    if (this.state.saved) {
      return;
    }

    saveTempProfile(_values);

    this.setState({
      loading: true,
    });

    // return;

    const data = {
      name: PROFILE_TYPE.basic.name,
      code: PROFILE_TYPE.basic.code,
      data: {
        ...this.state.profileObj,
        email: this.props.user.email,
      },
    };

    data.data = this.repairString(data.data, [
      'name',
      'cmnd',
      'recmnd',
      'address_dctt',
    ]);

    data.data = this.repairUpdateDate(data.data, [
      'birthday',
      'createDateCmnd',
    ]);

    data.data = this.repairSubmitTestSubject(data.data);

    if (this.props.id) {
      data.id = this.props.id;
    }

    const response = await createProfile(data);

    const self = this;

    if (response.data.code == 200) {
      removeTempProfile();
      this.setState(
        {
          loading: false,
          saved: true,
        },
        () => {
          message.success(response.data.message);
          setTimeout(() => {
            let state = {};
            try {
              state = self.props.location.state || {};
              const profile = response.data.data.profile;
              state.list_profile = [profile];
              state.chooseProfile = profile;
            } catch (error) {
              console.log(error);
            }
            self.props.history.push(self.getReturnUrl(), state);
          }, 2000);
        },
      );
    } else {
      this.setState(
        {
          loading: false,
        },
        () => {
          if (
            typeof response.data.errors != 'undefined' &&
            Object.keys(response.data.errors).length > 0
          ) {
            Object.values(response.data.errors).forEach((element) => {
              message.error(element);
            });
          } else {
            message.error(getErrorMessages(response.data));
          }
        },
      );
    }
  };

  onFinishFailed = (_errorInfo) => {
    message.error('Bạn cần nhập đủ thông tin');
  };

  onChangeDatePicker = (_date, dateString, type) => {
    const { parseObj } = this.state;
    parseObj[type] = dateString;
    this.setState({
      parseObj,
    });
  };

  render() {
    const { school, district, province, profileObj } = this.state;

    return (
      <>
        <Form
          layout="vertical"
          name="basic"
          className={`profile-form ${
            this.props.view ? 'profile-form-view' : ''
          }`}
          initialValues={profileObj}
          onValuesChange={this.changeVal}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
          autoComplete="off"
        >
          <div className="legend">Thông tin cá nhân hồ sơ</div>
          <div className="section-vnu">
            <Row>
              <Col xs={12} md={4} className="">
                <Form.Item
                  label={<span>Họ và Tên khai sinh</span>}
                  name="name"
                  normalize={(value) => {
                    if (value.trim() === '') {
                      return '';
                    }

                    return value.toLocaleUpperCase();
                  }}
                  rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}
                >
                  <Input
                    disabled={!!this.props.view}
                    tabIndex={1}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} md={4} className="">
                <Form.Item
                  label={<span>Giới tính</span>}
                  name="gender"
                  rules={[
                    { required: true, message: 'Vui lòng chọn giới tính' },
                  ]}
                >
                  <Select
                    disabled={!!this.props.view}
                    prefix={
                      <EnvironmentOutlined className="site-form-item-icon" />
                    }
                    showSearch
                    placeholder="Chọn"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent="Không tìm thấy kết quả"
                  >
                    <Option value="1">Nam</Option>
                    <Option value="2">Nữ</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={4} className="">
                <Form.Item
                  label="Ngày sinh"
                  name="birthday"
                  help={
                    <>
                      Ngày/Tháng/Năm. Ví dụ: 20/10/
                      {new Date().getFullYear() - 17}
                    </>
                  }
                  rules={[
                    { required: true, message: 'Vui lòng chọn ngày sinh' },
                  ]}
                >
                  <DatePicker
                    disabled={!!this.props.view}
                    defaultPickerValue={
                      profileObj.birthday
                        ? profileObj.birthday
                        : moment().add(-18, 'year')
                    }
                    disabledDate={(current) =>
                      moment().add(-100, 'year') >= current ||
                      moment().add(-5, 'year') <= current
                    }
                    format={dateFormatList}
                    locale={locale}
                    onChange={(date, dateString) =>
                      this.onChangeDatePicker(date, dateString, 'birthday')
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section-vnu">
            <Row className="mt-2">
              <Col xs={12} md={4} className="">
                <Form.Item
                  label={<span>Số điện thoại</span>}
                  name="phone"
                  rules={[
                    { required: true, message: 'Vui lòng nhập số điện thoại' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || isVietnamesePhoneNumber(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          'Số điện thoại không đúng định dạng',
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    disabled={!!this.props.view}
                    type="number"
                    prefix={<MobileOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="legend mt-4">
            Nơi học hiện tại (Hoặc nơi học lớp 12 trước khi tốt nghiệp)
          </div>
          <div className="section-vnu">
            <FormHighSchool
              prefix="_thpt"
              disabled={!!this.props.view}
              profileObj={profileObj}
              provinces={province.ttcn}
              districts={district._thpt}
              schools={school._thpt}
              resetFields={(fields, value) => {
                this.resetFields(fields, value);
              }}
            />
          </div>
          {/* <div className="legend mt-4">Chọn bài thi</div>
          <div className="section-vnu">
            <Row>
              <Col md={4} xs={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn, đây là môn thi bắt buộc',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject(
                            'Vui lòng chọn, đây là môn thi bắt buộc',
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  name="test_subjects[subject_math]"
                  valuePropName="checked"
                >
                  <Checkbox>Toán + Đọc hiểu</Checkbox>
                </Form.Item>
              </Col>
              <Col md={4} xs={12}>
                <Form.Item
                  name="test_subjects[subject_khtn]"
                  valuePropName="checked"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value &&
                          !getFieldValue('test_subjects[subject_english]')
                        ) {
                          return Promise.reject(
                            'Vui lòng chọn ít nhất một bài thi "Tự chọn"',
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Checkbox>Tự chọn 1: Khoa học tự nhiên</Checkbox>
                </Form.Item>
              </Col>
              <Col md={4} xs={12}>
                <Form.Item
                  name="test_subjects[subject_english]"
                  valuePropName="checked"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value &&
                          !getFieldValue('test_subjects[subject_khtn]')
                        ) {
                          return Promise.reject(
                            'Vui lòng chọn ít nhất một bài thi "Tự chọn"',
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Checkbox>Tự chọn 2: Tiếng Anh</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="section-vnu">
            <Row>
              <Col md={12}>
                <Alert
                  className="alert-info-px"
                  message="Ghi chú:"
                  description={
                    <>
                      - Toán (TN, TL) và Đọc hiểu (TN), 120 phút.
                      <br />
                      - Tự chọn 1: Khoa học tự nhiên (Lý, Hóa, Sinh) (TN), 90
                      phút.
                      <br />
                      - Tự chọn 2: Tiếng Anh (TN, TL), 60 phút.
                      <br />- Viết tắt: TN: Trắc nghiệm, TL: Tự luận.
                    </>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </div> */}
          {!this.props.view && (
            <Form.Item className="mt-4">
              <div className="flex flex-end pb-4 step-action-vnu">
                <Link to={this.getReturnUrl()}>
                  <Button type="default" size="large" className="b-r-8">
                    Hủy
                  </Button>
                </Link>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={this.state.loading}
                  className="ml-2 b-r-8"
                >
                  {this.state.loading ? (
                    <div>
                      <img
                        alt=""
                        className="icon-loading"
                        src={config.getLoadingDefault()}
                      />
                      Đang gửi
                    </div>
                  ) : (
                    <span>Lưu lại</span>
                  )}
                </Button>
              </div>
            </Form.Item>
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  isGuest: Object.keys(state.user).length === 0,
});

export default connect(mapStateToProps)(BasicForm);
