import React, { Component } from 'react';
import {
  Button,
  Badge,
  Image,
  Avatar,
  message,
  Card,
  Collapse,
  Tag,
} from 'antd';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import {
  PicCenterOutlined,
  AppstoreOutlined,
  PlusOutlined,
  MinusOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import TestTakerItem from '../Components/TestTakerItem';

import studying from 'themes/default/svg/studying.svg';

import { getErrorMessages } from '../../../app/utils/functions';
import { viewClasses, bookmarkExam } from '../service';
import ClassConfirmDelete from '../Components/ClassConfirmDelete';
import BreadcrumbX from '../../Common/BreadcrumbX';
import QS from '../../../app/utils/query-string';
import svgCongratulation from 'themes/default/svg/congratulation.svg';
import EmptyData from '../../Common/EmptyData';
import PATHS from 'themes/paths';

const { Panel } = Collapse;
class ClassesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      activeSort: '1',
      dataClass: '',
      exam: [],
      total: {},
      modal: false,
      showDueExam: false,
    };
  }

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      const search = this.getSearch();
      search.type = tab;

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: QS.createUrlString(search),
      });

      this.setState({
        activeTab: tab,
        showDueExam: false,
      });
    }
  };

  toggleSort = (sort) => {
    if (this.state.activeSort !== sort) {
      const search = this.getSearch();
      search.sort = sort;

      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: QS.createUrlString(search),
      });

      this.setState({
        activeSort: sort,
      });
    }
  };

  customExpandIcon = (props) => {
    if (props.isActive) {
      return <MinusOutlined />;
    }

    return <PlusOutlined />;
  };

  getSearch() {
    return QS.parse(this.props.location.search)
      ? QS.parse(this.props.location.search)
      : {};
  }

  componentDidMount() {
    if (!this.props.user || !this.props.match.params.id) {
      message.error('Bạn không thể truy cập');
    } else {
      this.getDataClasses(this.props.match.params.id);
    }

    const search = this.getSearch();

    if (
      search.type &&
      (search.type == 4 ||
        search.type == 3 ||
        search.type == 2 ||
        search.type == 1)
    ) {
      this.setState({
        activeTab: search.type == '4' ? '1' : search.type,
        showDueExam: search.type == '4',
      });
    }

    if (search.sort && (search.sort == '1' || search.sort == '2')) {
      this.setState({
        activeSort: search.sort,
      });
    }
  }

  getDataClasses = async (id) => {
    const self = this;
    const response = await viewClasses(id);
    if (response.data.code == 200) {
      self.setState({
        exam: response.data.data.exam,
        dataClass: response.data.data.class,
        total: response.data.data.total,
      });
    } else {
      message.error(getErrorMessages(response.data));
      setTimeout(() => {
        window.location = PATHS.classes;
      }, 2000);
    }
  };

  bookmarkItem = async (id) => {
    const self = this;
    const response = await bookmarkExam(id);
    if (response.data.code == 200) {
      self.getDataClasses(this.props.match.params.id);
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  deleteConnectiveClass = () => {
    this.setState({
      modal: true,
    });
  };

  resfeshData = () => {
    window.location = PATHS.classes;
  };

  showDueExam = (activeTab) => {
    const search = this.getSearch();
    search.type = activeTab;

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: QS.createUrlString(search),
    });

    this.setState({
      showDueExam: activeTab == '4',
      activeTab: activeTab == '4' ? '1' : this.state.activeTab,
    });
  };

  render() {
    const { activeTab, activeSort, dataClass, exam, total, showDueExam } =
      this.state;
    let lstControl0;
    let lstControl1 = [];
    if (exam && exam.todo && Object.entries(exam.todo).length > 0) {
      lstControl0 = exam.todo.filter((item) => item.control_mode == 0);
      lstControl1 = exam.todo.filter((item) => item.control_mode == 1);
      lstControl0.sort((a, b) => new Date(a.to_time) - new Date(b.to_time));
    }
    if (exam && exam.cancel && Object.entries(exam.cancel).length > 0) {
      exam.cancel.sort((a, b) => new Date(b.to_time) - new Date(a.to_time));
    }

    return (
      <Container>
        <Row>
          <Col>
            <BreadcrumbX
              location={this.props.location}
              extra={[{ path: '', name: dataClass.custom_name }]}
            />
          </Col>
        </Row>
        {dataClass && (
          <Row>
            <Col xs={12}>
              <div className="social__header">
                <div className="social__title">
                  <div className="title-classes">
                    <div className="">{dataClass.custom_name} </div>
                    {dataClass &&
                      dataClass.grade &&
                      dataClass.grade.map((item) => (
                        <Tag className="bg-primary" key={item}>
                          {item}
                        </Tag>
                      ))}
                    {dataClass &&
                      dataClass.subject &&
                      dataClass.subject.map((item) => (
                        <Tag className="bg-success" key={item}>
                          {item}
                        </Tag>
                      ))}
                  </div>
                  <div className="action-group-right">
                    <Button
                      type="primary"
                      onClick={() => this.deleteConnectiveClass()}
                      danger
                      icon={<ExportOutlined />}
                    >
                      {dataClass.status == 1 ? 'Rời khỏi lớp' : 'Hủy đăng ký'}
                    </Button>
                    <ClassConfirmDelete
                      closeModal={this.closeModal}
                      modal={this.state.modal}
                      class={dataClass}
                      resfeshData={this.resfeshData}
                    />
                  </div>
                </div>
                <hr className="line mt-3" />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={12}
              xl={3}
              className="class-detail-left mb-2"
            >
              <Row>
                <Col xs={12} md={6} lg={6} xl={12}>
                  <Card className="x-card mb-4" title="Bài thi cần làm">
                    {Object.entries(total).length > 0 ? (
                      <div className="flex space-around">
                        <div className="group-label-exam">
                          <Button
                            className="btn-info mb-2"
                            shape="circle"
                            onClick={() => this.showDueExam('1')}
                          >
                            {total.todo + parseInt(total.must)}
                          </Button>
                          <span>Bài chưa làm</span>
                        </div>
                        <div className="group-label-exam">
                          <Button
                            className="danger mb-2"
                            type="primary"
                            danger
                            shape="circle"
                            onClick={() => this.showDueExam('4')}
                          >
                            {total.must}
                          </Button>
                          <span>Cần làm</span>
                        </div>
                      </div>
                    ) : (
                      <span>Bạn chưa liên kết với lớp học này</span>
                    )}
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={6} xl={12}>
                  <Card className="x-card card-gv" title="Giáo viên phụ trách">
                    {dataClass.main_teacher_name ? (
                      <Collapse
                        defaultActiveKey={['1']}
                        expandIconPosition="right"
                        expandIcon={(props) => this.customExpandIcon(props)}
                      >
                        <Panel
                          header={
                            <div>
                              <Avatar size="large" icon={<UserOutlined />} />{' '}
                              {dataClass.main_teacher_name}
                            </div>
                          }
                          key="1"
                        >
                          <p className="info-gv align-center flex">
                            <PhoneOutlined className="mr-2" />{' '}
                            {dataClass.main_teacher_phone
                              ? dataClass.main_teacher_phone
                              : 'Đang cập nhật'}
                          </p>
                          <p className="info-gv align-center flex">
                            <MailOutlined className="mr-2" />{' '}
                            {dataClass.main_teacher_email
                              ? dataClass.main_teacher_email
                              : 'Đang cập nhật'}
                          </p>
                        </Panel>
                      </Collapse>
                    ) : (
                      <p>Đang cập nhật</p>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={12} lg={12} xl={9}>
              <Card
                className="x-card card-class-detail"
                title={
                  <div className="social__header">
                    <div className="social__title">
                      <span className="title-classes title-classes-light">
                        Bài thi của bạn
                      </span>
                      {Object.entries(total).length > 0 && (
                        <div className="action-group-right">
                          <div className="site-button-ghost-wrapper mr-3">
                            <Button
                              className={`${
                                activeTab === '1' ? 'active' : ''
                              } mr-2`}
                              onClick={() => {
                                this.toggle('1');
                              }}
                            >
                              Chưa thi{' '}
                              <Badge
                                count={
                                  total && total.todo >= 0 && total.must >= 0
                                    ? parseInt(total.must) +
                                      parseInt(total.todo)
                                    : 0
                                }
                                className="site-badge-count"
                              />
                            </Button>
                            <Button
                              className={`${activeTab === '2' ? 'active' : ''}`}
                              onClick={() => {
                                this.toggle('2');
                              }}
                            >
                              Đã thi{' '}
                              <Badge
                                count={total ? total.done : 0}
                                className="site-badge-count"
                              />
                            </Button>
                            <Button
                              className={`${activeTab === '3' ? 'active' : ''}`}
                              onClick={() => {
                                this.toggle('3');
                              }}
                            >
                              Quá hạn{' '}
                              <Badge
                                count={total ? total.cancel : 0}
                                className="site-badge-count"
                              />
                            </Button>
                            {/* {showDueExam &&<Button className={`${activeTab === '4' ? 'active' : ''}`} onClick={() => { this.toggle('4'); }}>
                        Cần làm hôm nay <Badge count={total ? total.must : 0} className="site-badge-count" />
                      </Button>} */}
                          </div>
                          <div className="site-button-ghost-wrapper mr-3 sort-box">
                            <Button
                              className={`${
                                activeSort === '1' ? 'active' : ''
                              } mr-2`}
                              onClick={() => {
                                this.toggleSort('1');
                              }}
                            >
                              <PicCenterOutlined />
                            </Button>
                            <Button
                              className={`${
                                activeSort === '2' ? 'active' : ''
                              }`}
                              onClick={() => {
                                this.toggleSort('2');
                              }}
                            >
                              <AppstoreOutlined />
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                }
              >
                {Object.entries(exam).length > 0 ? (
                  <TabContent className="tab-cus" activeTab={activeTab}>
                    <TabPane tabId="1">
                      {!showDueExam &&
                        exam &&
                        exam.doing &&
                        Object.entries(exam.doing).length > 0 && (
                          <Row>
                            {exam.doing.map((item) => (
                              <Col
                                xs="12"
                                md={activeSort == 2 ? '6' : '12'}
                                lg={activeSort == 2 ? '4' : '12'}
                                className="mb-3"
                                key={item.id}
                              >
                                <TestTakerItem
                                  item={item}
                                  display={activeSort}
                                  type={1}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                      {exam &&
                        exam.must &&
                        Object.entries(exam.must).length > 0 && (
                          <Row>
                            {exam.must.map((item) => (
                              <Col
                                xs="12"
                                md={activeSort == 2 ? '6' : '12'}
                                lg={activeSort == 2 ? '4' : '12'}
                                className="mb-3"
                                key={item.id}
                              >
                                <TestTakerItem
                                  expiredToday
                                  item={item}
                                  display={activeSort}
                                  type={1}
                                  bookmarkItem={this.bookmarkItem}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                      {!showDueExam &&
                        exam &&
                        exam.todo &&
                        Object.entries(exam.todo).length > 0 && (
                          <>
                            <Row>
                              {lstControl1.map((item) => (
                                <Col
                                  xs="12"
                                  md={activeSort == 2 ? '6' : '12'}
                                  lg={activeSort == 2 ? '4' : '12'}
                                  className="mb-3"
                                  key={item.id}
                                >
                                  <TestTakerItem
                                    item={item}
                                    display={activeSort}
                                    type={1}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <Row>
                              {lstControl0.map((item) => (
                                <Col
                                  xs="12"
                                  md={activeSort == 2 ? '6' : '12'}
                                  lg={activeSort == 2 ? '4' : '12'}
                                  className="mb-3"
                                  key={item.id}
                                >
                                  <TestTakerItem
                                    item={item}
                                    display={activeSort}
                                    type={1}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}

                      {!showDueExam &&
                      exam &&
                      exam.cancel &&
                      Object.entries(exam.cancel).length == 0 &&
                      total &&
                      total.done > 0 &&
                      exam.must &&
                      exam.todo >= 0 &&
                      exam.doing >= 0 &&
                      Object.entries(exam.todo).length +
                        Object.entries(exam.must).length +
                        Object.entries(exam.doing).length ==
                        0 ? (
                        <EmptyData
                          img={svgCongratulation}
                          text={
                            <>
                              <h3>
                                Chúc mừng,{' '}
                                {this.props.user.fullname
                                  ? this.props.user.fullname
                                  : this.props.user.username}
                              </h3>
                              <p>
                                Bạn đã hoàn thành mọi bài thi, hãy giữ vững
                                phong độ nhé
                              </p>
                            </>
                          }
                        />
                      ) : (
                        <>
                          {((!showDueExam &&
                            exam &&
                            Object.entries(exam.must).length == 0 &&
                            Object.entries(exam.todo).length == 0 &&
                            Object.entries(exam.doing).length == 0) ||
                            (showDueExam &&
                              exam &&
                              Object.entries(exam.must).length == 0)) && (
                            <EmptyData
                              img={studying}
                              text={<p>Không có bài thi nào</p>}
                            />
                          )}
                        </>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      {exam &&
                        exam.done &&
                        Object.entries(exam.done).length > 0 && (
                          <Row>
                            {exam.done.map((item) => (
                              <Col
                                xs="12"
                                md={activeSort == 2 ? '4' : '12'}
                                className="mb-3"
                                key={item.id}
                              >
                                <TestTakerItem
                                  item={item}
                                  display={activeSort}
                                  type={2}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                      {exam &&
                        exam.done &&
                        Object.entries(exam.done).length == 0 && (
                          <div className="p-1 text-auto text-center">
                            <Image src={studying} preview={false} />
                            <p> Không có bài thi nào</p>
                          </div>
                        )}
                    </TabPane>
                    <TabPane tabId="3">
                      {exam &&
                        exam.cancel &&
                        Object.entries(exam.cancel).length > 0 && (
                          <Row>
                            {exam.cancel.map((item) => (
                              <Col
                                xs="12"
                                md={activeSort == 2 ? '6' : '12'}
                                lg={activeSort == 2 ? '4' : '12'}
                                className="mb-3"
                                key={item.id}
                              >
                                <TestTakerItem
                                  item={item}
                                  display={activeSort}
                                  type={3}
                                  bookmarkItem={this.bookmarkItem}
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                      {exam &&
                        exam.cancel &&
                        Object.entries(exam.cancel).length == 0 && (
                          <div className="p-1 text-auto text-center">
                            <Image src={studying} preview={false} />
                            <p> Không có bài thi nào</p>
                          </div>
                        )}
                    </TabPane>
                    {/* <TabPane tabId="4">

                  {exam && exam['must'] && Object.entries(exam['must']).length==0 &&<div className="p-1 text-auto text-center">
                  <Image
                    src={studying}
                    preview={false}
                  />
                  <p><i> Không có bài thi nào</i></p>
                </div>}
              </TabPane> */}
                  </TabContent>
                ) : (
                  <span>Bạn chưa liên kết với lớp học này</span>
                )}
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ClassesDetail);
