import React, { Component } from 'react';
import { Image } from 'antd';

class EmptyData extends Component {
  render() {
    return (
      <div className="bravo-box">
        <div className="p-1 text-auto text-center">
          <Image src={this.props.img} preview={false} />
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default EmptyData;
