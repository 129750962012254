import React, { Component } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { Media, Input } from 'reactstrap';
import { getErrorMessages } from '../../../app/utils/functions';
import config from '../../../app/config';
import Auth from '../../../app/auth';

class UploadExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      selectedFile: null,
      invalidImage: null,
    };
    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const imageFile = e.target.files[0];
    const self = this;
    if (imageFile) {
      if (
        !['jpg', 'jpeg', 'png', 'gif'].includes(
          (imageFile.name.split('.').pop() || '').toLocaleLowerCase(),
        )
      ) {
        message.error('File không đúng định dạng');
        e.target.value = null;

        return;
      }
      this.setState(
        {
          avatar: config.getLoadingDefault(),
        },
        () => {
          let formData;
          formData = new FormData();
          formData.append('file', imageFile);
          formData.append('id', this.props.user.id);
          axios({
            method: 'post',
            url: `${config.getApiDomain()}/user/upload-avatar`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then((response) => {
              if (response.data.code != 200) {
                return message.error(getErrorMessages(response.data));
              }
              Auth.init();
              self.setState({ avatar: response.data.data.location });

              return message.success(response.data.message);
            })
            .catch((error) => {
              self.setState({ avatar: '' });
              console.log('Error:', error.response);
              message.error(getErrorMessages(error.response));
            });
        },
      );
    }
  }

  removeFile = () => {
    this.setState({
      avatar: config.getAvatarDefault(),
    });
    let formData;
    formData = new FormData();
    formData.append('id', this.props.user.id);
    axios({
      method: 'post',
      url: `${config.getApiDomain()}/user/remove-avatar`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((response) => {
        if (response.data.code != 200) {
          return message.error(getErrorMessages(response.data));
        }
        Auth.init();
      })
      .catch((error) => {
        message.error(getErrorMessages(error.response));
      });
  };

  render() {
    return (
      <>
        <div className="text-center">
          <Media
            key={Math.random()}
            className="img-fluid rounded-circle shadow-sm avatar-img"
            object
            src={
              this.state.avatar
                ? this.state.avatar
                : this.props.user.avatar
                ? this.props.user.avatar
                : config.getAvatarDefault()
            }
          />
          <div className="box-btn-avatar">
            <button
              type="button"
              className="btn-avatar"
              onClick={() => {
                document.getElementById('excelFileInput').click();
              }}
            >
              <i className="fa fa-camera primary" aria-hidden="true" />
            </button>
            {/* <button type="button" className="btn btn-danger btn-sm" onClick={this.removeFile}><i className="fa fa-trash-o" aria-hidden="true"></i></button> */}
          </div>
        </div>
        <Input
          key={Math.random()}
          id="excelFileInput"
          style={{ display: 'none' }}
          type="file"
          onChange={this.handleChange}
          inputprops={{ accept: 'image/*' }}
        />
      </>
    );
  }
}

export default UploadExcel;
