const countries = [
  { value: '1', label: 'Việt Nam' },
  { value: '2', label: 'Ac-hen-ti-na' },
  { value: '3', label: 'Adecbaigian' },
  { value: '4', label: 'Ai cập' },
  { value: '5', label: 'Ai-len' },
  { value: '6', label: 'Ai-xlen' },
  { value: '7', label: 'An ba ni' },
  { value: '8', label: 'An giê ri' },
  { value: '9', label: 'A-rơ-len' },
  { value: '10', label: 'Ar-mê-nia' },
  { value: '11', label: 'Ăng-gô-la' },
  { value: '12', label: 'Áo' },
  { value: '13', label: 'Áp-ga-ni-xtan' },
  { value: '14', label: 'Ả rập Xê út' },
  { value: '15', label: 'Ấn Độ' },
  { value: '16', label: 'Băng-la-đét' },
  { value: '17', label: 'Bô-li-via' },
  { value: '18', label: 'Bôxna He-rơ-xe-gô-vin-na' },
  { value: '19', label: 'Bồ Đào Nha' },
  { value: '20', label: 'Ba Lan' },
  { value: '21', label: 'Be-lo-rut-xia' },
  { value: '22', label: 'Bốt-xoa-na' },
  { value: '23', label: 'Bỉ' },
  { value: '24', label: 'Braxin' },
  { value: '25', label: 'Brunây' },
  { value: '26', label: 'BuTan' },
  { value: '27', label: 'Buốc-ki-na-pha-xô' },
  { value: '28', label: 'Bun-ga-ri' },
  { value: '29', label: 'Căm-Pu-Chia' },
  { value: '30', label: 'Cô-lôm-bi-a' },
  { value: '31', label: 'Côngô' },
  { value: '32', label: 'Cô-oét' },
  { value: '33', label: 'Côxta Rica' },
  { value: '34', label: 'Cadacxtan' },
  { value: '35', label: 'Ca-mơ-run' },
  { value: '36', label: 'Ca-na-đa' },
  { value: '37', label: 'Cộng hòa Ki-rơ-ghi-dơ' },
  { value: '38', label: 'Cộng hòa Séc' },
  { value: '39', label: 'CHDCND Lào' },
  { value: '40', label: 'CHDCND Triều tiên' },
  { value: '41', label: 'Chi lê' },
  { value: '42', label: 'CHND Trung hoa' },
  { value: '43', label: 'Croát-chi-a' },
  { value: '44', label: 'Cuba' },
  { value: '45', label: 'Dăm-bia' },
  { value: '46', label: 'Dimbabuê' },
  { value: '47', label: 'Đài loan' },
  { value: '48', label: 'Đaia' },
  { value: '49', label: 'Đan Mạch' },
  { value: '50', label: 'Đức' },
  { value: '51', label: 'Et-tô-ni' },
  { value: '52', label: 'Extônia' },
  { value: '53', label: 'Ê-cu-a-đo' },
  { value: '54', label: 'Ê-ti-ô-pi-a' },
  { value: '55', label: 'Gam-bi-a' },
  { value: '56', label: 'Ghi nê' },
  { value: '57', label: 'Gru-dia' },
  { value: '58', label: 'Hà lan' },
  { value: '59', label: 'Hàn Quốc' },
  { value: '60', label: 'Hồng Công' },
  { value: '61', label: 'Hung-ga-ri' },
  { value: '62', label: 'Hy Lạp' },
  { value: '63', label: 'In-đô-nê-xi-a' },
  { value: '64', label: 'I-rắc' },
  { value: '65', label: 'I-ran' },
  { value: '66', label: 'I-ta-li' },
  { value: '67', label: 'I-xra-en' },
  { value: '68', label: 'Ka-dắc-xtan' },
  { value: '69', label: 'Kê-nia' },
  { value: '70', label: 'Kirgixtan' },
  { value: '71', label: 'Ki-ri-ba-ti' },
  { value: '72', label: 'Latvia' },
  { value: '73', label: 'Liên bang Nga' },
  { value: '74', label: 'Liên xô cũ' },
  { value: '75', label: 'Libăng' },
  { value: '76', label: 'Li-bê-ri-a' },
  { value: '77', label: 'Libi' },
  { value: '78', label: 'Lit-va' },
  { value: '79', label: 'Luc-xem-bua' },
  { value: '80', label: 'Mô-dăm-bích' },
  { value: '81', label: 'Môn-đô-va' },
  { value: '82', label: 'Môn-đa-vi' },
  { value: '83', label: 'Mô-na-cô' },
  { value: '84', label: 'Mông cổ' },
  { value: '85', label: 'Mê hi cô' },
  { value: '86', label: 'Ma-đa-gát-xka' },
  { value: '87', label: 'Ma-lai-xi-a' },
  { value: '88', label: 'Ma-li' },
  { value: '89', label: 'Man-đi-vơ' },
  { value: '90', label: 'Mau-ri-ti-út' },
  { value: '91', label: 'Me-xi-cô' },
  { value: '92', label: 'Mi-cờ-rô-nê-xi-a' },
  { value: '93', label: 'Mỹ' },
  { value: '94', label: 'My-an-ma' },
  { value: '95', label: 'Na Uy' },
  { value: '96', label: 'Nam phi' },
  { value: '97', label: 'Nam tư' },
  { value: '98', label: 'Nam-mi-bia' },
  { value: '99', label: 'Nê-Pan' },
  { value: '100', label: 'Nhật bản' },
  { value: '101', label: 'Ni-ca-ra-goa' },
  { value: '102', label: 'Nigiê' },
  { value: '103', label: 'Ni-giê-ri-a' },
  { value: '104', label: 'Niu Di Lân' },
  { value: '105', label: 'Ôman' },
  { value: '106', label: 'Ôxtơrâylia' },
  { value: '107', label: 'Pa-Ki-xtan' },
  { value: '108', label: 'Pa-na-ma' },
  { value: '109', label: 'Pa-pua Niu Ghi - nê' },
  { value: '110', label: 'Pa-ra-goay' },
  { value: '111', label: 'Pê-ru' },
  { value: '112', label: 'Pháp' },
  { value: '113', label: 'Phần Lan' },
  { value: '114', label: 'Phi-Ghi' },
  { value: '115', label: 'Phi-li-pin' },
  { value: '116', label: 'Qua-ta/Qatar' },
  { value: '117', label: 'Quần đảo Cúc' },
  { value: '118', label: 'Quần đảo Ma-rơ-san' },
  { value: '119', label: 'Quần đảo Sô-lô-môn' },
  { value: '120', label: 'Ruanđa' },
  { value: '121', label: 'Ru-ma-ni' },
  { value: '122', label: 'Sô-ma-li' },
  { value: '123', label: 'Sat' },
  { value: '124', label: 'Si-ri-a' },
  { value: '125', label: 'Slô-vac-ki-a' },
  { value: '126', label: 'Slô-ven-ni-a' },
  { value: '127', label: 'Sri-lan-ka' },
  { value: '128', label: 'Tôn-ga' },
  { value: '129', label: 'Tây Ban Nha' },
  { value: '130', label: 'Tây Sa-moa' },
  { value: '131', label: 'Tandania' },
  { value: '132', label: 'Tatgikistan' },
  { value: '133', label: 'Thái lan' },
  { value: '134', label: 'Thụy Điển' },
  { value: '135', label: 'Thụy sỹ' },
  { value: '136', label: 'Tiệp khắc' },
  { value: '137', label: 'Turkmênistan' },
  { value: '138', label: 'Tu-va-lu' },
  { value: '139', label: 'Tuynidi' },
  { value: '140', label: 'U-dơ-bê-ki-xtan' },
  { value: '141', label: 'U-krai-na' },
  { value: '142', label: 'U-ru-goay' },
  { value: '143', label: 'Vương quốc Anh' },
  { value: '144', label: 'Vê-nê-xu-ê-la' },
  { value: '145', label: 'Va-nu-a-tu' },
  { value: '146', label: 'Xômali' },
  { value: '147', label: 'Xênêgan' },
  { value: '148', label: 'Xin-ga-po' },
  { value: '149', label: 'Xri Lan-ca' },
  { value: '150', label: 'Xuđăng' },
  { value: '151', label: 'Y-ê-men' },
  { value: '152', label: 'Úc' },
];

export default countries;
