import axios from 'axios';
import QS from '../../app/utils/query-string';
import config from '../../app/config';

export async function login(username, password) {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    data: QS.stringify({
      grant_type: 'password',
      scope:
        process.env.REACT_APP_KEYCLOAK_SCOPES != null
          ? process.env.REACT_APP_KEYCLOAK_SCOPES.split(' ')
          : '',
      username,
      password,
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    }),
  })
    .then((response) => response)
    .catch((error) => error);
}

export async function getTokenByCode(code, redirect_uri) {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    data: QS.stringify({
      grant_type: 'authorization_code',
      redirect_uri,
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      code,
    }),
  })
    .then((response) => response)
    .catch((error) => error);
}

export async function logout() {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
    headers: {
      common: false,
    },
    data: QS.stringify({
      refresh_token: localStorage.getItem('authen_refresh_token'),
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    }),
  });
}

export function saveUser(userId, data) {
  let apiUrl = `${config.getApiDomain()}/user/save`;
  const method = 'post';
  if (userId > 0) {
    apiUrl = `${config.getApiDomain()}/user/save?id=${userId}`;
  }

  return axios({
    method,
    url: apiUrl,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function changePassUser(userId, data) {
  const method = 'post';
  const apiUrl = `${config.getApiDomain()}/user/change-pass?id=${userId}`;

  return axios({
    method,
    url: apiUrl,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function register(data) {
  const apiUrl = `${config.getApiDomain()}/user/create`;
  const method = 'post';

  return axios({
    method,
    url: apiUrl,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function registerOther(data) {
  const apiUrl = `${config.getApiDomain()}/user/create-other`;
  const method = 'post';

  return axios({
    method,
    url: apiUrl,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function getUser(userId) {
  return axios
    .get(`${config.getApiDomain()}/user/view?id=${userId}`)
    .then((response) => response);
}

export function resetPasswordWithEmail(email) {
  const apiUrl = `${config.getApiDomain()}/user/reset-password-with-email?email=${email}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function searchUserByCode(code) {
  const apiUrl = `${config.getApiDomain()}/user/search-code?code=${
    code ? code.toUpperCase() : ''
  }`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function importImage(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/upload-avatar`,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function requestUser(id, type) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/request-user`,
    data: QS.stringify({ id, type }),
  }).then((response) => response);
}

export function listRequestUser(id) {
  const apiUrl = `${config.getApiDomain()}/user/list-request-user?id=${id}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function viewProfile(idTo) {
  const apiUrl = `${config.getApiDomain()}/user/view-profile?idTo=${idTo}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function viewClasses(id) {
  const apiUrl = `${config.getApiDomain()}/user/detail-class?id=${id}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function connectiveClass(code) {
  const apiUrl = `${config.getApiDomain()}/user/connective-class?code=${code}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function listConnectiveClass(id = '') {
  let apiUrl = `${config.getApiDomain()}/user/list-connective-class`;
  if (id) {
    apiUrl += `?id=${id}`;
  }
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function getDataListExam(id = '') {
  let apiUrl = `${config.getApiDomain()}/user/list-exam`;
  if (id) {
    apiUrl += `?id=${id}`;
  }
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function getListActiveExam(id = '') {
  let apiUrl = `${config.getApiDomain()}/user/list-active-exam`;
  if (id) {
    apiUrl += `?id=${id}`;
  }
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function getListDoneExam(id = '') {
  let apiUrl = `${config.getApiDomain()}/user/list-done-exam`;
  if (id) {
    apiUrl += `?id=${id}`;
  }
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function getListExpireExam(id = '') {
  let apiUrl = `${config.getApiDomain()}/user/list-expire-exam`;
  if (id) {
    apiUrl += `?id=${id}`;
  }
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function getDetailExam(id) {
  const apiUrl = `${config.getApiDomain()}/user/detail-exam?id=${id}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function deleteConnective(id) {
  return axios({
    method: 'DELETE',
    url: `${config.getApiDomain()}/user/delete-connective?id=${id}`,
  }).then((response) => response);
}
export function delConnectiveClass(code) {
  return axios({
    method: 'DELETE',
    url: `${config.getApiDomain()}/user/del-connective-class?code=${code}`,
  }).then((response) => response);
}

export function addConnective(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/add-connective`,
    data: QS.stringify({ id }),
  }).then((response) => response);
}

export function editCode(code) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/edit-code`,
    data: QS.stringify({ code }),
  }).then((response) => response);
}

export function changeNameClass(id, custom_name, reset) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/change-name-class`,
    data: QS.stringify({ id, custom_name, reset }),
  }).then((response) => response);
}

export function addConnectiveClass(code, message, studentCode) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/add-connective-class`,
    data: QS.stringify({ code, message, student_code: studentCode }),
  }).then((response) => response);
}

export function bookmarkExam(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/bookmark-exam`,
    data: QS.stringify({ id }),
  }).then((response) => response);
}

export function getProfiles(params = {}) {
  const apiUrl = `${config.getApiDomain()}/profile`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
    params: params,
  }).then((response) => response);
}
export function sendVerifyEmail() {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/send-verify-email`,
  });
}
