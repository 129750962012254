import React, { Component } from 'react';
import {
  Button,
  Badge,
  Input,
  Drawer,
  Avatar,
  message,
  Dropdown,
  Menu,
} from 'antd';
import { Row, Col, TabContent, TabPane, Container } from 'reactstrap';
import {
  ControlOutlined,
  MailOutlined,
  BarcodeOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import ic_class from 'themes/default/svg-same-color/icon-class.svg';
import studying from 'themes/default/svg/studying.svg';
import config from '../../../app/config';
import { getErrorMessages } from '../../../app/utils/functions';
import {
  connectiveClass,
  addConnectiveClass,
  listConnectiveClass,
} from '../service';
import ClassRequest from '../Components/ClassRequest';
import XCard from '../../../lib/xcbtControls/Card';
import RequestFormClass from '../Components/ClassFormRequest';
import ClassConfirmDelete from '../Components/ClassConfirmDelete';
import EmptyData from '../../Common/EmptyData';

class MessageRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      studentCode: '',
    };
  }

  onFormChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
    }
  };

  onSubmitRequest = async () => {
    if (this.props.onSubmitRequest) {
      this.props.onSubmitRequest(
        this.state.message.trim(),
        this.state.studentCode.trim().toUpperCase(),
      );
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="text-center">
        <Avatar
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 80,
            xxl: 80,
          }}
          src={config.getAvatarDefault()}
        />
        <p className="mt-2">
          Bạn muốn tham gia lớp học{' '}
          <span className="secondary">{classes.name}</span>{' '}
          {classes.main_teacher_name
            ? `của giáo viên ${classes.main_teacher_name}`
            : ''}
        </p>
        <Input
          value={this.state.message}
          onChange={(e) => this.onFormChange('message', e.target.value)}
          size="large"
          placeholder="Nội dung ..."
          className="b-r-8 mb-2"
          prefix={<MailOutlined className="color-light" />}
        />
        <Input
          value={this.state.studentCode}
          onChange={(e) => this.onFormChange('studentCode', e.target.value)}
          size="large"
          placeholder="Mã học sinh ..."
          className={`b-r-8 mb-2 ${this.state.studentCode ? 'uppercase' : ''}`}
          prefix={<BarcodeOutlined className="color-light" />}
        />
        <div className="btn-gr-join flex flex-content-evenly">
          <Button className="primary" onClick={this.onBack}>
            Nhập lại mã lớp
          </Button>
          <Button type="primary" onClick={this.onSubmitRequest}>
            Đăng ký học
          </Button>
        </div>
      </div>
    );
  }
}
class Classes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      visibleDrawer: false,
      visible: 1,
      collapse: false,
      display: 1,
      code: '',
      classes: '',
      classesSelected: '',
      onSearch: false,
      lstConnected: [],
      lstRequest: [],
      lstInvited: [],
      popoverOpen: false,
      message: '',
      lstClass: [],
      loading: true,
      modal: false,
      filter: '',
    };
  }

  deleteConfirm = (classesSelected) => {
    if (classesSelected) {
      this.setState({
        classesSelected,
        modal: true,
      });
    } else {
      return message.error('Không tìm thấy lớp học');
    }
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  openRequest = () => {
    this.setState({ visibleDrawer: true });
  };

  getListConnectiveClass = async () => {
    const filter = JSON.parse(localStorage.getItem('filter')) || {};
    this.setState({ loading: true });
    const response = await listConnectiveClass();
    if (response.data.code == 200) {
      this.setState(
        {
          filter:
            filter && filter.class && filter.class.active
              ? filter.class.active
              : this.state.filter,
          lstRequest: response.data.data.request,
          lstConnected: response.data.data.active,
          lstClass: response.data.data,
          loading: false,
        },
        () => {
          const param =
            filter && filter.class && filter.class.active
              ? filter.class.active
              : '';
          this.filter(param);
        },
      );

      return {
        isSuccess: true,
        data: response.data.data,
      };
    } else {
      message.error(getErrorMessages(response.data));

      return {
        isSuccess: false,
      };
    }
  };

  showDrawer = async (visible, code) => {
    if (!code) {
      return message.error('Bạn chưa nhập mã lớp học');
    }
    const response = await connectiveClass(code);
    if (response.data.code == 200) {
      this.setState({
        classes: response.data.data,
        visible,
        visibleDrawer: true,
        message: '',
      });
    } else {
      return message.error(getErrorMessages(response.data));
    }
  };

  onSubmitRequest = async (mess, studentCode) => {
    let { classes } = this.state;
    if (!classes) {
      return message.error('Không tìm thấy lớp học');
    }
    let response = await addConnectiveClass(classes.code, mess, studentCode);
    if (response.data.code == 200) {
      this.onClose();
      setTimeout(async () => {
        const result = await this.getListConnectiveClass();
        if (
          result.isSuccess &&
          Array.isArray(result.data.active) &&
          result.data.active.find((aClass) => aClass.code === classes.code)
        ) {
          message.success('Đăng ký lớp học thành công.');
          this.setState({ activeTab: '1' });
        } else {
          if (result.isSuccess && Array.isArray(result.data.request)) {
            const classRequest = result.data.request.find(
              (aClass) => aClass.code === classes.code,
            );
            if (classRequest && classRequest.msg) {
              message.warn(classRequest.msg, 8);
              this.setState({ activeTab: '2' });

              return;
            }
          }

          message.success('Yêu cầu đăng ký học đã được gửi đi.');
          this.setState({ activeTab: '2' });
        }
      }, 800);
    } else {
      return message.error(getErrorMessages(response.data));
    }
  };

  componentDidMount() {
    if (this.props.user.id) {
      this.getListConnectiveClass();
    }
    if (this.props.location && this.props.location.pathname) {
      localStorage.setItem('home_url', this.props.location.pathname);
    }
  }

  toggle = (tab) => {
    const filter = JSON.parse(localStorage.getItem('filter')) || {};
    let filterDefault = '';
    if (tab == 1) {
      filterDefault =
        filter && filter.class && filter.class.active
          ? filter.class.active
          : filterDefault;
    } else {
      filterDefault =
        filter && filter.class && filter.class.request
          ? filter.class.request
          : filterDefault;
    }

    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          filter: filterDefault,
        },
        () => {
          this.filter(filterDefault);
        },
      );
    }
  };

  onClose = () => {
    this.setState({
      visibleDrawer: false,
      visible: 1,
      classes: '',
    });
  };

  onBack = () => {
    this.setState({
      visible: 1,
    });
  };

  filter = (param) => {
    let filter = JSON.parse(localStorage.getItem('filter')) || {};
    // if(!param){
    //   filter = {
    //     ...filter,
    //     class : {}
    //   }
    //   localStorage.setItem('filter',JSON.stringify(filter));
    //   return this.setState({lstRequest: this.state.lstClass['request'],lstConnected: this.state.lstClass['active'],filter:param});
    // }
    const { exam } = this.state.lstClass;

    let data =
      this.state.activeTab == 1
        ? this.state.lstConnected
        : this.state.lstRequest;
    if (data && data.length > 0) {
      if (param == '') {
        data = [...data].sort((a, b) =>
          a.custom_name.localeCompare(b.custom_name),
        );
      }
      if (param == 'exam' && exam) {
        data = [...data].sort((a, b) => {
          a.count =
            exam[`${a.class_id}`] && exam[`${a.class_id}`].todo
              ? exam[`${a.class_id}`].todo.length
              : 0;
          b.count =
            exam[`${b.class_id}`] && exam[`${b.class_id}`].todo
              ? exam[`${b.class_id}`].todo.length
              : 0;

          return b.count - a.count;
        });
      }
    }
    if (this.state.activeTab == 1) {
      if (filter.class) {
        filter.class = {
          ...filter.class,
          active: param,
        };
      } else {
        filter = {
          ...filter,
          class: { active: param },
        };
      }
      this.setState({ lstConnected: data, filter: param });
    } else {
      if (filter.class) {
        filter.class = {
          ...filter.class,
          request: param,
        };
      } else {
        filter = {
          ...filter,
          class: { request: param },
        };
      }
      this.setState({ lstRequest: data, filter: param });
    }
    localStorage.setItem('filter', JSON.stringify(filter));
  };

  render() {
    let {
      filter,
      classes,
      lstRequest,
      lstConnected,
      classesSelected,
      loading,
      lstClass,
      visible,
      visibleDrawer,
    } = this.state;
    filter = filter == '' ? 'Sắp xếp' : 'Theo bài thi';

    return (
      <Container className="screen-class">
        <Row>
          {!loading && (
            <Col span={24}>
              <div className="social__header">
                <div className="social__title">
                  <span className="title-classes">Lớp học của tôi </span>
                  <div className="action-group-right">
                    <div className="site-button-ghost-wrapper mr-3">
                      <Button
                        className={`${
                          this.state.activeTab === '1' ? 'active' : ''
                        } mr-2`}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Đã tham gia{' '}
                        <Badge
                          count={
                            lstConnected && lstConnected.length > 0
                              ? lstConnected.length
                              : 0
                          }
                          className="site-badge-count"
                        />
                      </Button>
                      <Button
                        className={`${
                          this.state.activeTab === '2' ? 'active' : ''
                        }`}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        Chờ phê duyệt{' '}
                        <Badge
                          count={
                            lstRequest && lstRequest.length > 0
                              ? lstRequest.length
                              : 0
                          }
                          className="site-badge-count"
                        />
                      </Button>
                    </div>
                    <Dropdown
                      overlay={
                        <Menu>
                          {/* <Menu.Item onClick={()=>this.filter('name')}>
                      Theo tên
                    </Menu.Item> */}
                          {this.state.activeTab == '1' && (
                            <Menu.Item onClick={() => this.filter('exam')}>
                              Theo bài thi
                            </Menu.Item>
                          )}
                          <Menu.Item onClick={() => this.filter('')}>
                            Mặc định
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button className="mr-3 default-button">
                        {filter}{' '}
                        <ControlOutlined className="vertical-align-initial" />
                      </Button>
                    </Dropdown>
                    <Button type="primary" onClick={() => this.openRequest()}>
                      <i
                        className="fa fa-plus-circle mr-1"
                        aria-hidden="true"
                      />{' '}
                      Thêm lớp
                    </Button>
                  </div>
                </div>
                <hr className="line mt-3" />
              </div>

              <TabContent className="tab-cus" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {lstClass && lstConnected && lstConnected.length > 0 ? (
                    <Row key={Math.random()}>
                      {lstConnected.map((item) => {
                        const teacherName =
                          lstClass.class[`${item.code}`] &&
                          lstClass.class[`${item.code}`].main_teacher_name
                            ? lstClass.class[`${item.code}`].main_teacher_name
                            : '';

                        return (
                          <Col
                            xs="12"
                            sm="6"
                            xl="3"
                            lg="4"
                            className="mb-4"
                            key={item.id}
                          >
                            <XCard
                              edit
                              item={item}
                              teacherName={teacherName}
                              lstClass={lstClass}
                              deleteConnectiveClass={this.deleteConfirm}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <ClassRequest showDrawer={this.showDrawer} />
                  )}
                </TabPane>
                <TabPane tabId="2">
                  {lstClass && lstRequest && lstRequest.length > 0 ? (
                    <Row key={Math.random()}>
                      {lstRequest.map((item) => {
                        const teacherName =
                          lstClass.class[`${item.code}`] &&
                          lstClass.class[`${item.code}`].main_teacher_name
                            ? lstClass.class[`${item.code}`].main_teacher_name
                            : '';

                        return (
                          <Col
                            xs="12"
                            sm="6"
                            xl="3"
                            lg="4"
                            className="mb-4"
                            key={item.id}
                          >
                            <XCard
                              hideExtra
                              edit={false}
                              item={item}
                              teacherName={teacherName}
                              lstClass={lstClass}
                              deleteConnectiveClass={this.deleteConfirm}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  ) : (
                    <EmptyData
                      img={studying}
                      text={
                        <p>
                          <i> Không có yêu cầu nào đang chờ phê duyệt</i>
                        </p>
                      }
                    />
                  )}
                </TabPane>
              </TabContent>
            </Col>
          )}
          <ClassConfirmDelete
            closeModal={this.closeModal}
            modal={this.state.modal}
            class={classesSelected}
            resfeshData={this.getListConnectiveClass}
          />
          <Drawer
            closeIcon={
              <i className="fa fa-times-circle danger" aria-hidden="true" />
            }
            title=""
            width={400}
            onClose={this.onClose}
            visible={visibleDrawer}
            bodyStyle={{ paddingBottom: 80, marginTop: 80 }}
          >
            {(visible == 2 || visible == 3) && (
              <MessageRequest
                onBack={() => this.onBack()}
                onSubmitRequest={this.onSubmitRequest}
                classes={classes}
              />
            )}

            {visible == 1 && (
              <div className="text-center">
                <Avatar
                  size={{
                    xs: 24,
                    sm: 32,
                    md: 40,
                    lg: 64,
                    xl: 80,
                    xxl: 80,
                  }}
                  src={ic_class}
                  shape="square"
                />
                <RequestFormClass
                  visibleDrawer={visibleDrawer}
                  placement="bottomRight"
                  showDrawer={this.showDrawer}
                />
              </div>
            )}
          </Drawer>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Classes);
