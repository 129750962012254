import React from 'react';
import { Form, Input } from 'antd';

const InputDistrictOther = React.memo(({ name, disabled }) => {
  return (
    <Form.Item
      disabled={disabled}
      name={name}
      rules={[
        {
          required: true,
          message: 'Vui lòng nhập Quận/Huyện',
        },
      ]}
      normalize={(value) => {
        if (value.trim() === '') {
          return '';
        }

        return value;
      }}
    >
      <Input placeholder="Nhập Quận/Huyện" />
    </Form.Item>
  );
});

export default InputDistrictOther;
