import React from 'react';
import { Row, Col } from 'antd';
import SelectProvince from './SelectProvince';
import SelectDistrict from './SelectDistrict';
import InputProvinceOther from './InputProvinceOther';
import InputDistrictOther from './InputDistrictOther';
import SelectHighSchool from './SelectHighSchool';
import InputHighSchoolOther from './InputHighSchoolOther';

const FormHighSchool = React.memo(
  ({
    prefix,
    disabled,
    profileObj,
    provinces,
    districts,
    schools,
    resetFields,
    required = false,
  }) => {
    return (
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <SelectProvince
            name={`province${prefix}`}
            required={required}
            options={provinces}
            disabled={disabled}
            onChange={(value) => {
              resetFields([
                `province${prefix}_other`,
                `district${prefix}_other`,
                `highschool${prefix}_other`,
              ]);

              const updateValue = value == '-1' ? '-1' : '';

              resetFields(
                [`district${prefix}`, `highschool${prefix}`],
                updateValue,
              );
            }}
          />
          {profileObj[`province${prefix}`] == '-1' && (
            <InputProvinceOther
              name={`province${prefix}_other`}
              disabled={disabled}
            />
          )}
        </Col>
        <Col xs={24} md={8}>
          <SelectDistrict
            name={`district${prefix}`}
            required={required}
            options={districts}
            disabled={disabled}
            onChange={(value) => {
              resetFields([
                `district${prefix}_other`,
                `highschool${prefix}_other`,
              ]);

              const updateValue = value == '-1' ? '-1' : '';

              resetFields([`highschool${prefix}`], updateValue);
            }}
          />
          {(profileObj[`province${prefix}`] == '-1' ||
            profileObj[`district${prefix}`] == '-1') && (
            <InputDistrictOther
              name={`district${prefix}_other`}
              disabled={disabled}
            />
          )}
        </Col>
        <Col xs={24} md={8}>
          <SelectHighSchool
            name={`highschool${prefix}`}
            required={required}
            options={schools}
            disabled={disabled}
            onChange={(_) => resetFields([`highschool${prefix}_other`])}
          />
          {(profileObj[`province${prefix}`] == '-1' ||
            profileObj[`district${prefix}`] == '-1' ||
            profileObj[`highschool${prefix}`] == '-1') && (
            <InputHighSchoolOther
              name={`highschool${prefix}_other`}
              disabled={disabled}
            />
          )}
        </Col>
      </Row>
    );
  },
);

export default FormHighSchool;
