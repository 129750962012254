import { ktTrackingSetReferenceId } from 'core/ui';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router';
import PATHS from 'themes/paths';

const Reference = () => {
  const history = useHistory();
  const match = useRouteMatch();
  useEffect(() => {
    ktTrackingSetReferenceId(match.params.referenceId);
    history.push(PATHS.exam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Reference;
