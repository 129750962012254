const subjects = [
  {
    value: 'Toán học',
    label: 'Toán học',
  },
  {
    value: 'Đọc hiểu',
    label: 'Đọc hiểu',
  },
  {
    value: 'Khoa học và giải quyết vấn đề',
    label: 'Khoa học và giải quyết vấn đề',
  },
];

export default subjects;
