import React, { Component } from 'react';
import { Image, Form, Input, Button, message } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Auth from '../../../app/auth';
import logo from 'themes/default/logo-full.svg';
import { changePassUser } from '../service';
import { getErrorMessages } from '../../../app/utils/functions';
import check_circle from '../../../assets/img/theme/check-circle.svg';
import { Link } from 'react-router-dom';
import PATHS from 'themes/paths';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editPassObj: {
        oldpass: '',
        newpass: '',
        confirmpass: '',
      },
      changeError: {
        oldpass: false,
        newpass: false,
        confirmpass: false,
      },
      user: this.props.user,
      resetForm: false,
      changeStatus: false,
    };
  }

  changeVal = (changedValues, allValues) => {
    this.setState({
      editPassObj: allValues,
    });
  };

  onFormChangePass = async () => {
    if (!this.props.user) {
      return;
    }
    const data = {
      ...this.state.editPassObj,
    };
    const response = await changePassUser(this.props.user.id, data);

    if (response.data.code == 200) {
      // message.success('Đổi mật khẩu thành công. Hãy đăng nhập lại bằng mật khẩu bạn vừa đổi');
      Auth.logout(false);
      this.setState({ changeStatus: true });
    } else {
      this.setState(
        {
          changeError: response.data.errors,
        },
        () => {
          if (
            typeof response.data.errors != 'undefined' &&
            Object.keys(response.data.errors).length > 0
          ) {
            Object.values(response.data.errors).forEach((element) => {
              message.error(element);
            });
          } else {
            message.error(getErrorMessages(response.data));
          }
        },
      );
    }
  };

  render() {
    const { editPassObj, changeStatus, user } = this.state;

    return (
      <div className="page-login">
        <>
          <Container className="">
            <Row>
              <Col xs="12" className="mt-6">
                <div className="text-center">
                  <img className="logo-kt mb-4 mt-4" alt="..." src={logo} />
                </div>
                <div className="auth-form ">
                  <div className="auth-form__inner-wrap">
                    {!changeStatus ? (
                      <>
                        <div className="mb-3 text-center">
                          <h1 className="auth-form__header-welcome">
                            Đổi mật khẩu
                          </h1>
                          <p>
                            Bạn sẽ đổi mật khẩu cho:{' '}
                            <span className="secondary">
                              {this.props.user.username}
                            </span>
                          </p>
                        </div>

                        <Form
                          layout="vertical"
                          name="basic"
                          className="profile-form"
                          initialValues={editPassObj}
                          onValuesChange={this.changeVal}
                          key={this.state.resetForm}
                        >
                          <Form.Item
                            label={
                              <span>
                                Mật khẩu cũ{' '}
                                <span className="text-danger">*</span>
                              </span>
                            }
                            name="oldpass"
                          >
                            <Input.Password
                              className={
                                this.state.changeError.oldpass
                                  ? 'is-invalid-date'
                                  : ''
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            label={
                              <span>
                                Mật khẩu mới{' '}
                                <span className="text-danger">*</span>
                              </span>
                            }
                            name="newpass"
                          >
                            <Input.Password
                              className={
                                this.state.changeError.newpass
                                  ? 'is-invalid-date'
                                  : ''
                              }
                            />
                          </Form.Item>

                          <Form.Item
                            label={
                              <span>
                                Nhập lại mật khẩu mới{' '}
                                <span className="text-danger">*</span>
                              </span>
                            }
                            name="confirmpass"
                          >
                            <Input.Password
                              className={
                                this.state.changeError.confirmpass
                                  ? 'is-invalid-date'
                                  : ''
                              }
                            />
                          </Form.Item>

                          <Form.Item>
                            <Button
                              type="primary"
                              className="b-r-8 btn-submit mb-3"
                              block
                              onClick={this.onFormChangePass}
                            >
                              Đổi mật khẩu
                            </Button>
                            <Link to={PATHS.profile}>
                              <Button
                                type="link"
                                block
                                className="default-color"
                              >
                                Hủy thao tác
                              </Button>
                            </Link>
                          </Form.Item>
                        </Form>
                      </>
                    ) : (
                      <div className="text-center">
                        <Image
                          className="mb-3"
                          size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 80,
                          }}
                          src={check_circle}
                          preview={false}
                        />
                        <div className="mb-5 text-center">
                          <h1 className="auth-form__header-welcome">
                            Đổi mật khẩu thành công
                          </h1>
                          <p>
                            Bạn đã đổi mật khẩu cho:{' '}
                            <span className="secondary">{user.username}</span>
                          </p>
                        </div>
                        <Button
                          type="primary"
                          className="mt-5 b-r-8 btn-submit mb-3 input-default"
                          block
                        >
                          <a href="/">Về trang đăng nhập</a>
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ChangePassword);
