import React, { Component } from 'react';
import { Button, Image, message, Modal } from 'antd';
import ic_class from 'themes/default/svg-same-color/icon-class.svg';
import { delConnectiveClass } from '../service';

class ClassConfirmDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
    };
  }

  deleteConfirm = (classesSelected) => {
    if (classesSelected) {
      this.setState({
        classesSelected,
        modal: !this.state.modal,
      });
    } else {
      return message.error('Không tìm thấy lớp học');
    }
  };

  hideDeleteConfirm = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  afterClose = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  };

  deleteConnectiveClass = async () => {
    const self = this;
    const classes = this.props.class;
    if (!classes) {
      return message.error('Không tìm thấy lớp học');
    }
    const response = await delConnectiveClass(classes.code);
    if (response.data.code == 200) {
      message.success(
        this.props.class.status == 0
          ? `Bạn đã hủy đăng ký lớp ${classes.custom_name}`
          : `Bạn đã rời lớp ${classes.custom_name}`,
      );

      setTimeout(() => {
        self.afterClose();
        if (self.props.resfeshData) {
          self.props.resfeshData();
        }
      }, 2000);
    } else {
      message.error(response.data.message);
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.modal != this.state.modal) {
      this.setState({ modal: nextProps.modal });
    }
  };

  render() {
    return (
      <Modal
        afterClose={this.afterClose}
        visible={this.state.modal}
        onOk={this.hideDeleteConfirm}
        onCancel={this.hideDeleteConfirm}
        footer={null}
        keyboard
        maskClosable
        closable
        centered
        className="modal-setting-info"
        closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
      >
        <div className="btn-block text-center">
          <Image
            className="mb-3"
            size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 80,
              xxl: 80,
            }}
            src={ic_class}
            preview={false}
          />
          <p>
            Bạn muốn {this.props.class.status == 0 ? 'hủy đăng ký' : 'rời khỏi'}{' '}
            lớp học{' '}
            <span className="secondary">
              {this.props.class.custom_name || ''}
            </span>{' '}
            {this.props.class.main_teacher_name
              ? `của giáo viên ${this.props.class.main_teacher_name}`
              : ''}
          </p>
          <Button
            type="primary"
            danger
            className="btn-radius-4 mr-2"
            onClick={this.deleteConnectiveClass}
          >
            {this.props.class.status == 0 ? 'Hủy đăng ký' : 'Rời khỏi lớp'}
          </Button>

          {this.props.class.status == 1 && (
            <Button
              className="btn-radius-4"
              type="primary"
              onClick={this.hideDeleteConfirm}
            >
              Vẫn học tiếp
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}

export default ClassConfirmDelete;
