import * as queryString from 'query-string';

const ktTrackingSetReferenceId = (referenceID) => {
  if (
    referenceID &&
    typeof referenceID == 'string' &&
    referenceID.length > 0 &&
    referenceID.length < 10
  ) {
    localStorage.setItem('ReferenceID', referenceID);
  }
};

const ktSaveTrackingDataFromLocation = (location) => {
  const data = queryString.parse(location.search);

  ktTrackingSetReferenceId(data.ReferenceID);
};

const ktGetTrackingData = () => {
  const data = {};
  let referenceID = localStorage.getItem('ReferenceID');
  if (referenceID && referenceID.length < 10) {
    data.ReferenceID = referenceID;
  }

  return data;
};

const ktSaveTrackingData = (data) => {
  Object.keys(data).forEach((key) => {
    localStorage.setItem(key, data[key]);
  });
};

export {
  ktSaveTrackingData,
  ktSaveTrackingDataFromLocation,
  ktGetTrackingData,
  ktTrackingSetReferenceId,
};
