import * as Sentry from '@sentry/browser';
import moment from 'moment';

const init = async () => {
  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};

    let release = '';
    if (
      typeof process.env.REACT_APP_SENTRY_RELEASE == 'undefined' ||
      process.env.REACT_APP_SENTRY_RELEASE == '@@REACT_APP_SENTRY_RELEASE@@'
    ) {
      release = moment().format('YYYYMMDDHHMM');
    } else {
      release = process.env.REACT_APP_SENTRY_RELEASE;
    }
    if (process.env.REACT_APP_SENTRY_ENVIRONMENT == 'production') {
      Sentry.init({
        release: release,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        dsn: process.env.REACT_APP_SENTRY_DSN,
      });
    }
  }

  return;
};

export default init;
