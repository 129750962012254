import React from 'react';
import { Form, Select } from 'antd';
import { filterSelectOptions } from 'app/utils/functions';

const SelectDistrict = React.memo(
  ({ options, name, disabled, onChange, required = true }) => {
    return (
      <Form.Item
        label={<span>Quận/Huyện</span>}
        name={name}
        rules={[{ required: required, message: 'Vui lòng chọn Quận/Huyện' }]}
      >
        <Select
          disabled={disabled}
          showSearch
          placeholder="Chọn"
          optionFilterProp="children"
          options={options}
          notFoundContent="Không tìm thấy kết quả"
          onChange={onChange}
          filterOption={filterSelectOptions}
        />
      </Form.Item>
    );
  },
);

export default SelectDistrict;
