import React, { Component } from 'react';
import { Col, Row, Label, Input, FormGroup } from 'reactstrap';
import TextEditor from '../../../Common/TextEditor';
import { getChoiceLabel } from '../../../../app/utils/functions';

class SimpleChoice extends Component {
  constructor(props) {
    super(props);

    this.onItemChoiceEdit = this.onItemChoiceEdit.bind(this);
    this.onResponseChange = this.onResponseChange.bind(this);
  }

  onItemChoiceEdit(event, content, i) {
    this.props.choicesObj[i] = content;
    this.props.onChange({
      choicesObj: this.props.choicesObj,
      response: this.props.response,
    });
  }

  onResponseChange(event) {
    this.props.onChange({
      choicesObj: this.props.choicesObj,
      response: event.target.value,
    });
  }

  render() {
    const html = [];

    for (let i = 1; i <= 4; i++) {
      const initialValue =
        typeof this.props.choicesObj[i] != 'undefined'
          ? this.props.choicesObj[i]
          : '';
      html.push(
        <FormGroup key={i} row>
          <Col md="3">
            <Label
              className={
                this.props.errorFields[`choice_${i}`] ? 'is-invalid' : ''
              }
              htmlFor="textarea-input"
            >
              Phương án {i} {i < 3 && <span className="is-invalid">(*)</span>}
            </Label>
          </Col>
          <Col xs="12" md="9">
            <TextEditor
              /* eslint react/style-prop-object: "off" */
              style="body {font-size: 14px}"
              id={`choice_${i}`}
              name={`choice_${i}`}
              height={this.props.height || 100}
              value={initialValue}
              onChange={(event, content) =>
                this.onItemChoiceEdit(event, content, i)
              }
            />
          </Col>
        </FormGroup>,
      );
    }

    html.push(
      <FormGroup key={9999999} row>
        <Col md="3">
          <Label
            className={this.props.errorFields.response ? 'is-invalid' : ''}
            htmlFor="multiple-select"
          >
            Đáp án đúng <span className="is-invalid">(*)</span>
          </Label>
        </Col>
        <Col md="9">
          <Input
            type="select"
            name="response"
            value={this.props.response}
            invalid={!!this.props.errorFields.response}
            onChange={this.onResponseChange}
          >
            <option value="">Chọn</option>
            {Object.keys(this.props.choicesObj).map((key, index) => (
              <option key={key} value={key}>
                Phương án {key}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>,
    );

    return html;
  }
}

class SimpleChoicePreview extends Component {
  render() {
    const data = this.props.response;
    const itemId = data.id ? data.id : 0;
    const response = data.response ? parseInt(JSON.parse(data.response)) : '';

    return (
      <Row className="item-choices avoid-break choice">
        {Object.keys(this.props.choicesObj).map((key, index) => {
          let resItem = '';
          if (response == key) {
            resItem =
              parseInt(data.answer) == key
                ? 'result-correct correct'
                : 'result-correct';
          } else {
            resItem = parseInt(data.answer) == key ? 'wrong' : '';
          }
          if (this.props.choicesObj[key]) {
            return (
              <Col
                xs={data && data.item_layouts ? data.item_layouts : 12}
                key={index}
              >
                <p
                  className={
                    !this.props.hideResponse
                      ? `${resItem} group-choice flex mb-1`
                      : 'group-choice flex mb-1'
                  }
                >
                  <span className="option-choice mr-2">
                    {getChoiceLabel()[key]}
                  </span>
                  {/* <Input type="radio" disabled />
                <font className="choice-label">{getChoiceLabel()[key]}</font> {}  */}
                  <span
                    className="content-item"
                    id={`_item_${itemId}_choice_${key}`}
                    dangerouslySetInnerHTML={{
                      __html: this.props.choicesObj[key],
                    }}
                  />
                </p>
              </Col>
            );
          }

          return '';
        })}
      </Row>
    );
  }
}

function simpleChoicePrepareData(choicesObj) {
  for (const key in choicesObj) {
    if (window.tinyMCE.get(`choice_${key}`)) {
      window.tinyMCE.get(`choice_${key}`).setContent(choicesObj[key]);
    }
  }
}

export { SimpleChoice, SimpleChoicePreview, simpleChoicePrepareData };
