import React from 'react';
import { Form, Select } from 'antd';
import { filterSelectOptions } from 'app/utils/functions';

const SelectHighSchool = React.memo(
  ({ options, name, disabled, required = false }) => {
    return (
      <Form.Item
        label={<span>Trường THPT</span>}
        name={name}
        rules={[{ required: required, message: 'Vui lòng nhập Trường THPT' }]}
      >
        <Select
          disabled={disabled}
          showSearch
          placeholder="Chọn"
          optionFilterProp="children"
          options={options}
          notFoundContent="Không tìm thấy kết quả"
          filterOption={filterSelectOptions}
        />
      </Form.Item>
    );
  },
);

export default SelectHighSchool;
