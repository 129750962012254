import { TsaRegisterContextService } from './TsaRegisterContext';
import {
  registerExam,
  getPaymentMethod,
  createPayment,
  checkInvoice,
  getTestSites,
} from '../../service';
import { message } from 'antd';
import { getErrorMessages } from '../../../../app/utils/functions';
import { getProfiles } from '../../../User/service';
import { get } from 'lodash';

function useTsaRegisterDomain() {
  const [state, TsaRegisterDispatcher] = TsaRegisterContextService();

  const TsaRegisterDomain = {
    checkValidInvoice: async (billing_id, payment_id, search) => {
      let response = await checkInvoice({
        billing_id,
        payment_id,
        data: search,
      });

      if (response.data.code == 200) {
        if (
          response.data.data &&
          typeof response.data.data.transaction_data !== 'object'
        ) {
          try {
            response.data.data.transaction_data = JSON.parse(
              response.data.data.transaction_data,
            );
          } catch (error) {
            response.data.data.transaction_data = {};
          }
        }
        TsaRegisterDispatcher.updateContext({
          currentStep: 3,
          billing: response.data.data.billing,
          loading: false,
        });
      } else {
        TsaRegisterDispatcher.updateContext(
          { loading: false, error: true },
          () => {
            message.warn(
              'Có lỗi xảy ra, đang tải lại sau 5 giây. Vui lòng chờ.',
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          },
        );
      }
    },

    getProfile: async (code) => {
      console.log('getProfile code', code);
      const response = await getProfiles({ code: code });
      console.log('response', response);
      if (response.data.code == 200) {
        const billingRegistryTmp = {};
        response.data.data.billings.forEach((b) => {
          try {
            b.customer_invoice_data = JSON.parse(b.customer_invoice_data);
            const profile_id = get(b, 'customer_invoice_data.profile_id');
            const url = get(b, 'customer_invoice_data.url');
            if (url == state.exam.url) {
              billingRegistryTmp[profile_id] = b;
            }
          } catch (error) {}
        });

        let profiles = response.data.data.profiles.map((p) => {
          try {
            p.data = typeof p.data == 'string' ? JSON.parse(p.data) : p.data;
          } catch (error) {
            p.data = {};
          }

          p.billing = billingRegistryTmp[p.id];

          return p;
        });

        let profile = profiles[0];

        console.log('profile', profile);

        TsaRegisterDispatcher.updateContext({
          profile: profile,
          loading: false,
        });
      } else {
        return message.error(getErrorMessages(response.data));
      }
    },

    // chooseProfile: (item) => {
    //   if (item.data && typeof item.data !== 'object') {
    //     item.data = JSON.parse(item.data);
    //   }
    //   TsaRegisterDispatcher.updateContext({
    //     chooseProfile: item,
    //     orderId: item.billing && item.billing.id ? item.billing.id : '',
    //     billing: item.billing ? item.billing : null,
    //   });
    // },

    quickView: (item) => {
      if (item.data && typeof item.data !== 'object') {
        item.data = JSON.parse(item.data);
      }
      TsaRegisterDispatcher.updateContext({
        modalDetail: true,
        chooseProfile: item,
        orderId: item.billing && item.billing.id ? item.billing.id : '',
        billing: item.billing ? item.billing : null,
      });
    },

    choosePaymentMethod: (paymentMethod) => {
      TsaRegisterDispatcher.updateContext({ paymentMethod });
    },

    hideConfirm: () => {
      TsaRegisterDispatcher.updateContext({
        modal: !state.modal,
      });
    },

    hideConfirmDetail: () => {
      TsaRegisterDispatcher.updateContext({
        modalDetail: !state.modalDetail,
      });
    },

    hideModalRedirectToLogin: () => {
      TsaRegisterDispatcher.updateContext({
        modalRedirectToLogin: !state.modalRedirectToLogin,
        searchEmail: '',
        // loadingSearchProfileByEmail: ApiNone,
      });
    },

    hideModalSelectPayMethod: () => {
      TsaRegisterDispatcher.updateContext({
        modalSelectPayMethod: !state.modalSelectPayMethod,
      });
    },

    increaseStep: () => {
      TsaRegisterDispatcher.updateContext({
        currentStep: state.currentStep + 1,
      });
    },

    nextStep: async (formRef) => {
      if (!state.user.email_verified) {
        message.error('Bạn cần thực hiện xác thực email trước khi đăng ký');

        return;
      }

      const { currentStep, profile, checkedRule } = state;

      if (currentStep === 4) {
        return;
      }

      if (currentStep === 0 && !profile.id) {
        return message.error('Bạn chưa chọn hồ sơ để đăng ký');
      }

      let testDayData;
      if (currentStep === 1) {
        if (formRef.current) {
          formRef.current.submit();

          testDayData = formRef.current.getFieldValue();
          if (
            !testDayData.test_day_1 &&
            !testDayData.test_day_2 &&
            !testDayData.test_day_3
          ) {
            return message.error('Bạn cần chọn ít nhất một đợt thi');
          }

          console.log('testDayData', testDayData);

          testDayData.test_day_1 = testDayData?.test_day_1 ? 1 : 0;
          testDayData.test_day_2 = testDayData?.test_day_2 ? 1 : 0;
          testDayData.test_day_3 = testDayData?.test_day_3 ? 1 : 0;

          console.log('testDayData', testDayData);
        }
      }
      if (currentStep === 1 && !checkedRule) {
        return message.error(
          'Bạn cần đọc và đồng ý với nội dung quy chế quy định',
        );
      }

      if (currentStep === 1) {
        const data = {
          customer_invoice_data: {
            profile_id: profile.id,
            url: state.exam.url,
            testDayData: testDayData,
          },
          invoice_description: state.exam.name,
        };

        TsaRegisterDispatcher.updateContext({
          loadingStep3: true,
        });

        const result = await registerExam(
          state.exam.id,
          profile.id,
          state.testSiteIdSelected,
        );

        TsaRegisterDispatcher.updateContext({
          loadingStep3: false,
        });

        if (result.data.code == 200) {
          const billing = result.data.data.billing;

          TsaRegisterDispatcher.updateContext({
            billing,
          });

          if (billing.status === 1) {
            return TsaRegisterDispatcher.updateContext({
              currentStep: 3,
            });
          } else {
            const res = await getPaymentMethod();
            if (res.data.code == 200 && res.data.data.payment_method_data) {
              return TsaRegisterDispatcher.updateContext({
                modalSelectPayMethod: true,
                paymentMethodData: res.data.data.payment_method_data,
                paymentMethodGroup: res.data.data.payment_method_group,
                orderId: result.data.data.billing
                  ? result.data.data.billing.id
                  : '',
              });
            }

            return message.error('Không tìm thấy phương thức thanh toán');
          }
        } else {
          message.error(getErrorMessages(result.data));
        }

        return;
      }

      TsaRegisterDispatcher.updateContext({
        currentStep: currentStep + 1,
        modalDetail: false,
      });
    },

    backStep: () => {
      let { currentStep, checkedRule } = state;
      if (currentStep <= 2) {
        checkedRule = false;
      }
      TsaRegisterDispatcher.updateContext({
        currentStep: currentStep - 1,
        checkedRule,
      });
    },

    onChangeCheckbox: (e) => {
      TsaRegisterDispatcher.updateContext({
        checkedRule: e.target.checked,
      });
    },

    continueRegister: async (payment_method_id = 1) => {
      const { orderId, searchEmail } = state;

      let result = await createPayment({
        billing_id: orderId,
        payment_method_id: payment_method_id,
      });

      if (result.data.code == 200) {
        window.location.href = result.data.data.url;
      } else {
        return message.error(getErrorMessages(result.data));
      }
    },

    getTestSites: async () => {
      let response = await getTestSites(state.exam.id);
      if (response?.data?.code == 200) {
        return response.data.data.test_sites;
      }
      return [];
    },
  };

  return TsaRegisterDomain;
}

export default useTsaRegisterDomain;
