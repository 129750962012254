import React from 'react';
import { Link } from 'react-router-dom';

const CustomContainer = (props) => {
  if (props.onSelect) {
    return (
      <div key={`cm${Date.now()}`} onClick={props.onSelect}>
        {props.children}
      </div>
    );
  }

  if (props.url) {
    if (props.useHref) {
      return (
        <a key={`cm${Date.now()}`} href={props.url}>
          {props.children}
        </a>
      );
    }

    return (
      <Link key={`cm${Date.now()}`} to={props.url}>
        {props.children}
      </Link>
    );
  }

  return <div key={`cm${Date.now()}`}>{props.children}</div>;
};

export default CustomContainer;
