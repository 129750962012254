import React, { Component } from 'react';
import { Popover, Button, Input, message, Select } from 'antd';

const { Option } = Select;

class RequestFormClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      type: 1,
    };
  }

  showDrawer = () => {
    const { code } = this.state;
    if (!code) {
      return message.error('Bạn chưa nhập mã lớp cần liên kết');
    }
    if (this.props.showDrawer) {
      this.props.showDrawer(2, code);
    }
  };

  showDrawerUser = () => {
    const { code, type } = this.state;
    if (!code) {
      return message.error('Bạn chưa nhập mã hồ sơ');
    }
    if (this.props.showDrawer) {
      this.props.showDrawer(2, code, type);
    }
  };

  onFormChange = (event) => {
    const code = event.target.value;
    this.setState({ code });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (!nextProps.visibleDrawer) {
      this.setState({ code: '' });
    }
  };

  handleChange = (type) => {
    this.setState({ type });
  };

  render() {
    if (this.props.type == 2) {
      return (
        <div className="form-request mt-2">
          <p className="mb-4">
            Nhập mã hồ sơ của người mà bạn muốn theo dõi
            <br />
            <Popover
              placement={this.props.placement}
              content="Mã hồ sơ dài 12 kí tự bao gồm chữ cái và số."
              title=""
            >
              <span className="secondary">Mã hồ sơ là gì?</span>
            </Popover>
          </p>
          <Select
            defaultValue="1"
            onChange={this.handleChange}
            className="mb-4 select-option"
          >
            <Option value="1">Liên kết với tài khoản sẽ theo dõi bạn</Option>
            <Option value="2">Liên kết với tài khoản bạn sẽ theo dõi</Option>
          </Select>
          <Input
            value={this.state.code}
            onChange={this.onFormChange}
            style={
              this.state.code
                ? {
                    textTransform: 'uppercase',
                  }
                : {}
            }
            size="large"
            placeholder="Nhập mã hồ sơ"
            className="mb-4"
          />
          <br />
          <Button type="primary" size="large" onClick={this.showDrawerUser}>
            Tiếp theo
          </Button>
        </div>
      );
    }

    return (
      <div className="form-request mt-2">
        <p>
          Để tham gia lớp học hãy nhập mã lớp học thầy cô gửi bạn
          <br />
          <Popover
            placement={this.props.placement}
            content="Mã lớp gồm chữ cái và số có độ dài 6 kí tự. Mã lớp được giáo viên gửi cho bạn và được dùng để gửi yêu cầu tham gia lớp học"
            title=""
          >
            <span className="primary">Mã lớp học là gì?</span>
          </Popover>
        </p>
        <Input
          value={this.state.code}
          onChange={this.onFormChange}
          style={
            this.state.code
              ? {
                  textTransform: 'uppercase',
                }
              : {}
          }
          size="large"
          placeholder="Nhập mã lớp học"
          className="mb-2"
        />
        <br />
        <Button type="primary" size="large" onClick={this.showDrawer}>
          Tham gia lớp học
        </Button>
      </div>
    );
  }
}

export default RequestFormClass;
