/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { formatVndMoney } from '../../../../app/utils/functions';
import { CloseCircleTwoTone } from '@ant-design/icons';
import icon_success from '../../../../assets/img/theme/icon_success.svg';
import { TsaRegisterContextService } from '../TsaRegister/TsaRegisterContext';
import ExamTypes from 'modules/Exam/Components/ExamTypes';

const Step3Finish = ({ ...props }) => {
  const [state, TsaRegisterDispatcher] = TsaRegisterContextService();

  const { billing } = state;
  const { exam } = props;

  return (
    <Row className="mt-4">
      <Col xs="12" className="mb-2">
        {billing !== null && (
          <>
            {billing.status === 1 ? (
              <div className="order-info">
                <div className="header-order-info text-center mb-4 pb-4">
                  <img className="logo-kt mb-1" alt="..." src={icon_success} />
                  <br />
                  <strong className="f-s-md">Đăng ký thành công</strong>
                  <br />
                  {exam.type == ExamTypes.OFFLINE && (
                    <>
                      Thông tin đăng ký của bạn đã được ghi nhận, hệ thống sẽ
                      sắp xếp lịch thi và cập nhật thông tin trên cổng đăng ký
                      thi.
                      <br />
                      Theo dõi thêm thông tin tại{' '}
                      <a href="/dang-ky-thi">
                        <u>Trang quản lý bài thi</u>
                      </a>
                    </>
                  )}
                  {exam.type == ExamTypes.ONLINE_2 && (
                    <>
                      Bạn đã đăng ký thi thành công {exam.name}
                      <br />
                      Để tiếp tục vào thi trực tuyến, bạn truy cập{' '}
                      <a href="/dang-ky-thi">
                        <u>Danh sách bài thi</u>
                      </a>{' '}
                      và ấn <b>Vào thi ngay</b>
                    </>
                  )}

                  {exam?.configs?.fb_page && (
                    <>
                      <br />
                      <br />
                      {exam?.configs?.fb_page?.description}
                      <br />
                      <img
                        style={{ width: 200 }}
                        src={exam?.configs?.fb_page?.qr}
                      />
                    </>
                  )}
                </div>

                <div className="flex space-between">
                  Thời gian hoàn thành:&nbsp;
                  <b>{billing.invoice_paid_ts || ''}</b>
                </div>

                <div className="flex space-between">
                  Số tiền thanh toán:&nbsp;
                  <b className="danger">
                    {formatVndMoney(billing.invoice_total_amount)}
                  </b>
                </div>
              </div>
            ) : (
              <div className="order-info">
                <div className="header-order-info text-center mb-4 pb-4">
                  <CloseCircleTwoTone
                    twoToneColor="#ff4d4f"
                    className="icon-error"
                  />
                  <br />
                  <strong className="f-s-md">
                    {billing.payment_status === -1
                      ? 'Thanh toán bị hủy'
                      : 'Thanh toán thất bại'}
                  </strong>
                </div>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default Step3Finish;
