import React, { Component, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Spinner from 'react-spinkit';
import { message, Button } from 'antd';
import 'material-design-icons/iconfont/material-icons.css';
import { Document, Page, pdfjs } from 'react-pdf';
import Viewer from 'react-viewer';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SinglePagePDFViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const { pdf } = props;

  return (
    <div className="mt-1">
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="page-controls">
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          <i className="material-icons">keyboard_arrow_left</i>
        </button>
        <span>
          {pageNumber || (numPages ? 1 : '--')} /{numPages || '--'}
        </span>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          <i className="material-icons">keyboard_arrow_right</i>
        </button>
      </div>
    </div>
  );
}

class ViewDocx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    return (
      <div className="container rsvp-wrapper">
        {this.state.loading ? (
          <Spinner
            className="loading text-center"
            name="three-bounce"
            color="white"
            fadeIn="none"
          />
        ) : null}
        <iframe
          src={`https://docs.google.com/viewer?url=${
            this.props.link || ''
          }&embedded=true`}
          width="100%"
          height="700"
          title="Viewer"
          onLoad={this.hideSpinner}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        />
      </div>
    );
  }
}

class ExtendedPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeIndex: 0,
    };
  }

  dowloadAllFile = (files) => {
    const zip = new JSZip();
    let count = 0;

    const now = moment(new Date());
    const zipFilename = `xcbt-${now.format('YYYYMMDDTHHmmss')}.zip`;

    if (!files || files.length == 0) {
      return message.error('Không có file đính kèm');
    }

    files.forEach((file) => {
      const filename = file.name;
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(file.link, (err, data) => {
        if (err) {
          // throw err; // or handle the error
          return message.error('Có lỗi trong quá trình tải file đính kèm');
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count == files.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  };

  render() {
    const data = this.props.response;
    const answer = data.answer ? JSON.parse(data.answer) : {};
    const attachment =
      answer.attachment && Array.isArray(answer.attachment)
        ? answer.attachment
        : [];
    const docx_file = attachment
      ? attachment.filter((el) => el.type === 'docx')
      : [];
    const other_file = attachment
      ? attachment.filter((el) => el.type === 'other')
      : [];
    const images = attachment
      ? attachment
          .filter((el) => el.type === 'image')
          .map((el) => ({
            src: el.link || '',
            alt: el.name || '',
            downloadUrl: el.link || '',
          }))
      : [];
    const pdfs = attachment
      ? attachment
          .filter((el) => el.type === 'pdf')
          .map((el) => ({
            name: el.name || '',
            url: el.link || '',
          }))
      : [];
    const hasAttachment =
      docx_file.length || other_file.length || images.length || pdfs.length;

    return (
      <div className="item-choices">
        <Row>
          {answer.content && (
            <Col sm="12" className="question-body mt-1">
              <p>
                <b className="primary">Bài làm</b>
              </p>
              <div className="avoid-break ">
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: answer.content || '',
                  }}
                />
              </div>
            </Col>
          )}
          {hasAttachment ? (
            <Col sm="12" className="mt-2">
              <div className="avoid-break">
                <b>File đính kèm: </b>
                <Button
                  onClick={() => {
                    this.dowloadAllFile(attachment);
                  }}
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined className="baseline" />}
                  size="small"
                >
                  Tải tất cả
                </Button>
              </div>
              {/* Docx */}
              {other_file.length > 0 && (
                <div className="">
                  {other_file.map((file, index) => (
                    <Col
                      sm={12}
                      key={index.toString()}
                      className="file-viewer mt-1 flex space-between align-center"
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: file.name || '' }}
                      />
                      <Button
                        type="secondary"
                        icon={<DownloadOutlined className="baseline" />}
                        size="small"
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.link ? file.link : ''}
                        >
                          Tải về
                        </a>
                      </Button>
                    </Col>
                  ))}
                </div>
              )}
              {/* Docx */}
              {docx_file.length > 0 && (
                <div className="">
                  {docx_file.map((file, index) => (
                    <Col
                      sm={12}
                      key={index.toString()}
                      className="file-viewer mt-1 flex space-between align-center"
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: file.name || '' }}
                      />
                      <Button
                        type="secondary"
                        icon={<DownloadOutlined className="baseline" />}
                        size="small"
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.link ? file.link : ''}
                        >
                          Tải về
                        </a>
                      </Button>
                    </Col>
                  ))}
                </div>
              )}
              {/* PDF */}
              {pdfs.length > 0 && (
                <div className="">
                  {pdfs.map((file, index) => (
                    <Col
                      sm={12}
                      key={index.toString()}
                      className="file-viewer mt-1 flex space-between align-center"
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: file.name || '' }}
                      />
                      <Button
                        type="secondary"
                        icon={<DownloadOutlined className="baseline" />}
                        size="small"
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.link ? file.link : ''}
                        >
                          Tải về
                        </a>
                      </Button>
                    </Col>
                  ))}
                </div>
              )}
              {pdfs.length > 0 && (
                <div className="">
                  {pdfs.map((file, index) => (
                    <Col
                      sm={12}
                      key={index.toString()}
                      className="file-viewer mt-1"
                    >
                      <SinglePagePDFViewer pdf={file.url} />
                    </Col>
                  ))}
                </div>
              )}
              {/* Image */}
              {images.length > 0 && (
                <div className="file-viewer mt-2">
                  <div className="wrap">
                    <div className="img-list-wrap">
                      <div className="img-list">
                        {images.map((item, index) => (
                          <div key={index.toString()} className="img-item">
                            <img
                              alt=""
                              src={item.src}
                              onClick={() => {
                                this.setState({
                                  visible: true,
                                  activeIndex: index,
                                });
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Viewer
                    visible={this.state.visible}
                    onClose={() => {
                      this.setState({ visible: false });
                    }}
                    images={images}
                    activeIndex={this.state.activeIndex}
                    container={null}
                    downloadable
                    // {...options}
                  />
                </div>
              )}
            </Col>
          ) : (
            ''
          )}
        </Row>
      </div>
    );
  }
}

export { ExtendedPreview, ViewDocx, SinglePagePDFViewer };
