import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import StudentTablePoint from 'core/my/student-table-point/student-table-point';
import { Form } from 'antd';

const TablePoint = () => {
  const [form] = Form.useForm();

  return (
    <Container>
      <Row>
        <Col span={24}>
          <div className="social__header">
            <div className="social__title">
              <div className="title-classes title-classes-light">
                <span className="title-classes">Bảng điểm</span>
              </div>
              <div className="action-group-right"></div>
            </div>
            <hr className="line mt-3" />
          </div>
        </Col>
      </Row>
      <StudentTablePoint form={form} />
    </Container>
  );
};

export default TablePoint;
