import React from 'react';
import { Form, Input } from 'antd';

const InputProvinceOther = React.memo(({ name, disabled }) => {
  return (
    <Form.Item
      disabled={disabled}
      name={name}
      rules={[
        {
          required: true,
          message: 'Vui lòng nhập Tỉnh/TP',
        },
      ]}
      normalize={(value) => {
        if (value.trim() === '') {
          return '';
        }

        return value;
      }}
    >
      <Input placeholder="Nhập Tỉnh/TP" />
    </Form.Item>
  );
});

export default InputProvinceOther;
