import React, { useState } from 'react';
import { Space, Tag, Row, Button, Typography, Modal, Col, message } from 'antd';
import classNames from 'classnames';
import styles from './RegisterExamCard.module.less';
import { useHistory } from 'react-router';
import moment from 'moment';
import { formatDate, formatVndMoney } from 'app/utils/functions';
import { get } from 'lodash';
import ExamTypes from './ExamTypes';
import PATHS from 'themes/paths';
import { Link } from 'react-router-dom';
import ExamPlanDetail from '../Screens/ExamPlanDetail';

const { Text } = Typography;

const RegisterExamCard = ({
  isGuest,
  showActions = false,
  emailVerified = false,
  testingToken = '',
  exam: {
    name,
    start_register_at,
    end_register_at,
    amount,
    start_at,
    end_at,
    url,
    is_paid,
    type,
    test_urls,
    detail_url,
    configs,
    register_status,
    exam_plan,
  },
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalTestUrl, setShowModalTestUrl] = useState(false);
  const [examPlanIdSelected, setExamPlanIdSelected] = useState(false);
  const history = useHistory();

  const formatDayOfWeek = (d) => {
    if (d == 1) {
      return 'Thứ hai,';
    } else if (d == 2) {
      return 'Thứ ba,';
    } else if (d == 3) {
      return 'Thứ tư,';
    } else if (d == 4) {
      return 'Thứ năm,';
    } else if (d == 5) {
      return 'Thứ sáu,';
    } else if (d == 6) {
      return 'Thứ bảy,';
    } else if (d == 7) {
      return 'Chủ nhật,';
    }
  };

  return (
    <div className={styles.container} style={configs?.style || null}>
      <div className={styles.name}>{name}</div>
      <div className={styles.body}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Hình thức thi:&nbsp;</div>
            <div className={styles.childRight}>
              <Text>
                <Tag
                  color={
                    type == ExamTypes.ONLINE || type == ExamTypes.ONLINE_2
                      ? 'success'
                      : 'error'
                  }
                  style={{ margin: 0 }}
                >
                  {type == ExamTypes.ONLINE && 'Thi trực tuyến'}
                  {type == ExamTypes.ONLINE_2 && 'Thi trực tuyến'}
                  {type == ExamTypes.OFFLINE && 'Thi tại địa điểm thi'}
                </Tag>
              </Text>
            </div>
          </Row>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Thời gian đăng ký:&nbsp;</div>
            <div className={styles.childRight}>
              {moment(start_register_at).format('DD/MM/YYYY')}&nbsp;-&nbsp;
              {formatDate(end_register_at)}
            </div>
          </Row>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Lệ phí:&nbsp;</div>
            <div className={styles.childRight}>
              <Text strong>
                {amount == 0 ? 'Miễn phí' : formatVndMoney(amount)}
              </Text>
            </div>
          </Row>
          <Row align="middle" justify="space-between">
            <div className={styles.childLeft}>Thời gian thi:&nbsp;</div>
            <div className={styles.childRight}>
              {start_at == end_at ? (
                <>
                  {formatDayOfWeek(moment(start_at).isoWeekday())}{' '}
                  {formatDate(start_at)}
                </>
              ) : (
                <>
                  {formatDate(start_at)}&nbsp;-&nbsp;
                  {formatDate(end_at)}
                </>
              )}
            </div>
          </Row>
        </Space>
      </div>
      {showActions && (
        <>
          <Row
            align="middle"
            justify="space-between"
            className={styles.actions}
          >
            {type == ExamTypes.OFFLINE && register_status == 'paid' ? (
              <a
                className={classNames([styles.btnDetail, 'u-link'])}
                href={null}
                onClick={() => {
                  setExamPlanIdSelected(exam_plan?.id);
                }}
              >
                Chi tiết thông tin đăng ký
              </a>
            ) : (
              <span></span>
            )}

            {type == ExamTypes.ONLINE && (
              <>
                {test_urls.length == 0 && (
                  <Button type="primary">Đang chờ</Button>
                )}
                {test_urls.length == 1 && (
                  <Button type="primary">
                    <a href={test_urls[0].url} target="_blank" rel="noreferrer">
                      Thi ngay
                    </a>
                  </Button>
                )}
                {test_urls.length > 1 && (
                  <Button
                    type="primary"
                    onClick={() => setShowModalTestUrl(true)}
                  >
                    Thi ngay
                  </Button>
                )}
              </>
            )}

            {(type == ExamTypes.OFFLINE || type == ExamTypes.ONLINE_2) && (
              <>
                {isGuest && (
                  <Button
                    type="primary"
                    disabled={moment(start_register_at) > moment()}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {moment(start_register_at) > moment()
                      ? 'Sắp diễn ra'
                      : 'Đăng ký'}
                  </Button>
                )}
                {!isGuest && (
                  <>
                    {register_status == 'paid' &&
                      type == ExamTypes.ONLINE_2 && (
                        <Button
                          type="success"
                          onClick={() => setShowModalTestUrl(true)}
                        >
                          Vào thi ngay
                        </Button>
                      )}
                    {register_status == 'paid' && type == ExamTypes.OFFLINE && (
                      <Button
                        type="success"
                        onClick={() => {
                          history.push(`/bai-thi?type=5`);
                        }}
                      >
                        Đã đăng ký
                      </Button>
                    )}
                    {(register_status == 'none' ||
                      register_status == 'pending') && (
                      <Button
                        type={register_status == 'none' ? 'primary' : 'warning'}
                        disabled={moment(start_register_at) > moment()}
                        onClick={() => {
                          if (!emailVerified) {
                            message.error(
                              'Bạn cần thực hiện xác thực email trước khi đăng ký',
                            );

                            return;
                          }
                          history.push(`/dk/${url}`);
                        }}
                      >
                        {moment(start_register_at) > moment()
                          ? 'Sắp diễn ra'
                          : register_status == 'none'
                          ? 'Đăng ký'
                          : 'Tiếp tục đăng ký'}
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
          <Modal
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
            keyboard
            maskClosable={false}
            closable
            centered
            className="modal-setting-info modal-confirm-register"
            closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
          >
            <div className="btn-block text-center">
              <p>
                <strong>Vui lòng đăng nhập để tiếp tục </strong>
              </p>
              <Row>
                <Col xs={24} md={12}>
                  <Button
                    type="link"
                    className="b-r-8 btn-step3 mt-2"
                    onClick={() => {
                      history.push(`${PATHS.register}?returnUrl=/dk/${url}`);
                    }}
                  >
                    Chưa có tài khoản?
                  </Button>
                </Col>
                <Col xs={24} md={12}>
                  <Button
                    type="primary"
                    className="b-r-8 btn-step3 mt-2"
                    onClick={() => {
                      history.push(`${PATHS.login}?returnUrl=/dk/${url}`);
                    }}
                  >
                    Đăng nhập
                  </Button>
                </Col>
              </Row>
            </div>
          </Modal>
          <Modal
            open={showModalDetail}
            onCancel={() => setShowModalDetail(false)}
            footer={null}
            keyboard
            maskClosable={false}
            closable
            centered
            width={640}
            closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
          >
            <h3>{name}</h3>
            <h4>Nội dung chi tiết sẽ được cập nhật sau</h4>
          </Modal>
          <Modal
            open={showModalTestUrl}
            onCancel={() => setShowModalTestUrl(false)}
            footer={null}
            keyboard
            maskClosable={false}
            closable
            centered
            width={640}
            closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
          >
            <h3>Chọn bài thi</h3>
            <Space direction="vertical" style={{ width: '100%' }}>
              {test_urls.map(({ subject, url }, index) => {
                return (
                  <Button key={index} type="primary" block>
                    <a
                      href={url + '/' + testingToken}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {subject}
                    </a>
                  </Button>
                );
              })}
            </Space>
          </Modal>

          <Modal
            visible={examPlanIdSelected}
            onOk={() => {
              setExamPlanIdSelected(false);
            }}
            onCancel={() => {
              setExamPlanIdSelected(false);
            }}
            footer={null}
            closable
            centered
            width={800}
            //className="modal-setting-info modal-confirm-register"
            closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
          >
            <ExamPlanDetail examPlanId={examPlanIdSelected} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default RegisterExamCard;
