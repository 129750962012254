const religions = [
  { value: '1', label: 'Không' },
  { value: '2', label: 'Hồi giáo' },
  { value: '3', label: 'Ấn độ giáo' },
  { value: '4', label: 'Phật giáo' },
  { value: '5', label: 'Khổng giáo' },
  { value: '6', label: 'Do thái giáo' },
  { value: '7', label: 'Lão giáo' },
  { value: '8', label: 'Đạo Sikh' },
  { value: '9', label: 'Đạo Jain' },
  { value: '10', label: 'Shintō' },
  { value: '11', label: 'Cao đài' },
  { value: '12', label: 'Christian' },
  { value: '13', label: 'Khác' },
  { value: '14', label: 'Thiên chúa' },
  { value: '-1', label: 'Khác' },
];

export default religions;
