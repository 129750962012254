import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import BasicForm from './BasicForm';
import 'moment/locale/vi';

class Basic extends Component {
  render() {
    const id =
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : '';

    return (
      <div className="page-login app">
        <>
          <Container className="">
            <Row>
              <Col xs="12" md={10} className="mt-4 text-auto">
                <div className="bg-form-profile">
                  <h3
                    className="text-center"
                    style={{
                      paddingTop: 24,
                      paddingBottom: 24,
                      paddingLeft: 12,
                      paddingRight: 12,
                    }}
                  >
                    Hồ sơ cơ bản
                  </h3>
                  <BasicForm
                    id={id}
                    returnUrl={this.props.location.returnUrl || null}
                    {...this.props}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </>
      </div>
    );
  }
}

export default Basic;
