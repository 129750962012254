import { Layout, Menu, Avatar, Dropdown, BackTop, Popover } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  BellOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  DownOutlined,
  UpCircleOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { isCustomDomain } from 'app/utils/functions';
import { connect } from 'react-redux';
import logo from '../../themes/default/logo-short.svg';
import logo_white from '../../themes/default/logo-square.svg';
import routes from '../../routes';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../app/config';
import Auth from '../../app/auth';
import Loading from '../../Loading';
import ProfileFriend from '../../modules/User/Components/ProfileFriend';
import StudentMenu from './StudentMenu';
import AdminMenu from './AdminMenu';
import { useMediaQuery } from 'react-responsive';
import PATHS from 'themes/paths';
import theme from 'themes';

const { Header, Sider, Content } = Layout;

const DefaultLayout = ({ user, location }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 }, undefined);

  useEffect(() => {
    const notifyAddFriend = localStorage.getItem('notifyAddFriend') || 0;
    if (
      user.friend &&
      user.friend.connected.length == 0 &&
      notifyAddFriend == 0
    ) {
      localStorage.setItem('notifyAddFriend', 1);
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const toggleCollapsed = (collapsed) => {
    setCollapsed(collapsed);
  };

  const logout = () => {
    Auth.logout();
  };

  const onClose = () => {
    window.location = '/profile?tab=4';
  };

  const handleVisibleChange = () => {
    setVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={PATHS.profile}>
          <UserOutlined className="mr-2" /> Tài khoản
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a
          /* eslint jsx-a11y/anchor-is-valid: "off" */
          className="item-menu-dropdown"
          rel="noopener noreferrer"
          href="#"
          onClick={logout}
        >
          <LogoutOutlined className="mr-2" /> Đăng xuất
        </a>
      </Menu.Item>
    </Menu>
  );

  const home_url = localStorage.getItem('home_url');

  let logoSrc = logo;
  let logoSmallSrc = logo_white;
  let customDomain = isCustomDomain();
  if (customDomain) {
    logoSrc = require('../../assets/img/custom-logo/' +
      customDomain +
      '.png').default;
    logoSmallSrc = require('../../assets/img/custom-logo/' +
      customDomain +
      '-icon.png').default;
  }

  return (
    <>
      {user.id ? (
        <Layout className="main" style={{ minHeight: '100vh' }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={250}
            className="menu-left"
            breakpoint="xxl"
            onCollapse={(collapsed) => {
              toggleCollapsed(collapsed);
            }}
          >
            <div className="logo">
              <img src={logoSrc} className="logo-desk" alt="" />
              <img src={logoSmallSrc} className="logo-collapsible" alt="" />
            </div>
            {user.is_root !== true && <StudentMenu location={location} />}
            {user.is_root === true && <AdminMenu location={location} />}
            <div className="sidebar-footer-company">
              {theme.config.copyRightLine1} © {new Date().getFullYear()}.
              <br />
              {theme.config.copyRightLine2}
              <br />
              {theme.config.copyRightLine3}
            </div>
          </Sider>
          <Layout
            className="site-layout"
            style={!collapsed ? { marginLeft: 250 } : { marginLeft: 80 }}
          >
            <Header
              className="site-layout-background header-sp"
              style={{ padding: 0 }}
            >
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: toggle,
                },
              )}
              <Menu
                theme="light"
                className="menu-top-right"
                mode="horizontal"
                disabledOverflow={!isMobile}
              >
                <Menu.Item key="1">
                  <Popover
                    overlayClassName="popover-tooltip"
                    content=""
                    title={
                      <div className="">
                        <InfoCircleTwoTone className="mr-1 vertical-text-top" />{' '}
                        Bạn có thể gửi yêu cầu theo dõi nhanh tại đây.
                      </div>
                    }
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <ProfileFriend
                      onClick={() => {
                        handleVisibleChange();
                      }}
                      icon={<UsergroupAddOutlined />}
                      onClose={() => onClose()}
                    />
                  </Popover>
                </Menu.Item>
                <Menu.Item key="2">
                  <BellOutlined />
                </Menu.Item>
                <Menu.Item key="3">
                  <Dropdown overlay={menu}>
                    <a
                      /* eslint jsx-a11y/anchor-is-valid: "off" */
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Avatar
                        src={
                          user.avatar ? user.avatar : config.getAvatarDefault()
                        }
                      />
                      {!isMobile && <>&nbsp;{user.fullname || user.email}</>}
                      <DownOutlined />
                    </a>
                  </Dropdown>
                </Menu.Item>
              </Menu>
            </Header>
            <Content
              className="body-sp site-layout-background"
              style={{
                margin: '8px 24px',
                padding: '12px 24px',
                minHeight: 280,
              }}
            >
              <BackTop>
                <UpCircleOutlined className="back-to-top f-s-xxl" />
              </BackTop>
              <Switch>
                {routes.map((route, idx) => {
                  if (route.isRoot) {
                    return user.is_root && route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) =>
                          home_url && route.path === '/' ? (
                            <Redirect to={home_url} />
                          ) : (
                            <route.component {...props} />
                          )
                        }
                      />
                    ) : null;
                  }

                  if (user.is_root && !route.isRoot && route.path === '/') {
                    return null;
                  }

                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) =>
                        home_url && route.path === '/' ? (
                          <Redirect to={home_url} />
                        ) : (
                          <route.component {...props} />
                        )
                      }
                    />
                  ) : null;
                })}
              </Switch>
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Loading />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DefaultLayout);
