import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/main.less';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Loading from './Loading';
import Auth from './app/auth';
import init from './core/init';

ReactDOM.render(<Loading />, document.getElementById('root'));

init()
  .then(() => {
    Auth.init()
      .then((res) => {
        ReactDOM.render(<App login={res} />, document.getElementById('root'));
      })
      .catch((error) => {
        localStorage.setItem('authen_access_token', '');
        localStorage.setItem('authen_refresh_token', '');
        console.log(error);
        ReactDOM.render(<App login={false} />, document.getElementById('root'));
      });
  })
  .catch((error) => {
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  });
