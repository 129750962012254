import axios from 'axios';
import QS from '../../app/utils/query-string';
import config from '../../app/config';
import { ktGetTrackingData } from 'core/ui';

export async function login(username, password) {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    data: QS.stringify({
      grant_type: 'password',
      scope:
        process.env.REACT_APP_KEYCLOAK_SCOPES != null
          ? process.env.REACT_APP_KEYCLOAK_SCOPES.split(' ')
          : '',
      username,
      password,
      client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    }),
  })
    .then((response) => response)
    .catch((error) => error);
}

export function getCities(type = '') {
  let apiUrl = `${process.env.REACT_APP_API_E_DOMAIN}assets/get-cities`;
  const method = 'get';
  if (type == 1) {
    apiUrl = `${config.getApiDomain()}/address/province`;
  }

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}
export function getDistricts(type = '', id) {
  let apiUrl = `${process.env.REACT_APP_API_E_DOMAIN}assets/get-districts?city_code=${id}`;
  const method = 'get';
  if (type == 1) {
    apiUrl = `${config.getApiDomain()}/address/district?province_id=${id}`;
  }

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}
export function getWards(id) {
  const apiUrl = `${config.getApiDomain()}/address/ward?district_id=${id}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}
export function getSchools(city, district) {
  const apiUrl = `${process.env.REACT_APP_API_E_DOMAIN}assets/get-schools?city_code=${city}&district_code=${district}`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}

export function createProfile(data) {
  const formData = new FormData();
  if (data.photo_file) {
    formData.append('photo_file', data.photo_file);
  }
  if (data.cmnd_img_before_file) {
    formData.append('cmnd_img_before_file', data.cmnd_img_before_file);
  }
  if (data.cmnd_img_after_file) {
    formData.append('cmnd_img_after_file', data.cmnd_img_after_file);
  }
  formData.append('name', data.name);
  formData.append('code', data.code);
  formData.append(
    'data',
    JSON.stringify({
      ...data.data,
      ...ktGetTrackingData(),
    }),
  );
  if (data.id) {
    formData.append('id', data.id);
  }

  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/save`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  }).then((response) => response);
}

export function getProfiles() {
  const apiUrl = `${config.getApiDomain()}/profile`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  }).then((response) => response);
}
export function deleteProfile(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/delete`,
    data: QS.stringify({ id }),
  }).then((response) => response);
}
export function viewProfile(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/view`,
    data: QS.stringify({ id }),
  }).then((response) => response);
}
export function bookmarkExam(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/bookmark-exam`,
    data: QS.stringify({ id }),
  }).then((response) => response);
}

export function processOcr(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/ocr`,
    data: QS.stringify(data),
  }).then((response) => response);
}
