import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { Image } from 'antd';
import loading from 'themes/default/loading.svg';
import logo from 'themes/default/logo-short.svg';

class Loading extends Component {
  render() {
    return (
      <div className="guide-layout">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={{ zIndex: 1999 }}
        />
        <Container fluid>
          <Row className="">
            <Col xs="12" lg="12">
              <Image preview={false} src={logo} className="mb-2" />
              <br />
              {this.props.error == true ? (
                <span className="mt-4">
                  Xin lỗi. Truy cập không hợp lệ. Hệ thống sẽ tự động chuyển
                  hướng
                </span>
              ) : (
                <img height={50} src={loading} alt="" />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Loading;
