import React, { Component } from 'react';
import { Image, Button, message, Modal, Tag, Popover, Space } from 'antd';
import {
  FormOutlined,
  CloseCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import 'moment/locale/vi';
import vnu_hcm from '../../../assets/img/theme/vnu-hcm.png';
import { deleteProfile } from '../service';
import { formatVndMoney, getErrorMessages } from '../../../app/utils/functions';
import CustomContainer from './CustomContainer';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
class TemplateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  quickView = () => {
    if (this.props.quickView) {
      return this.props.quickView(this.props.item);
    }
  };

  selectType = (link) => {
    if (this.props.chooseProfile) {
      return this.props.chooseProfile(this.props.item);
    }
  };

  delete = async (item) => {
    const response = await deleteProfile(item.id);
    if (response.data.code == 200) {
      this.setState(
        {
          modal: !this.state.modal,
        },
        () => {
          if (this.props.refreshData) {
            this.props.refreshData();
          }

          return message.success(response.data.message);
        },
      );
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  hideDeleteConfirm = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  getItemData() {
    try {
      if (typeof this.props.item.data === 'object') {
        return this.props.item.data;
      }

      return this.props.item.data ? JSON.parse(this.props.item.data) : {};
    } catch (error) {
      return {};
    }
  }

  renderBillingInfo() {
    if (!this.props.item || !this.props.item.billing) {
      return <></>;
    }

    const billing = this.props.item.billing;
    if (billing.status == 1) {
      return (
        <Popover
          content={
            <>
              Mã đơn hàng:&nbsp;
              <b>
                FIS.TSD-{billing.id}-{billing.payment_id}
                <br />
              </b>
              Cổng thanh toán:&nbsp;
              {get(billing, 'transaction_data.paygate') ||
                billing.payment_method_code}
              <br />
              Tạo lúc:&nbsp;
              {moment(billing.invoice_created_ts).format('DD/MM/YYYY HH:mm')}
              <br />
              {billing.invoice_paid_ts && (
                <>
                  Thanh toán lúc:&nbsp;
                  {moment(billing.invoice_paid_ts).format('DD/MM/YYYY HH:mm')}
                  <br />
                </>
              )}
              Số tiền thanh toán:&nbsp;
              {formatVndMoney(billing.invoice_total_amount)}
            </>
          }
        >
          <Tag color="#4DD077">
            Đã thanh toán <EyeOutlined />
          </Tag>
        </Popover>
      );
    }

    return (
      <Popover
        content={
          <>
            Mã thanh toán: <b>{billing.payment_code}</b>
            <br />
            Loại hình thanh toán: <b>-</b>
            <br />
            Tạo lúc:{' '}
            {moment(billing.invoice_created_ts).format('DD/MM/YYYY HH:mm')}
            <br />
            Số tiền cần thanh toán:{' '}
            {formatVndMoney(billing.invoice_total_amount)}
            <br />
            <b>Ghi chú:</b> Nếu bạn muốn thanh toán trực tuyến
            <br />
            hãy chọn hồ sơ này và bấm{' '}
            <b>
              <i>"Tiếp theo"</i>
            </b>
          </>
        }
      >
        <Tag color="#FF7A45">
          Thanh toán tiếp <EyeOutlined />
        </Tag>
      </Popover>
    );
  }

  render() {
    const itemData = this.getItemData();

    return (
      <div
        className={
          `${this.props.selected == this.props.item.id ? 'selected' : ''}` +
          ' flex cursor align-items-center temp-item'
          // ' flex cursor align-items-center temp-item mt-3'
        }
      >
        <CustomContainer
          useHref={this.props.item.useHref}
          url={this.props.item.link}
          onSelect={this.props.chooseProfile ? this.selectType : null}
        >
          <img
            className="profile-ava mr-1"
            src={itemData?.photo ? itemData.photo : vnu_hcm}
            alt=""
          />
        </CustomContainer>

        <div className="title-profile">
          <CustomContainer
            useHref={this.props.item.useHref}
            url={this.props.item.link}
            onSelect={this.props.chooseProfile ? this.selectType : null}
          >
            <Space direction="vertical" size={1}>
              <b>
                [{this.props.item.code}] - {this.props.item.name}
              </b>
              {itemData.name && <span>{itemData.name}</span>}
              {!isEmpty(itemData.email) && <span>Email: {itemData.email}</span>}
              {!isEmpty(itemData.cmnd) && (
                <span>CCCD/CMND: {itemData.cmnd}</span>
              )}
              {!isEmpty(itemData.birthday) && (
                <span>
                  Ngày sinh:{' '}
                  {moment(itemData.birthday, 'MM-DD-YYYY').format('DD/MM/YYYY')}
                </span>
              )}
              {this.props.item.billing != null && (
                <span>{this.renderBillingInfo()}</span>
              )}
            </Space>
          </CustomContainer>
        </div>
        {this.props.edit && (
          <CustomContainer
            useHref={this.props.item.useHref}
            url={this.props.item.link}
          >
            <FormOutlined className="ml-2 f-s-md" />
          </CustomContainer>
        )}
        {/*   {this.props.edit && (
          <CloseCircleOutlined
            onClick={this.hideDeleteConfirm}
            className="ml-2 color-danger f-s-md"
          />
        )} */}
        {this.props.view && (
          <EyeOutlined
            onClick={() => this.quickView()}
            className="f-s-md btn-eye-view"
          />
        )}
        <Modal
          visible={this.state.modal}
          onOk={this.hideDeleteConfirm}
          onCancel={this.hideDeleteConfirm}
          footer={null}
          keyboard
          maskClosable
          closable
          centered
          className="modal-setting-info"
          closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
        >
          <div className="btn-block text-center">
            <Image
              className="mb-3 profile-ava"
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 80,
              }}
              src={vnu_hcm}
              preview={false}
            />
            <p>
              Bạn chắc chắn muốn xóa hồ sơ{' '}
              <span className="secondary">{this.props.item.name || ''}</span>
            </p>
            <Button
              type="primary"
              className="btn-radius-4 mr-2"
              onClick={() => this.delete(this.props.item)}
            >
              Xóa
            </Button>
            <Button
              className="btn-radius-4"
              type="default"
              onClick={this.hideDeleteConfirm}
            >
              Bỏ qua
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default TemplateItem;
