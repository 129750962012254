import React, { Component } from 'react';
import { Image } from 'antd';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import img_teacher from 'themes/default/svg/teaching.svg';
import RequestFormClass from './ClassFormRequest';

class ClassPageRequest extends Component {
  state = {};

  showDrawer = (type, code) => {
    if (this.props.showDrawer) {
      this.props.showDrawer(3, code);
    }
  };

  render() {
    return (
      <Row>
        <Col xs="24" md="6" className="">
          <div className="p-1 text-center">
            <Image src={img_teacher} preview={false} />
          </div>
        </Col>
        <Col xs="24" md="6" className="text-auto">
          <div className="request-right text-center">
            <h2>
              Xin chào,{' '}
              {this.props.user.fullname
                ? this.props.user.fullname
                : this.props.user.username}
            </h2>
            <RequestFormClass showDrawer={this.showDrawer} />
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ClassPageRequest);
