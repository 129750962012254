import React from 'react';
import { Form, Select } from 'antd';
import priority_areas from './priority_areas';
import { filterSelectOptions } from 'app/utils/functions';

const SelectPriorityArea = React.memo(({ name, disabled }) => {
  return (
    <Form.Item
      label={<span>Khu vực ưu tiên</span>}
      name={name}
      rules={[{ required: true, message: 'Vui lòng chọn Khu vực ưu tiên' }]}
    >
      <Select
        disabled={disabled}
        showSearch
        placeholder="Chọn"
        options={priority_areas}
        notFoundContent="Không tìm thấy kết quả"
        filterOption={filterSelectOptions}
      />
    </Form.Item>
  );
});

export default SelectPriorityArea;
