import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { getAListPartners } from '../service';
import { Table, Button, Form, message, Select, Tag, Input } from 'antd';
import moment from 'moment';
import {
  FieldTimeOutlined,
  SearchOutlined,
  CaretRightOutlined,
  ClearOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { getErrorMessages } from '../../../app/utils/functions';
import { Link } from 'react-router-dom';

const { Option } = Select;
const DEFAULT_VI_DATE_FORMAT = 'DD-MM-YYYY';
const DEFAULT_EN_DATE_FORMAT = 'YYYY-MM-DD';

class ListPartner extends Component {
  baseState = {
    items: [],
    filters: {},
    keyword: '',
    sorter: {},
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    isOpenSearch: true,
    exportLoading: false,
    registerProfileUrls: [],
  };

  state = this.baseState;

  columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '50px',
    },
    {
      title: 'Tên đối tác',
      dataIndex: 'name',
      render: (value, { id }) => {
        return <Link to={`/partners/edit/${id}`}>{value}</Link>;
      },
    },
    {
      title: 'Mã đối tác',
      width: '100px',
      dataIndex: 'service_code',
    },
    {
      title: 'Trạng thái',
      width: '160px',
      dataIndex: 'status',
      render: (value, item) => {
        return (
          <>
            <b>{this.getStatus(value)}</b> <br />
            <p>
              <FieldTimeOutlined
                style={{ fontSize: 12, position: 'relative', top: -2 }}
              />
              &nbsp;
              <span style={{ fontSize: 12 }}>
                {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
              </span>
            </p>
          </>
        );
      },
    },
  ];

  getStatus(status) {
    switch (status) {
      case 0:
        return <Tag color="#faad14">Tạm dừng</Tag>;
      case 1:
        return <Tag color="#52c41a">Hoạt động</Tag>;
      default:
        return <Tag color="#ff7875">N/A</Tag>;
    }
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.getListPartners(this.getRequestParams({ pagination }));
  }

  getRequestParams({ pagination, filters = {}, sorter = {} }) {
    const request = {
      order_by: sorter.field,
      order_dir: sorter.order,
      page: pagination.current,
      page_size: pagination.pageSize,
      sorter: {
        ...this.state.sorter,
        ...(sorter || this.state.sorter),
      },
      keyword: this.state.keyword.trim() ? this.state.keyword : undefined,
      ...filters,
    };

    if (request.invoice_paid_ts_from) {
      request.invoice_paid_ts_from = moment(
        request.invoice_paid_ts_from,
        DEFAULT_VI_DATE_FORMAT,
      ).format(DEFAULT_EN_DATE_FORMAT);

      request.invoice_paid_ts_to = moment(
        request.invoice_paid_ts_to,
        DEFAULT_VI_DATE_FORMAT,
      ).format(DEFAULT_EN_DATE_FORMAT);
    }

    return request;
  }

  async getListPartners(params = {}, isReplace = false) {
    this.setState({ loading: true });

    const res = await getAListPartners(params);

    if (res.data.code === 200) {
      this.setState({
        loading: false,
        pagination: {
          current: params.page,
          pageSize: params.page_size,
          total: res.data.data.total_count,
        },
        sorter: {
          ...this.state.sorter,
          ...(params.sorter || this.state.sorter),
        },
        items: isReplace
          ? res.data.data.items
          : [...this.state.items, ...res.data.data.items],
      });
    } else {
      return message.error(getErrorMessages(res.data));
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters },
      },
      () =>
        this.getListPartners(
          this.getRequestParams({
            pagination,
            filters: this.state.filters,
            sorter,
          }),
        ),
    );
  };

  resetTable() {
    this.setState({
      ...this.baseState,
      isOpenSearch: this.state.isOpenSearch,
      registerProfileUrls: this.state.registerProfileUrls,
    });
    const { pagination } = this.state;
    this.getListPartners(this.getRequestParams({ pagination }));
  }

  toggleFilters() {
    this.setState({ isOpenSearch: !this.state.isOpenSearch });
  }

  tableReload() {
    const { pagination, filters, sorter } = this.state;

    this.getListPartners(
      this.getRequestParams({
        pagination,
        filters,
        sorter,
      }),
      true,
    );
  }

  render() {
    const {
      items,
      pagination,
      loading,
      isOpenSearch,
      filters: { status, keyword },
    } = this.state;

    return (
      <Container className="page-exam">
        <Row>
          <Col xs={12}>
            <div className="social__header">
              <div className="social__title">
                <span className="title-classes title-classes-light">
                  Đối tác&nbsp;
                  {pagination.total > 0 ? (
                    <span>({pagination.total})</span>
                  ) : (
                    ''
                  )}
                </span>
                <div className="action-group-right">
                  <Link to="/partners/create">
                    <Button icon={<PlusCircleOutlined />} />
                  </Link>
                  &nbsp;
                  <Button
                    onClick={() => this.resetTable()}
                    icon={<ClearOutlined />}
                  />
                  &nbsp;
                  <Button
                    onClick={() => this.toggleFilters()}
                    icon={
                      isOpenSearch ? <CaretRightOutlined /> : <SearchOutlined />
                    }
                  >
                    {isOpenSearch ? 'Thu gọn' : 'Tìm kiếm'}
                  </Button>
                </div>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={isOpenSearch ? 9 : 12}>
            <Table
              bordered
              columns={this.columns}
              rowKey={(record) => record.id}
              dataSource={items}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </Col>
          {isOpenSearch && (
            <Col
              xs={3}
              style={{
                border: '1px solid #f0f0f0',
                backgroundColor: '#fff',
                paddingTop: 16,
              }}
            >
              <Form layout="vertical">
                <Form.Item label="Tìm kiếm:">
                  <Input
                    value={keyword || ''}
                    onChange={(e) => {
                      this.setState(
                        {
                          items: [],
                          filters: {
                            ...this.state.filters,
                            keyword: e.target.value ? e.target.value : '',
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="Trạng thái:">
                  <Select
                    value={status || ''}
                    onChange={(value) => {
                      this.setState(
                        {
                          items: [],
                          filters: {
                            ...this.state.filters,
                            status: value ? value : '',
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  >
                    <Option value=""> -- Tất cả -- </Option>
                    <Option value="1">Hoạt động</Option>
                    <Option value="0">Tạm dừng</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ListPartner);
