// ROOT_PATHS sẽ bị đè lại trong các /src/themes/THEME_NAME/paths.js
const ROOT_PATHS = {
  login: '/dang-nhap',
  register: '/dang-ky',
  exam: '/dk',
  examDetail: '/dk/:examCode',
  examDetailContinue: '/dk/:examCode/:id',
  changePassword: '/doi-mat-khau',
  listExam: '/dang-ky-thi',
  classes: '/lop-hoc',
  classesDetail: '/lop-hoc/:id',
  testTaker: '/bai-thi',
  testTakerDetail: '/bai-thi/:id',
  profile: '/tai-khoan',
  connective: '/lien-ket',
  reference: '/r/:referenceId',
  tablePoint: '/tai-khoan/bang-diem',
  registerTestSite: '/dang-ky-dia-diem-thi/:url',
};

export default ROOT_PATHS;
