import React from 'react';
import logo from 'themes/default/logo-full.svg';
import { Row, Col, Button } from 'antd';
import ListExamBody from '../Components/ListExamBody';
import { connect } from 'react-redux';
import styles from './Exam.module.less';
import { Link, Redirect } from 'react-router-dom';
import PATHS from 'themes/paths';
import { UserOutlined } from '@ant-design/icons';

const ExamHome = ({ isGuest }) => {
  if (!isGuest) {
    return <Redirect to={PATHS.listExam} />;
  }

  return (
    <div className={styles.main}>
      <Row className={styles.container}>
        <Col xs={24} className="mt-6">
          <div className="text-center">
            <img className="logo-kt mb-4 mt-4" alt="..." src={logo} />
          </div>
          <div className={styles.body}>
            <Row gutter={16} justify="start" align="middle">
              <Col xs={24} md={18}>
                <div className={styles.title}>
                  <h3 style={{ margin: 0 }}>Đăng ký thi</h3>
                </div>
              </Col>
              <Col xs={24} md={6} className={styles['title-left']}>
                {isGuest && (
                  <Row className={styles.login} align="middle" justify="center">
                    <UserOutlined />
                    Thí sinh&nbsp;
                    <Link to={PATHS.login}>
                      Đăng nhập
                      {/* <Button type="secondary">Đăng nhập</Button> */}
                    </Link>
                  </Row>
                )}
                {!isGuest && (
                  <>
                    Vào &nbsp;
                    <Link to={PATHS.login}>
                      <Button type="secondary">Tài khoản của tôi</Button>
                    </Link>
                  </>
                )}
              </Col>
            </Row>
            <div className={styles.section}>
              <ListExamBody isGuest={isGuest} showCopyRight={true} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGuest: Object.keys(state.user).length === 0,
});

export default connect(mapStateToProps)(ExamHome);
