import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Upload, message, Button, Space } from 'antd';
import { SyncOutlined, UploadOutlined } from '@ant-design/icons';

const TablePointImages = ({
  form,
  initialValues,
  tablePointImages,
  setTablePointImages,
  grades = [],
}) => {
  const [state, updateState] = useState(tablePointImages);

  useEffect(() => {
    setTablePointImages && setTablePointImages(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    updateState(tablePointImages);
  }, [tablePointImages]);

  const setState = (values) => {
    updateState({ ...state, ...values });
  };

  if (!grades.length) {
    return <Row>Chưa cấu hình</Row>;
  }

  const processPhoto = (input, name) => {
    const imageFile = input.file;
    if (imageFile) {
      if (
        !imageFile.type ||
        !['image/jpeg', 'image/jpg', 'image/png'].includes(
          imageFile.type.toLowerCase(),
        )
      ) {
        message.error('Vui lòng chọn ảnh PNG hoặc JPG');
        setState({
          [name]: null,
        });

        return;
      }

      const isLt2M = imageFile.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error('Vui lòng chọn ảnh không quá 2MB!');

        return null;
      } else {
        return {
          photo: URL.createObjectURL(imageFile.originFileObj),
          photoFile: imageFile.originFileObj,
        };
      }
    }
  };

  return (
    <>
      <div className="legend">Tải lên minh chứng (Ảnh chụp học bạ)</div>
      <Row gutter={16}>
        {grades.map((grade) => {
          const name = `table-point-image-${grade}`;

          return (
            <Col xs={24} md={8} key={grade}>
              <Form.Item
                required
                name={name}
                label={`Tải ảnh minh chứng lớp ${grade}`}
                tooltip={`(Ảnh chụp toàn bộ trang kết quả học tập lớp ${grade})`}
                rules={[
                  {
                    required: true,
                    message: `Vui lòng tải ảnh minh chứng Lớp ${grade}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        value &&
                        value.file &&
                        value.file.size / 1024 / 1024 > 2
                      ) {
                        return Promise.reject(
                          new Error('Vui lòng chọn ảnh không quá 2MB!'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Upload
                  name={name}
                  listType="picture"
                  showUploadList={false}
                  fileList={[]}
                  onChange={(info) => {
                    setState({
                      [name]: processPhoto(info, name),
                    });
                  }}
                  accept=".png, .jpg, .jpeg"
                  customRequest={(info) => {
                    // Plz don't remove empty function.
                  }}
                >
                  {state[name]?.photo || initialValues[name] ? (
                    <Space direction="vertical" size="large">
                      <img
                        src={state[name]?.photo || initialValues[name]}
                        alt="avatar"
                        style={{ maxWidth: '100%' }}
                      />
                      <Button type="primary" icon={<SyncOutlined />}>
                        Đổi ảnh
                      </Button>
                    </Space>
                  ) : (
                    <div>
                      <Button type="primary" icon={<UploadOutlined />}>
                        Tải ảnh minh chứng (Lớp {grade})
                      </Button>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default TablePointImages;
