import React from 'react';
import loadingSvg from 'themes/default/loading.svg';

const KTLoading = ({ showLoading = true, loadingText = 'Đang tải' }) => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <img alt="" src={loadingSvg} style={{ width: 40 }} />
      <br />
      {showLoading && <i>{loadingText}</i>}
    </div>
  );
};

export default KTLoading;
