/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, message } from 'antd';
import { Row, Col } from 'reactstrap';
import findByEmail from 'themes/default/svg/find-by-email.svg';
import TemplateItem from '../../../Profile/Screens/TemplateItem';

import { get } from 'lodash';
import ExamCard from 'modules/Exam/Components/RegisterExamCard';
import { TsaRegisterContextService } from './TsaRegisterContext';
import useTsaRegisterDomain from './TsaRegisterDomain';

const Step1Profile = ({ ...props }) => {
  const [state, TsaRegisterDispatcher] = TsaRegisterContextService();
  const domain = useTsaRegisterDomain();
  const { loading, profile, billing, exam } = state;
  return (
    <>
      <div className="section section-step1-header"></div>
      <div className={`section-body-rg-vnu`}>
        <Row className="section">
          <Col xs="12" sm="12" md="6" lg="6" xl="6">
            <ExamCard exam={exam} isGuest={false} showActions={false} />
          </Col>
          {!!profile && !loading && (
            <Col
              className="flex"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              key={profile?.id}
              style={{ marginBottom: 8 }}
            >
              <TemplateItem
                quickView={domain.quickView}
                view
                profile={domain.profile}
                item={profile}
              />
            </Col>
          )}

          {!!profile &&
            (billing == null || billing.status != 1) &&
            get(profile, 'billing.status') != 1 && (
              <p className="text-center mt-2 danger">
                Bạn đã có hồ sơ dự thi, vui lòng kiểm tra kỹ thông tin trước khi
                tiếp tục.
              </p>
            )}

          {!profile && (
            <div className="text-center">
              <img
                width={350}
                className="logo-kt"
                alt="..."
                src={findByEmail}
              />
              <div className="mb-3 mt-3">
                Bạn chưa có hồ sơ thích hợp.
                <br />
                Bạn vui lòng tạo hồ sơ để đăng ký nhé!
              </div>

              <Link
                to={{
                  pathname: `/profile/${exam.profile_type.toLowerCase()}`,
                  returnUrl: props.history.location.pathname,
                }}
                onClick={(e) => {
                  if (!props.user.email_verified) {
                    message.error(
                      'Bạn cần thực hiện xác thực email trước khi đăng ký',
                    );
                    e.preventDefault();

                    return;
                  }
                }}
              >
                <Button type="primary" className="b-r-4 btn-create-vnu">
                  Tạo hồ sơ
                </Button>
              </Link>
            </div>
          )}
        </Row>
      </div>
    </>
  );
};

export default Step1Profile;
