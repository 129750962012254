import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { formatProfile } from '../../../app/utils/functions';
import {
  searchUserByCode,
  requestUser,
  listRequestUser,
  deleteConnective,
  addConnective,
} from '../service';
import ProfileItem from '../Components/ProfileItem';

class Connective extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.activeTab
          ? this.props.location.state.activeTab.toString()
          : '1',
      openRequest: false,
      collapse: false,
      display: 1,
      code: '',
      user: '',
      onSearch: false,
      lstConnected: [],
      lstRequest: [],
      lstInvited: [],
      popoverOpen: false,
      lstUser: [],
    };
  }

  togglePop = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  openRequest = () => {
    const { openRequest } = this.state;
    this.setState({ openRequest: !openRequest });
  };

  collapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  changeView(tab) {
    this.setState({
      display: tab,
    });
  }

  onFormChange = (event) => {
    let { code } = this.state;
    code = event.target.value;
    this.setState({ code });
  };

  onFormSubmit = async () => {
    const { code } = this.state;
    this.setState({ onSearch: true });
    if (!code) {
      return toast.error('Bạn chưa nhập mã hồ sơ cần liên kết');
    }
    const response = await searchUserByCode(code);
    if (response.data.code == 200) {
      this.setState({ user: response.data.data });
    } else {
      // toast.error(response.data.message);
      this.setState({ user: '' });
    }
  };

  onSubmitRequest = async () => {
    const { user } = this.state;
    if (!user) {
      return toast.error('Không tìm thấy hồ sơ cần liên kết');
    }
    const response = await requestUser(user.id);
    if (response.data.code == 200) {
      // this.setState({user: response.data.data});
      toast.success('Liên kết hồ sơ thành công.');
      this.getlistRequestUser();
    } else {
      toast.error(response.data.message);
    }
  };

  getlistRequestUser = async () => {
    const response = await listRequestUser(this.props.user.id);
    if (response.data.code == 200) {
      this.setState({
        lstRequest: response.data.data.request,
        lstConnected: response.data.data.connected,
        lstInvited: response.data.data.invited,
        lstUser: response.data.data.lst_user,
      });
    }
  };

  componentDidMount() {
    if (this.props.user && this.props.user.id) {
      this.getlistRequestUser();
    }

    if (this.props.location && this.props.location.pathname) {
      localStorage.setItem('home_url', this.props.location.pathname);
    }
  }

  delConnective = async (id) => {
    const response = await deleteConnective(id);
    if (response.data.code == 200) {
      toast.success('Gỡ liên kết hồ sơ thành công.');
      this.getlistRequestUser();
    } else {
      toast.error(response.data.message);
    }
  };

  confirmInvited = async (id) => {
    const response = await addConnective(id);
    if (response.data.code == 200) {
      toast.success('Liên kết hồ sơ thành công.');
      this.getlistRequestUser();
    } else {
      toast.error(response.data.message);
    }
  };

  render() {
    const { lstUser, lstConnected } = this.state;
    const lstConnected2 = lstConnected.filter(
      (item) => item.id_from == this.props.user.id,
    );

    return (
      <Container>
        <Row>
          <Col span={24}>
            <div className="social__header">
              <div className="social__title">
                <span className="title-classes">
                  Tài khoản bạn đang theo dõi{' '}
                </span>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col>
        </Row>
        <Row>
          {lstConnected2.length > 0 ? (
            lstConnected2.map((item) => {
              item = formatProfile(lstUser, item, this.props.user.id);

              return (
                <Col xs="12" md="4" lg="3" className="mb-4" key={item.id}>
                  <ProfileItem
                    item={item}
                    type={1}
                    confirmInvited={this.confirmInvited}
                  />
                </Col>
              );
            })
          ) : (
            <div className="p-1 text-center">
              <i> Không có dữ liệu</i>
            </div>
          )}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Connective);
