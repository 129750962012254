import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { message, DatePicker, Button } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { getErrorMessages } from '../../../app/utils/functions';
import { saveUser } from '../service.js';
import { get, pick } from 'lodash';
import store from 'app/store';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

class ProfileFormInfo extends Component {
  constructor(props) {
    super(props);

    const birthday = get(props, 'user.birthday', '');
    this.state = {
      updateProfileLoading: false,
      tooltipOpen: false,
      lstConnected: [],
      activeTab: '1',
      openRequest: false,
      collapse: false,
      display: 1,
      editUserObj: {
        ...(props.user || {}),
        birthday: birthday ? moment(birthday).format('DD/MM/YYYY') : '',
      },
      userError: {
        fullname: false,
        birthday: false,
      },
    };
  }

  onFormChange = (event) => {
    const { editUserObj } = this.state;
    editUserObj[event.target.name] = event.target.value;
    this.setState({
      editUserObj,
    });
  };

  onFormSubmit = async () => {
    if (!this.props.user) {
      return;
    }
    this.setState({ updateProfileLoading: true });
    const data = pick(
      {
        ...this.state.editUserObj,
      },
      ['fullname', 'birthday', 'phone', 'address', 'school'],
    );
    // if(!data.fullname){
    //   userError.fullname = true;
    //   this.setState({userError});
    //   return message.error('Bạn cần nhập họ tên');
    // }
    // if(!data.birthday){
    //   userError.birthday = true;
    //   this.setState({userError});
    //   return message.error('Bạn cần nhập ngày sinh');
    // }
    if (data.birthday) {
      data.birthday = moment(data.birthday, dateFormatList[0]).format(
        'YYYY-MM-DD',
      );
    }
    const response = await saveUser(this.props.user.id, data);

    if (response.data.code == 200) {
      message.success('Bạn đã cập nhật thông tin thành công');
      store.dispatch({ type: 'UPDATE_USER', user: response.data.data.user });
      this.setState({
        userError: {
          fullname: false,
          birthday: false,
        },
      });
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    } else {
      this.setState({
        userError: response.data.errors || {},
      });
      if (
        typeof response.data.errors != 'undefined' &&
        Object.keys(response.data.errors).length > 0
      ) {
        Object.values(response.data.errors).forEach((element) => {
          message.error(element);
        });
      } else {
        message.error(getErrorMessages(response.data));
      }
    }
    this.setState({ updateProfileLoading: false });
  };

  componentDidMount() {
    if (!this.props.user) {
      message.error('Có lỗi xảy ra!');
    } else {
      this.setState(
        {
          isEdit: true,
        },
        () => {
          if (!this.props.user.fullname) {
            message.warning('Hãy cập nhật thông tin hồ sơ');
          }
        },
      );
    }
  }

  onChangeDatePicker = (date, dateString) => {
    const { editUserObj } = this.state;
    editUserObj.birthday = dateString;
    this.setState({
      editUserObj,
    });
  };

  render() {
    const { display } = this.props;

    return (
      <Row>
        <Col lg="12" sm="12">
          <FormGroup className="row">
            <Col
              lg={display == 'inline' ? 3 : 12}
              sm="12"
              className="ant-form-item-label"
            >
              <Label for="fullname">
                Họ tên <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col
              lg={display == 'inline' ? 9 : 12}
              sm="12"
              className={display == 'inline' ? '' : 'pd-r-0'}
            >
              <Input
                type="text"
                name="fullname"
                id="fullname"
                placeholder=""
                required
                value={this.state.editUserObj.fullname || ''}
                onChange={this.onFormChange}
                invalid={!!this.state.userError.fullname}
              />
            </Col>
          </FormGroup>
        </Col>
        {/*         <Col lg="12" sm="12">
          <FormGroup className="row">
            <Col
              lg={display == 'inline' ? 3 : 12}
              sm="12"
              className="ant-form-item-label"
            >
              <Label for="fullname">
                Ngày sinh <span className="text-danger">*</span>
              </Label>
            </Col>
            <Col
              lg={display == 'inline' ? 9 : 12}
              sm="12"
              className={display == 'inline' ? '' : 'pd-r-0'}
            >
              <DatePicker
                className={
                  this.state.userError.birthday ? 'is-invalid-date' : ''
                }
                placeholder={this.state.editUserObj.birthday || ''}
                onChange={this.onChangeDatePicker}
                defaultValue=""
                format={dateFormatList}
              />
            </Col>
          </FormGroup>
        </Col> */}
        <Col lg="12" sm="12">
          <FormGroup className="row">
            <Col
              lg={display == 'inline' ? 3 : 12}
              sm="12"
              className="ant-form-item-label"
            >
              <Label for="fullname">Trường học </Label>
            </Col>
            <Col
              lg={display == 'inline' ? 9 : 12}
              sm="12"
              className={display == 'inline' ? '' : 'pd-r-0'}
            >
              <Input
                type="text"
                name="school"
                id="school"
                placeholder=""
                value={this.state.editUserObj.school || ''}
                onChange={this.onFormChange}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col lg="12" sm="12">
          <FormGroup className="row">
            <Col
              lg={display == 'inline' ? 3 : 12}
              sm="12"
              className="ant-form-item-label"
            >
              <Label for="fullname">Số điện thoại </Label>
            </Col>
            <Col
              lg={display == 'inline' ? 9 : 12}
              sm="12"
              className={display == 'inline' ? '' : 'pd-r-0'}
            >
              <Input
                type="number"
                name="phone"
                id="phone"
                placeholder=""
                value={
                  this.state.editUserObj.phone
                    ? this.state.editUserObj.phone
                    : ''
                }
                onChange={this.onFormChange}
                invalid={!!this.state.userError.phone}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col lg="12" sm="12">
          <FormGroup className="row">
            <Col
              lg={display == 'inline' ? 3 : 12}
              sm="12"
              className="ant-form-item-label"
            >
              <Label for="fullname">Địa chỉ </Label>
            </Col>
            <Col
              lg={display == 'inline' ? 9 : 12}
              sm="12"
              className={display == 'inline' ? '' : 'pd-r-0'}
            >
              <Input
                type="text"
                name="address"
                id="address"
                placeholder=""
                value={this.state.editUserObj.address || ''}
                onChange={this.onFormChange}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col
          lg={display == 'inline' ? { size: 9, offset: 3 } : { size: 12 }}
          sm="12"
          className={display == 'inline' ? '' : 'text-right pd-r-0'}
        >
          <Button
            className="btn-form"
            type="primary"
            loading={this.state.updateProfileLoading}
            block
            onClick={this.onFormSubmit}
          >
            <i className="fa fa-save mr-1" aria-hidden="true" />
            Cập nhật thông tin
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileFormInfo);
