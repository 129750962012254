import _ from 'lodash';
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { ktSaveTrackingDataFromLocation } from './kt-ga-fn';

const trackingId = process.env.REACT_APP_GA_CODE || '';
if (!_.isEmpty(trackingId)) {
  ReactGA.initialize(trackingId);
}

const KTGaListener = ({ children }) => {
  const location = useLocation();

  // Fired on every route change
  useEffect(() => {
    if (_.isEmpty(trackingId) || _.isEmpty(location.pathname)) {
      return;
    }
    ReactGA.pageview(location.pathname);
    ktSaveTrackingDataFromLocation(location);
  }, [location]);

  return <>{children}</>;
};

export default KTGaListener;
