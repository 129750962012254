import React, { Component } from 'react';
import { Card, Button, Modal } from 'antd';
import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../../../app/config';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { Meta } = Card;

class ProfileItem extends Component {
  state = {};

  delConnective = (id) => {
    if (this.props.delConnective) {
      this.props.delConnective(id);
    }
  };

  confirmInvited = (id) => {
    if (this.props.confirmInvited) {
      this.props.confirmInvited(id);
    }
  };

  openRequest = (item) => {
    const self = this;
    confirm({
      className: 'type-profile',
      centered: true,
      title: (
        <>
          Bạn có chắc chắc muốn&nbsp;
          <span className="secondary">
            "{this.getDelConnectiveTitle(item)}"
          </span>
          &nbsp;với&nbsp;
          <span className="secondary">"{item.name || item.username}"</span>?
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      // content: [<p>Bạn có chắc chắc muốn <b>"{this.getDelConnectiveTitle(item)}"?</b></p>],
      cancelText: 'Bỏ qua',
      okText: 'OK',
      okType: 'danger',
      onOk() {
        self.delConnective(item.id);
      },
      onCancel() {},
    });
  };

  getDelConnectiveTitle(item) {
    return item.status == 1
      ? 'Hủy liên kết'
      : item.created_by == this.props.user.id
      ? 'Hủy yêu cầu'
      : 'Từ chối';
  }

  render() {
    const { item, type } = this.props;

    return (
      <div className="text-center">
        <Card className="x-card">
          <Media
            key={Math.random()}
            className="img-fluid rounded-circle shadow-sm avatar-img mb-2"
            object
            src={item.avatar ? item.avatar : config.getAvatarDefault()}
          />
          <Meta
            className="text-center"
            title={
              type == 1 && item.id_from == this.props.user.id ? (
                <Link
                  color="primary"
                  to={`/profile/${item.id_to}`}
                  className="px-0 mr-2 primary"
                >
                  {item.fullname ? item.fullname : item.username}
                </Link>
              ) : item.fullname ? (
                item.fullname
              ) : (
                item.username
              )
            }
            description={
              <>
                {item.status != 1 && (
                  <span className="mb-2">
                    {item.id_from == this.props.user.id
                      ? item.created_by == this.props.user.id
                        ? 'Đề nghị theo dõi'
                        : 'Chia sẻ dữ liệu với bạn'
                      : item.created_by == this.props.user.id
                      ? 'Chia sẻ dữ liệu'
                      : 'Đề nghị theo dõi bạn'}
                  </span>
                )}
                <div className="flex text-center mt-1">
                  {type == 2 && (
                    <Button
                      type="primary"
                      className="b-r-4 mr-2"
                      onClick={() => this.confirmInvited(item.id)}
                    >
                      Đồng ý
                    </Button>
                  )}
                  {this.props.delConnective && (
                    <Button
                      className="b-r-4"
                      onClick={() => this.openRequest(item)}
                    >
                      {this.getDelConnectiveTitle(item)}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileItem);
