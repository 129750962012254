import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import ListExamBody from '../Components/ListExamBody';
import VerifyEmailAlert from '../../User/Components/VerifyEmailAlert';

class ListExam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendMailStatus: 0,
    };
  }

  render() {
    return (
      <Container>
        <Row>
          {!this.props.user.email_verified && (
            <Col xs={12} className="mb-3">
              <VerifyEmailAlert />
            </Col>
          )}
          {/* <Col span={24}>
            <div className="social__header">
              <div className="social__title">
                <div className="title-classes title-classes-light">
                  <span className="title-classes">Bài thi</span>
                </div>
                <div className="action-group-right">
                  <Button type="secondary">
                    <Link to={`${PATHS.testTaker}?type=5`}>
                      Bài thi đã đăng ký
                    </Link>
                  </Button>
                </div>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col> */}
        </Row>

        <div className="social__header">
          <div className="social__title">
            <span className="title-classes title-classes-light">
              Bài thi Đánh giá tư duy - TSA
            </span>
          </div>
          <hr className="line mt-3" />
        </div>

        <ListExamBody emailVerified={this.props.user.email_verified} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ListExam);
