import React, { Component } from 'react';
import { Tooltip, Card, Button, message, Progress, Typography } from 'antd';
import moment from 'moment';
import {
  PushpinOutlined,
  PlayCircleOutlined,
  PoweroffOutlined,
  LockOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import config from 'app/config';
import {
  buildRouter,
  countDownTimer,
  getTimeDifferences,
  timeRemaining,
} from 'app/utils/functions';
import { Link } from 'react-router-dom';
import PATHS from 'themes/paths';
import theme from 'themes';

const { Meta } = Card;
const { Text } = Typography;

class CodeTaker extends Component {
  Copy = () => {
    navigator.clipboard.writeText(this.props.item.code);
    message.success(`Đã copy ${this.props.item.code}`);
  };

  render() {
    return (
      <>
        <Button
          className="mr-2 mb-2 b-r-4 color-light"
          onClick={() => this.Copy()}
        >
          Copy mã
        </Button>
        <Button className="b-r-4 mb-2">
          Mã dự thi:&nbsp;
          {this.props.isShowCode ? (
            <b className="ml-2">{this.props.item.code}</b>
          ) : (
            '******'
          )}
          &nbsp;
          <span
            className="ml-2 secondary"
            onClick={() =>
              this.props.onShowCode
                ? this.props.onShowCode(this.props.item.id)
                : null
            }
          >
            {this.props.isShowCode ? 'Ẩn' : 'Hiện'}
          </span>
        </Button>
      </>
    );
  }
}

class TimeRemaining extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  componentDidMount() {
    this.getTimeRemaining();
  }

  getTimeRemaining = () => {
    const data = timeRemaining(
      this.props.item.started_at,
      this.props.item.to_time,
      this.props.item.time_limit,
    );
    this.setState({ data });
  };

  render() {
    const { data } = this.state;

    return (
      <div className={`${this.props.type == 2 ? 'flex-column' : 'flex'} m-2 `}>
        {data.status ? (
          <>
            <span className="danger message-count-down">
              Còn lại: {data.data}
            </span>
            <Progress percent={data.duration} showInfo={false} />
          </>
        ) : (
          <span className="danger text-center">Đã hết giờ làm bài</span>
        )}
      </div>
    );
  }
}

/**
 * isShowCode
 * onShowCodeClick
 */
class TestTakerItem extends Component {
  bookmarkItem = (id) => {
    if (this.props.bookmarkItem) {
      this.props.bookmarkItem(id);
    }
  };

  getDeliveryUrl(item) {
    let domainE = config.getApiDomainE();
    if (item.delivery_config && item.delivery_config.url) {
      domainE = item.delivery_config.url;
    }
    // if (theme.config.themeName == 'hust') {
    //   return `${domainE}?code=${item.code}&url_callback=${
    //     process.env.REACT_APP_DOMAIN
    //   }${PATHS.testTaker.replace('/', '')}?type=2`;
    // }

    return `${domainE}?code=${item.code}&url_callback=${window.location.href}`;
  }

  countDownTimer(dateTime) {
    const str = countDownTimer(dateTime, this.props.timestamp, 1800000);
    if (str) {
      return <Text type="success"> ({str})</Text>;
    }

    return '';
  }

  // style = gird | line
  renderViewResultBtn(isDisableViewResult, linkViewResult, style) {
    if (theme.config.themeName == 'hust') {
      if (style == 'line') {
        return (
          <>
            {!isDisableViewResult && (
              <a
                href={linkViewResult}
                className="ml-2"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  disabled={isDisableViewResult}
                  className="primary-light b-r-4"
                  type="primary"
                >
                  Xem kết quả
                </Button>
              </a>
            )}
          </>
        );
      } else {
        return (
          <>
            {!isDisableViewResult && (
              <a
                href={linkViewResult}
                className="ant-btn-block"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  block
                  className="primary-light b-r-4"
                  disabled={isDisableViewResult}
                  type="primary"
                >
                  Xem kết quả
                </Button>
              </a>
            )}
          </>
        );
      }
    } else {
      if (style == 'line') {
        return (
          <>
            {!isDisableViewResult && (
              <Link to={linkViewResult} className="ml-2">
                <Button
                  disabled={isDisableViewResult}
                  className="primary-light b-r-4"
                  type="primary"
                >
                  Xem kết quả
                </Button>
              </Link>
            )}
          </>
        );
      } else {
        return (
          <>
            {!isDisableViewResult && (
              <Link to={linkViewResult} className="ant-btn-block">
                <Button
                  block
                  className="primary-light b-r-4"
                  disabled={isDisableViewResult}
                  type="primary"
                >
                  Xem kết quả
                </Button>
              </Link>
            )}
          </>
        );
      }
    }
  }

  render() {
    const {
      item,
      display,
      type,
      customer,
      expiredToday,
      isShowCode,
      onShowCode,
    } = this.props;
    const result = item.result ? JSON.parse(item.result) : { score: '' };
    let isDisableViewResult;
    let linkViewResult;

    const currentTime = this.props.timestamp
      ? moment(this.props.timestamp)
      : moment();

    if (theme.config.themeName == 'hust') {
      isDisableViewResult =
        moment(item.to_time) < currentTime ||
        item.test_taker_group_status == 20;
      linkViewResult = this.getDeliveryUrl(item);
    } else {
      /*
      mở tạm thi xong sẽ xem đc kết quả - sau sẽ check theo config kíp
      isDisableViewResult = !(
        moment(item.to_time) < currentTime || item.test_taker_group_status == 20
      ); */
      linkViewResult = buildRouter(PATHS.testTakerDetail, { id: item.id });
    }

    return (
      <div
        className={`${display == 1 ? 'style-list' : 'style-card'} card-exam`}
      >
        {display == 1 ? (
          <>
            {type == 2 ? (
              <Card className="x-card">
                <div className="block-mobile flex align-center space-between">
                  <Meta
                    avatar={''}
                    title={
                      <Tooltip
                        placement="top"
                        title={item.test_taker_group_name}
                      >
                        {item.test_taker_group_name}
                      </Tooltip>
                    }
                    description={
                      <div>
                        Thời gian nộp bài:{' '}
                        {moment(item.submitted_at).format('DD-MM HH:mm')}
                        <br />
                        Thời gian làm bài:{' '}
                        {getTimeDifferences(
                          moment(item.started_at).format(
                            'MMMM DD, YYYY HH:mm:ss',
                          ),
                          moment(item.submitted_at).format(
                            'MMMM DD, YYYY HH:mm:ss',
                          ),
                        )}
                        <br />
                        Số câu đúng:{' '}
                        {item.correct_answer_count
                          ? `${item.correct_answer_count}/${item.count_item}`
                          : '?'}
                      </div>
                    }
                  />
                  <div className="btn-group-right">
                    {!customer && (
                      <CodeTaker
                        item={item}
                        isShowCode={isShowCode}
                        onShowCode={onShowCode}
                      />
                    )}
                    {this.renderViewResultBtn(
                      isDisableViewResult,
                      linkViewResult,
                      'line',
                    )}
                  </div>
                </div>
              </Card>
            ) : (
              <Card
                className="x-card"
                actions={
                  item.status == 35 ? [<TimeRemaining item={item} />] : []
                }
              >
                <div className="block-mobile flex align-center space-between">
                  <Meta
                    avatar={
                      <div
                        className={`${
                          item.status == 35
                            ? 'bg-success'
                            : expiredToday
                            ? 'bg-warning'
                            : 'bg-secondary'
                        } bage-time`}
                        size={72}
                      >
                        <span className="f-s-l">
                          {item.count_item ? (
                            item.count_item
                          ) : item.time_limit > 0 ? (
                            item.time_limit
                          ) : (
                            <span className="f-s-l">&infin;</span>
                          )}
                        </span>
                        <span>
                          {item.count_item
                            ? 'câu'
                            : item.time_limit > 0
                            ? 'phút'
                            : ''}
                        </span>
                      </div>
                    }
                    title={
                      <Tooltip
                        placement="top"
                        title={item.test_taker_group_name}
                      >
                        {item.test_taker_group_name}
                      </Tooltip>
                    }
                    description={
                      <div>
                        Thời gian làm:{' '}
                        {item.time_limit && item.time_limit > 0
                          ? `${item.time_limit} phút`
                          : 'Không giới hạn'}
                        <br />
                        {type == 1 && (
                          <Tooltip
                            placement="top"
                            title="Thời gian bắt đầu"
                            className="mr-2"
                          >
                            <PlayCircleOutlined className="primary vertical-baseline mr-1" />
                            {item.test_taker_group_status == 13 ||
                            item.test_taker_group_status == 17 ? (
                              item.status == 35 || item.control_mode == 0 ? (
                                'Đã mở'
                              ) : (
                                <>
                                  {moment(item.from_time).format('DD-MM HH:mm')}
                                  {this.countDownTimer(item.from_time)}
                                </>
                              )
                            ) : item.control_mode == 1 ? (
                              <>
                                {moment(item.from_time).format('DD-MM HH:mm')}
                                {this.countDownTimer(item.from_time)}
                              </>
                            ) : (
                              'Đang cập nhật '
                            )}
                          </Tooltip>
                        )}
                        {(type == 1 || type == 3) && (
                          <Tooltip
                            placement="top"
                            title="Thời gian hết hạn rút đề"
                            className=" mr-2 align-center"
                          >
                            <LockOutlined className="warning vertical-baseline mr-1" />
                            <span className={expiredToday ? 'warning' : ''}>
                              {item.test_taker_group_status == 17
                                ? 'Đã hết hạn'
                                : item.control_mode == 1
                                ? moment(item.due_time).format('DD-MM HH:mm')
                                : type == 3
                                ? 'Đã hết hạn'
                                : 'Đang cập nhật '}
                            </span>
                          </Tooltip>
                        )}
                        <Tooltip
                          placement="top"
                          title="Thời gian kết thúc"
                          className="mr-2 align-center"
                        >
                          <PoweroffOutlined className="danger vertical-baseline" />{' '}
                          {moment(item.to_time).format('DD-MM HH:mm')}
                        </Tooltip>
                      </div>
                    }
                  />
                  <div className="btn-group-right">
                    {type == 1 && !customer && (
                      <CodeTaker
                        item={item}
                        isShowCode={isShowCode}
                        onShowCode={onShowCode}
                      />
                    )}
                    {type == 3 && !this.props.hideBoomark && (
                      <PushpinOutlined
                        onClick={() => {
                          this.bookmarkItem(item.id);
                        }}
                        className={`${
                          item.bookmark == 1
                            ? 'bookmark-test-taker-item bookmarked-test-taker-item'
                            : 'bookmark-test-taker-item'
                        }`}
                      />
                    )}

                    {type == 1 && !customer && (
                      <>
                        {/* {item.test_taker_group_status == 1 && (
                          <Tooltip title="Kíp thi chưa mở">
                            <span>
                              <Button
                                type="default"
                                disabled
                                className="b-r-4 ml-2"
                              >
                                Làm bài thi
                              </Button>
                            </span>
                          </Tooltip>
                        )} */}
                        <a
                          className="ml-2"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.getDeliveryUrl(item)}
                        >
                          <Button
                            disabled={
                              currentTime < moment(item.from_time) &&
                              item.control_mode == 1
                            }
                            className={`${
                              item.status == 35
                                ? ''
                                : item.test_taker_group_status == 13
                                ? 'primary-light'
                                : ''
                            } b-r-4`}
                            type={
                              item.status == 35 ||
                              item.test_taker_group_status == 13
                                ? 'primary'
                                : ''
                            }
                          >
                            {item.status == 35
                              ? 'Tiếp tục làm bài'
                              : 'Làm bài thi'}
                          </Button>
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            )}
          </>
        ) : (
          <>
            {type == 2 ? (
              <Card
                className="x-card"
                title={
                  <Tooltip placement="top" title={item.test_taker_group_name}>
                    {item.test_taker_group_name}
                  </Tooltip>
                }
                bordered={false}
                actions={[
                  <div className="m-2">
                    <div className="flex align-center space-between block-mobile">
                      {!customer && (
                        <CodeTaker
                          item={item}
                          isShowCode={isShowCode}
                          onShowCode={onShowCode}
                        />
                      )}
                    </div>
                    {this.renderViewResultBtn(
                      isDisableViewResult,
                      linkViewResult,
                      'gird',
                    )}
                  </div>,
                ]}
              >
                <div>
                  Thời gian nộp bài:{' '}
                  {moment(item.submitted_at).format('DD-MM HH:mm')}
                  <br />
                  Thời gian làm bài:{' '}
                  {getTimeDifferences(
                    moment(item.started_at).format('MMMM DD, YYYY HH:mm:ss'),
                    moment(item.submitted_at).format('MMMM DD, YYYY HH:mm:ss'),
                  )}
                  <br />
                  Số câu đúng:{' '}
                  {item.correct_answer_count
                    ? `${item.correct_answer_count}/${item.count_item}`
                    : '?'}
                  <br />
                  Điểm:{' '}
                  <span className="f-s-l danger">
                    {item.status == 36 ? (
                      item.score ? (
                        item.score
                      ) : (
                        result.score
                      )
                    ) : (
                      <Tooltip placement="top" title="Đang chờ chấm">
                        <QuestionOutlined className="vertical-text-top" />
                      </Tooltip>
                    )}
                  </span>
                </div>
              </Card>
            ) : (
              <Card
                className="x-card"
                title={
                  type == 3 ? (
                    <div className="flex space-between">
                      <span className="card-head-title">
                        <Tooltip
                          placement="top"
                          title={item.test_taker_group_name}
                        >
                          {item.test_taker_group_name}
                        </Tooltip>
                      </span>
                      {!this.props.hideBoomark && (
                        <PushpinOutlined
                          onClick={() => {
                            this.bookmarkItem(item.id);
                          }}
                          className={`${item.bookmark == 1 ? 'secondary' : ''}`}
                        />
                      )}
                    </div>
                  ) : (
                    <Tooltip placement="top" title={item.test_taker_group_name}>
                      {item.test_taker_group_name}
                    </Tooltip>
                  )
                }
                bordered={false}
                actions={
                  type == 1 && !customer
                    ? [
                        <div className="m-2">
                          <div className="flex align-center space-between block-mobile">
                            <CodeTaker
                              item={item}
                              isShowCode={isShowCode}
                              onShowCode={onShowCode}
                            />
                          </div>
                          {/* {item.test_taker_group_status == 1 ||
                            (item.from_time && (
                              <Tooltip title="Kíp thi chưa mở">
                                <span>
                                  <Button
                                    block
                                    type="default"
                                    disabled
                                    className="b-r-4"
                                  >
                                    Làm bài thi
                                  </Button>
                                </span>
                              </Tooltip>
                            ))} */}
                          <a
                            className="ant-btn-block"
                            href={this.getDeliveryUrl(item)}
                          >
                            <Button
                              block
                              disabled={
                                currentTime < moment(item.from_time) &&
                                item.control_mode == 1
                              }
                              className={`${
                                item.status == 35
                                  ? ''
                                  : item.test_taker_group_status == 13
                                  ? 'primary-light'
                                  : ''
                              } b-r-4`}
                              type={
                                item.status == 35 ||
                                item.test_taker_group_status == 13
                                  ? 'primary'
                                  : ''
                              }
                            >
                              {item.status == 35
                                ? 'Tiếp tục làm bài'
                                : 'Làm bài thi'}
                            </Button>
                          </a>
                          {item.status == 35 && (
                            <TimeRemaining item={item} type={2} />
                          )}
                        </div>,
                      ]
                    : []
                }
              >
                <div>
                  Thời gian làm:{' '}
                  {item.time_limit && item.time_limit > 0
                    ? `${item.time_limit} phút`
                    : 'Không giới hạn'}
                  <br />
                  {type == 1 && (
                    <>
                      <Tooltip
                        placement="top"
                        title="Thời gian bắt đầu"
                        className="mr-2"
                      >
                        <PlayCircleOutlined className="primary vertical-baseline mr-1" />
                        {item.test_taker_group_status == 13 ||
                        item.test_taker_group_status == 17 ? (
                          item.status == 35 || item.control_mode == 0 ? (
                            'Đã mở'
                          ) : (
                            <>
                              {moment(item.from_time).format('DD-MM HH:mm')}
                              {this.countDownTimer(item.from_time)}
                            </>
                          )
                        ) : item.control_mode == 1 ? (
                          <>
                            {moment(item.from_time).format('DD-MM HH:mm')}
                            {this.countDownTimer(item.from_time)}
                          </>
                        ) : (
                          'Đang cập nhật '
                        )}
                      </Tooltip>
                      <br />
                    </>
                  )}
                  {(type == 1 || type == 3) && (
                    <>
                      <Tooltip
                        placement="top"
                        title="Thời gian hết hạn rút đề"
                        className=" mr-2 align-center"
                      >
                        <LockOutlined className="warning vertical-baseline mr-1" />
                        <span className={expiredToday ? 'warning' : ''}>
                          {item.test_taker_group_status == 17
                            ? 'Đã hết hạn'
                            : item.control_mode == 1
                            ? moment(item.due_time).format('DD-MM HH:mm')
                            : type == 3
                            ? 'Đã hết hạn'
                            : 'Đang cập nhật '}
                        </span>
                      </Tooltip>
                      <br />
                    </>
                  )}
                  <Tooltip
                    placement="top"
                    title="Thời gian kết thúc"
                    className="mr-2 align-center"
                  >
                    <PoweroffOutlined className="danger vertical-baseline" />{' '}
                    {moment(item.to_time).format('DD-MM HH:mm')}
                  </Tooltip>
                  {/* <span className="mr-1">Bắt đầu: {item.control_mode == 1 ? moment(item.from_time).format("DD-MM HH:mm") : (item.test_taker_group_status==13 || item.test_taker_group_status==17 ? 'Đã mở' : 'Đang cập nhật ')} <br/></span>
                {type == 1 &&<span className={expiredToday ? 'danger' : ''}> Hết hạn: {item.control_mode == 1 ? moment(item.due_time).format("DD-MM HH:mm") : (item.test_taker_group_status==17 ? 'Đã hết hạn' : 'Đang cập nhật ')}
                        <br/></span>}
                         Kết thúc: {moment(item.to_time).format("DD-MM HH:mm")} */}
                </div>
              </Card>
            )}
          </>
        )}
      </div>
    );
  }
}

export default TestTakerItem;
