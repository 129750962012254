import React, { Component } from 'react';
import { Col, Row, Label, Input, FormGroup } from 'reactstrap';
import TextEditor from '../../../Common/TextEditor';
import { getChoiceLabel } from '../../../../app/utils/functions';

class MultipleChoice extends Component {
  constructor(props) {
    super(props);

    this.onItemChoiceEdit = this.onItemChoiceEdit.bind(this);
    this.onResponseChange = this.onResponseChange.bind(this);
  }

  onItemChoiceEdit(event, content, i) {
    this.props.choicesObj[i] = content;
    this.props.onChange({
      choicesObj: this.props.choicesObj,
      response: this.props.response,
    });
  }

  onResponseChange(event, option) {
    if (!event.target.checked) {
      if (this.props.response.indexOf(option) > -1) {
        this.props.response.splice(this.props.response.indexOf(option), 1);
      }
    } else if (this.props.response.indexOf(option) === -1) {
      this.props.response.push(option);
    }
    this.props.onChange({
      choicesObj: this.props.choicesObj,
      response: this.props.response,
    });
  }

  render() {
    const html = [];

    for (let i = 1; i <= 4; i++) {
      const initialValue =
        typeof this.props.choicesObj[i] != 'undefined'
          ? this.props.choicesObj[i]
          : '';
      html.push(
        <FormGroup key={i} row>
          <Col md="3">
            <Label
              className={
                this.props.errorFields[`choice_${i}`] ? 'is-invalid' : ''
              }
              htmlFor="textarea-input"
            >
              Phương án {i} {i < 3 && <span className="is-invalid">(*)</span>}
            </Label>
          </Col>
          <Col xs="12" md="9">
            <TextEditor
              /* eslint react/style-prop-object: "off" */
              style="body {font-size: 14px}"
              id={`choice_${i}`}
              name={`choice_${i}`}
              height={this.props.height || 100}
              value={initialValue}
              onChange={(event, content) =>
                this.onItemChoiceEdit(event, content, i)
              }
            />
          </Col>
        </FormGroup>,
      );
    }
    const optionsArray = [1, 2, 3, 4];
    html.push(
      <FormGroup key={9999999} row>
        <Col md="3">
          <Label
            invalid={this.props.errorFields.response}
            htmlFor="multiple-select"
          >
            Đáp án đúng <span className="is-invalid">(*)</span>
          </Label>
        </Col>
        <Col md="9">
          {optionsArray.map((option, optionIndex) => (
            <FormGroup key={optionIndex} check className="checkbox">
              <Input
                className="form-check-input"
                type="checkbox"
                name={`checkbox[${option}]`}
                checked={this.props.response.indexOf(option) > -1}
                onChange={(event) => this.onResponseChange(event, option)}
              />
              <Label check className="form-check-label" htmlFor="checkbox1">
                Phương án {option}
              </Label>
            </FormGroup>
          ))}
        </Col>
      </FormGroup>,
    );

    return <div>{html}</div>;
  }
}

class MultipleChoicePreview extends Component {
  render() {
    const data = this.props.response;
    const itemId = data.id ? data.id : 0;
    if (typeof data.response == 'undefined') {
      data.response = '';
    }
    let response =
      Object.keys(data.response).length > 0
        ? typeof data.response === 'string'
          ? JSON.parse(data.response)
          : data.response
        : [];

    if (response) {
      response.sort();
    }

    const answer = data.answer ? JSON.parse(data.answer) : [];

    return (
      <Row className="item-choices avoid-break">
        {Object.keys(this.props.choicesObj).map((key, index) => {
          let resItem = '';
          if (response.indexOf(parseInt(key)) > -1) {
            resItem =
              answer.indexOf(parseInt(key)) > -1 && data.status_answer
                ? 'result-correct correct'
                : answer.indexOf(parseInt(key)) > -1
                ? 'result-correct wrong'
                : 'result-correct';
          } else {
            resItem =
              answer.indexOf(parseInt(key)) > -1 && !data.status_answer
                ? 'wrong'
                : '';
          }

          if (this.props.choicesObj[key]) {
            return (
              <Col
                xs="12"
                lg={
                  this.props.response && this.props.response.item_layouts
                    ? this.props.response.item_layouts
                    : 12
                }
                key={index}
              >
                <p
                  className={
                    `${!this.props.hideResponse ? resItem : ''}` +
                    ' group-choice flex mb-1'
                  }
                >
                  {/* <Input type="checkbox" disabled sty/>  */}
                  <font className="choice-label option-choice option-choice-multi mr-2">
                    {getChoiceLabel()[key]}
                  </font>
                  <span
                    className="content-item"
                    id={`_item_${itemId}_choice_${key}`}
                    dangerouslySetInnerHTML={{
                      __html: this.props.choicesObj[key],
                    }}
                  />
                </p>
              </Col>
            );
          }

          return '';
        })}
        {/*        {!this.props.hideResponse && (
          <Col xs="12" lg="12">
            <p className="mt-3">Đáp án: {response.map((item) => { return ItemService.getChoiceLabel()[item] }).join(', ')}</p>
          </Col>
        )} */}
      </Row>
    );
  }
}

function multipleChoicePrepareData(choicesObj) {
  for (const key in choicesObj) {
    if (window.tinyMCE.get(`choice_${key}`)) {
      window.tinyMCE.get(`choice_${key}`).setContent(choicesObj[key]);
    }
  }
}

export { MultipleChoice, MultipleChoicePreview, multipleChoicePrepareData };
