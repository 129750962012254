import React from 'react';
import { message, Alert } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { sendVerifyEmail } from '../service.js';

class VerifyEmailAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendMailStatus: 0,
    };
  }

  sendVerifyEmail = async () => {
    let sendVerifyEmailTimestamp = localStorage.getItem(
      'sendVerifyEmailTimestamp',
    );
    if (
      sendVerifyEmailTimestamp > 0 &&
      parseInt(sendVerifyEmailTimestamp) > moment().unix() - 120
    ) {
      return message.error(
        `Bạn đã gửi yêu cầu gửi xác nhận gần đây, vui lòng thử lại sau ${
          120 - (moment().unix() - parseInt(sendVerifyEmailTimestamp))
        } giây`,
      );
    }
    this.setState({
      sendMailStatus: 1,
    });
    const response = await sendVerifyEmail();
    this.setState({
      sendMailStatus: 2,
    });
    if (response.data.code == 200) {
      localStorage.setItem('sendVerifyEmailTimestamp', moment().unix());
      message.success(response.data.message);
    } else {
      message.error(response.data.message);
    }
  };

  render() {
    return (
      <Alert
        message="Tài khoản của bạn chưa được xác thực."
        description={
          <span>
            Một email xác minh đã được gửi đến <b>{this.props.user.email}</b>,
            vui lòng kiểm tra email và thực hiện xác thực tài khoản (Kiểm tra
            mục thư rác hoặc quảng cáo nếu không thấy email).
            <br />
            {this.state.sendMailStatus == 0 ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={this.sendVerifyEmail}>
                <u>Gửi lại email xác thực</u>
              </a>
            ) : this.state.sendMailStatus == 1 ? (
              'Đang gửi email ...'
            ) : (
              ''
            )}
          </span>
        }
        type="warning"
        showIcon
        closable
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(VerifyEmailAlert);
