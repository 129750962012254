import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {
  getOrders,
  exportOrders,
  getRegisterProfileUrls,
  updateOrderStatus,
} from '../service';
import {
  Table,
  Button,
  Form,
  Tooltip,
  DatePicker,
  message,
  Select,
  Input,
  Tag,
  Modal,
} from 'antd';
import moment from 'moment';
import {
  FieldTimeOutlined,
  SearchOutlined,
  CaretRightOutlined,
  ClearOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { getErrorMessages } from '../../../app/utils/functions';
import BasicForm from 'modules/Profile/Screens/BasicForm';
import HustForm from 'modules/Profile/Screens/HustForm';

const { Option } = Select;
const { RangePicker } = DatePicker;
const DEFAULT_VI_DATE_FORMAT = 'DD-MM-YYYY';
const DEFAULT_EN_DATE_FORMAT = 'YYYY-MM-DD';

const paymentMethodColors = {
  PAYGOV: '#52c41a',
  PAYLATER: '#faad14',
  _NA: '#c0c0c0',
};

class ListOrder extends Component {
  baseState = {
    orders: [],
    chooseProfile: {},
    filters: {},
    keyword: '',
    sorter: {},
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    isOpenSearch: true,
    exportLoading: false,
    registerProfileUrls: [],
  };

  state = this.baseState;

  columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      width: '50px',
    },

    {
      title: 'Học sinh',
      dataIndex: 'fullname',
      render: (fullname, item) => {
        let customer_invoice_data = item?.customer_invoice_data || '';
        customer_invoice_data = JSON.parse(customer_invoice_data);
        return (
          <>
            <Tooltip placement="top" title={item.username}>
              {item.username} <br />
            </Tooltip>

            <a
              size="small"
              onClick={() => {
                console.log(item.profile);
                this.setState({
                  showModalProfile: true,
                  chooseProfile: item.profile,
                });
              }}
            >
              <u>
                Hồ sơ: {item.profile?.data?.name} - {item.profile?.data?.phone}
              </u>
            </a>
          </>
        );
      },
    },
    {
      title: 'Thông tin',
      dataIndex: 'invoice_description',
      render: (invoice_description, item) => {
        let customer_invoice_data = item?.customer_invoice_data || '';
        customer_invoice_data = JSON.parse(customer_invoice_data);
        return (
          <>
            {invoice_description}
            {!!customer_invoice_data && customer_invoice_data?.subjectData && (
              <>
                <br />
                Bài thi:{' '}
                {customer_invoice_data?.subjectData
                  .map((s) =>
                    s == 'subject_math'
                      ? 'Toán - Đọc hiểu'
                      : s == 'subject_khtn'
                      ? 'KHTN'
                      : s == 'subject_english'
                      ? 'Tiếng Anh'
                      : '',
                  )
                  .join(', ')}
              </>
            )}
          </>
        );
      },
    },

    {
      title: 'Số tiền (VNĐ)',
      align: 'center',
      dataIndex: 'invoice_total_amount',
      render: (invoice_total_amount, item) => {
        return (
          <>
            <Tag color={paymentMethodColors[item.payment_method_code || '_NA']}>
              {item.payment_method_code || 'N/A'}
            </Tag>
            <br />
            <b>
              {new Intl.NumberFormat('vi-VN').format(invoice_total_amount)}đ
            </b>
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (value, item) => {
        return (
          <>
            {this.getBillingStatus(value)}
            <br />
            <span>
              <FieldTimeOutlined
                style={{ fontSize: 12, position: 'relative', top: -2 }}
              />
              &nbsp;
              <span style={{ fontSize: 12 }}>
                {moment(item.invoice_created_ts).format('DD/MM/YYYY HH:mm')}
              </span>
            </span>
          </>
        );
      },
    },
    /*   {
      title: 'Xác nhận',
      dataIndex: 'status',
      render: (value, item) => {
        return (
          <>
            {item.status == 1 || (
              <Button
                size="small"
                title="Xác nhận thanh toán"
                onClick={() => this.changeStatus(item.id, 1)}
                icon={<CheckOutlined />}
              >
                Xác nhận
              </Button>
            )}
          </>
        );
      },
    }, */
    /*    {
      title: '',
      dataIndex: 'status',
      render: (value, item) => {
        return (
          <>
            {item.status == 1 || (
              <Button
                size="small"
                onClick={() => this.changeStatus(item.id, 1)}
                icon={<CheckOutlined />}
              >
                Xác nhận thanh toán
              </Button>
            )}
          </>
        );
      },
    }, */
  ];

  getBillingStatus(status) {
    switch (status) {
      case 0:
        return <Tag color="#faad14">Chưa thanh toán</Tag>;
      case 1:
        return <Tag color="#52c41a">Đã thanh toán</Tag>;
      default:
        return <Tag color="#ff7875">Lỗi thanh toán</Tag>;
    }
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.getListOrders(this.getRequestParams({ pagination }));
    this.getListRegisterProfileUrls();
  }

  getRequestParams({ pagination, filters = {}, sorter = {} }) {
    const request = {
      order_by: sorter.field,
      order_dir: sorter.order,
      page: pagination.current,
      page_size: pagination.pageSize,
      sorter: {
        ...this.state.sorter,
        ...(sorter || this.state.sorter),
      },
      keyword: this.state.keyword.trim() ? this.state.keyword : undefined,
      ...filters,
    };

    if (request.invoice_paid_ts_from) {
      request.invoice_paid_ts_from = moment(
        request.invoice_paid_ts_from,
        DEFAULT_VI_DATE_FORMAT,
      ).format(DEFAULT_EN_DATE_FORMAT);

      request.invoice_paid_ts_to = moment(
        request.invoice_paid_ts_to,
        DEFAULT_VI_DATE_FORMAT,
      ).format(DEFAULT_EN_DATE_FORMAT);
    }

    return request;
  }

  async getListRegisterProfileUrls() {
    const res = await getRegisterProfileUrls();

    if (res.data.code === 200) {
      this.setState({
        registerProfileUrls: res.data.data.items,
      });
    } else {
      return message.error(getErrorMessages(res.data));
    }
  }

  async changeStatus(id, status) {
    const res = await updateOrderStatus(id, status);

    if (res.data.code === 200) {
      return message.success('Cập nhật thông tin thanh toán thành công!');
    } else {
      return message.error(getErrorMessages(res.data));
    }
  }

  async getListOrders(params = {}, isReplace = true) {
    this.setState({ loading: true });

    const res = await getOrders(params);

    if (res.data.code === 200) {
      this.setState({
        loading: false,
        pagination: {
          current: params.page,
          pageSize: params.page_size,
          total: res.data.data.total_count,
          showTotal: () => {
            return (
              'Tổng số ' +
              res.data.data.total_count.toLocaleString() +
              ' bản ghi'
            );
          },
        },
        sorter: {
          ...this.state.sorter,
          ...(params.sorter || this.state.sorter),
        },
        orders: isReplace
          ? res.data.data.items
          : [...this.state.orders, ...res.data.data.items],
      });
    } else {
      return message.error(getErrorMessages(res.data));
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters },
      },
      () =>
        this.getListOrders(
          this.getRequestParams({
            pagination,
            filters: this.state.filters,
            sorter,
          }),
        ),
    );
  };

  resetTable() {
    this.setState({
      ...this.baseState,
      isOpenSearch: this.state.isOpenSearch,
      registerProfileUrls: this.state.registerProfileUrls,
    });
    const { pagination } = this.state;
    this.getListOrders(this.getRequestParams({ pagination }));
  }

  toggleFilters() {
    this.setState({ isOpenSearch: !this.state.isOpenSearch });
  }

  tableReload() {
    const { pagination, filters, sorter } = this.state;

    this.getListOrders(
      this.getRequestParams({
        pagination,
        filters,
        sorter,
      }),
      true,
    );
  }

  async exportExcel() {
    this.setState({ exportLoading: true });
    const { filters } = this.state;
    const params = this.getRequestParams({ pagination: {}, filters });
    const res = await exportOrders(params);

    if (res.data.code === 200) {
      this.setState({
        exportLoading: false,
      });
    } else {
      return message.error(getErrorMessages(res.data));
    }
  }

  render() {
    const {
      orders,
      pagination,
      loading,
      isOpenSearch,
      registerProfileUrls,
      chooseProfile,
      filters: {
        status,
        billing_id,
        payment_method_code,
        invoice_paid_ts_from,
        invoice_paid_ts_to,
        customer_invoice_data_url,
      },
    } = this.state;
    console.log(orders);
    return (
      <Container className="page-exam">
        <Row>
          <Col xs={12}>
            <div className="social__header">
              <div className="social__title">
                <span className="title-classes title-classes-light">
                  Danh sách đăng ký&nbsp;
                  {pagination.total > 0 ? (
                    <span>({pagination.total})</span>
                  ) : (
                    ''
                  )}
                </span>
                <div className="action-group-right">
                  <Button
                    onClick={() => this.resetTable()}
                    icon={<ClearOutlined />}
                  />
                  &nbsp;
                  <Button
                    onClick={() => this.toggleFilters()}
                    icon={
                      isOpenSearch ? <CaretRightOutlined /> : <SearchOutlined />
                    }
                  >
                    {isOpenSearch ? 'Thu gọn' : 'Tìm kiếm'}
                  </Button>
                </div>
              </div>
              <hr className="line mt-3" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={isOpenSearch ? 9 : 12}>
            <Table
              bordered
              columns={this.columns}
              rowKey={(record) => record.id}
              dataSource={orders}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
          </Col>
          {isOpenSearch && (
            <Col
              xs={3}
              style={{
                border: '1px solid #f0f0f0',
                backgroundColor: '#fff',
                paddingTop: 16,
              }}
            >
              <Form layout="vertical">
                <Form.Item label="Mã đơn:">
                  <Input
                    value={billing_id || ''}
                    type="number"
                    onChange={(e) => {
                      this.setState(
                        {
                          orders: [],
                          filters: {
                            ...this.state.filters,
                            billing_id: e.target.value ? e.target.value : '',
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="Kỳ thi:">
                  <Select
                    value={customer_invoice_data_url || ''}
                    onChange={(value) => {
                      this.setState(
                        {
                          orders: [],
                          filters: {
                            ...this.state.filters,
                            customer_invoice_data_url: value ? value : '',
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  >
                    <Option value=""> -- Tất cả -- </Option>
                    {registerProfileUrls.map((item) => (
                      <Option key={`url-${item.short_name}`} value={item.url}>
                        {item.short_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Ngày thanh toán:">
                  <RangePicker
                    format={DEFAULT_VI_DATE_FORMAT}
                    value={
                      !invoice_paid_ts_from
                        ? ''
                        : [
                            moment(
                              invoice_paid_ts_from,
                              DEFAULT_VI_DATE_FORMAT,
                            ),
                            moment(invoice_paid_ts_to, DEFAULT_VI_DATE_FORMAT),
                          ]
                    }
                    onChange={(_, [from, to]) => {
                      this.setState(
                        {
                          orders: [],
                          filters: {
                            ...this.state.filters,
                            invoice_paid_ts_from: from,
                            invoice_paid_ts_to: to,
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item label="Trạng thái:">
                  <Select
                    value={status || ''}
                    onChange={(value) => {
                      this.setState(
                        {
                          orders: [],
                          filters: {
                            ...this.state.filters,
                            status: value ? value : '',
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  >
                    <Option value=""> -- Tất cả -- </Option>
                    <Option value="1">Đã thanh toán</Option>
                    <Option value="0">Chưa thanh toán</Option>
                    <Option value="-1">Lỗi thanh toán</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Nguồn:">
                  <Select
                    value={payment_method_code || ''}
                    onChange={(value) => {
                      this.setState(
                        {
                          orders: [],
                          filters: {
                            ...this.state.filters,
                            payment_method_code: value ? value : '',
                          },
                        },
                        () => this.tableReload(),
                      );
                    }}
                  >
                    <Option value=""> -- Tất cả -- </Option>
                    <Option value="PAYGOV">Pay Gov</Option>
                    <Option value="PAYLATER">Pay Later</Option>
                  </Select>
                </Form.Item>
                <Button type="primary" onClick={() => this.exportExcel()}>
                  Export
                </Button>
              </Form>
            </Col>
          )}
        </Row>

        {!!this.state.showModalProfile && (
          <Modal
            visible={true}
            onOk={() => {
              this.setState({ showModalProfile: false });
            }}
            onCancel={() => {
              this.setState({ showModalProfile: false });
            }}
            footer={null}
            closable
            centered
            width={1200}
            //className="modal-setting-info modal-confirm-register"
            closeIcon={<i className="fa fa-times-circle" aria-hidden="true" />}
          >
            <div className="btn-block ">
              <div className="section-vnu mt-2">
                <b>{chooseProfile.name}</b>
              </div>
              <div className="section-vnu">
                {chooseProfile.code === 'BASIC' && (
                  <BasicForm view profile={chooseProfile.data} />
                )}
                {chooseProfile.code === 'HUST' && (
                  <HustForm view profile={chooseProfile.data} />
                )}
              </div>
            </div>
          </Modal>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ListOrder);
