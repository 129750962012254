import React, { Component } from 'react';
import { Form, Input, Button, message, InputNumber } from 'antd';
import { Container, Row, Col } from 'reactstrap';
import { register, login } from '../../service';
import Auth from 'app/auth';
import { getErrorMessages } from 'app/utils/functions';
import logo from 'themes/default/logo-full.svg';
import config from 'app/config';
import ic_fb from 'assets/img/theme/facebook.png';
import { Link } from 'react-router-dom';
import * as queryString from 'query-string';
import theme from 'themes';
import PATHS from 'themes/paths';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginObj: {
        username: '',
        password: '',
        rePassword: '',
        type: 1,
        email: '',
        phone: '',
      },
      loginError: {
        email: false,
        username: false,
        password: false,
        rePassword: false,
        type: false,
        phone: false,
      },
      loading: false,
      returnUrl: '',
    };
  }

  onFormChange = (event) => {
    const { loginObj } = this.state;
    loginObj[event.target.name] = event.target.value;
    this.setState({
      loginObj,
      loginError: false,
    });
  };

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const returnUrl = params.returnUrl || this.props.location.returnUrl || '';
    this.setState({ returnUrl });
  }

  onFormSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { loginObj } = this.state;

    if (loginObj.phone) {
      loginObj.phone = `0${loginObj.phone}`.replace(/0*/, '0');
    }
    const response = await register(loginObj);
    if (response.data.code == 200) {
      this.setState(
        {
          loginError: response.data.errors,
          loading: false,
        },
        async () => {
          message.success('Đăng ký thành công.');
          const result = await login(loginObj.email, loginObj.password);

          if (result.status == 200) {
            await Auth.verifyUser(
              result.data.access_token,
              result.data.refresh_token,
            ).then((res) => {
              localStorage.setItem('refresh_event', Math.random());
            });
          }
          const self = this;
          setTimeout(() => {
            if (self.state.returnUrl) {
              window.location.href = self.state.returnUrl;
            } else {
              window.location.href = theme.config.auth.registerRedirect;
            }
          }, 2000);
        },
      );
    } else {
      this.setState(
        {
          loginError: response.data.errors,
          loading: false,
        },
        () => {
          if (
            typeof response.data.errors != 'undefined' &&
            Object.keys(response.data.errors).length > 0
          ) {
            Object.values(response.data.errors).forEach((element) => {
              message.error(element);
            });
          } else {
            message.error(getErrorMessages(response.data));
          }
        },
      );
    }
  };

  changeVal = (changedValues, allValues) => {
    this.setState({
      loginObj: allValues,
    });
  };

  cepMask = (value) => {
    if (value.match(/^([0]+)([1-9][0-9]*)$/gm)) {
      return value;
    }

    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  render() {
    const { loginObj } = this.state;
    const returnUrl =
      this.props.location && this.props.location.returnUrl
        ? this.props.location.returnUrl
        : '';

    return (
      <div className="page-regiter">
        <Container className="">
          <Row>
            <Col xs="12" className="">
              <div className="text-center">
                <img className="logo-kt mb-4 mt-4" alt="..." src={logo} />
              </div>
              <div className="auth-form">
                <div className="auth-form__inner-wrap">
                  <div className="mb-5">
                    <h1 className="auth-form__header-welcome">
                      Đăng ký tài khoản
                    </h1>
                    <p className="text-center">
                      Bạn đã có tài khoản?{' '}
                      <Link to={PATHS.login} className="link">
                        Đăng nhập ngay
                      </Link>
                    </p>
                  </div>
                  {/*  <div className="btn-gr-mxh mb-4">
                    <a
                      href={config.getUrlLoginFB(returnUrl)}
                      color=""
                      className="btn btn-pill social-auth social-auth--fb"
                    >
                      <span className="icon js-facebook-inner">
                        <img src={ic_fb} alt="Facebook" />
                      </span>
                      <span className="font-face-lt">Facebook</span>
                    </a>
                    <a
                      href={config.getUrlLoginGG(returnUrl)}
                      color=""
                      className="btn btn-pill social-auth social-auth-gg"
                    >
                      <span className="icon js-google-inner">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="24" height="24" rx="5" fill="#fff" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.64 12.204c0-.638-.057-1.252-.164-1.84H12v3.48h4.844a4.14 4.14 0 01-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
                            fill="#4285F4"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 21c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H3.957v2.332A8.997 8.997 0 0012 21z"
                            fill="#34A853"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.964 13.71A5.41 5.41 0 016.682 12c0-.593.102-1.17.282-1.71V7.958H3.957A8.997 8.997 0 003 12c0 1.452.348 2.827.957 4.042l3.007-2.332z"
                            fill="#FBBC05"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 6.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C16.463 3.891 14.426 3 12 3a8.997 8.997 0 00-8.043 4.958l3.007 2.332C7.672 8.163 9.656 6.58 12 6.58z"
                            fill="#EA4335"
                          />
                        </svg>
                      </span>
                      <span className="font-face-lt">Google</span>
                    </a>
                  </div>
                  <div className="or-line">
                    <p className="or__line">
                      <span className="or__text">Hoặc</span>
                    </p>
                  </div> */}
                  <Form
                    autoComplete="off"
                    layout="vertical"
                    name="basic"
                    className="profile-form regis-form"
                    initialValues={loginObj}
                    onValuesChange={this.changeVal}
                    onFinish={this.onFormSubmit}
                    // key={this.state.resetForm}
                  >
                    {/* <Form.Item
                      label={
                        <span>
                          Tên đăng nhập <span className="text-danger">*</span>
                        </span>
                      }
                      name="username"
                    >
                      <Input
                        className={
                          this.state.loginError.username
                            ? 'is-invalid-date'
                            : ''
                        }
                        placeholder=""
                        autoComplete="username"
                      />
                    </Form.Item> */}
                    <Form.Item
                      label={
                        <span>
                          Họ tên
                          <span className="text-danger">*</span>
                        </span>
                      }
                      name="fullname"
                    >
                      <Input
                        placeholder="Điền đầy đủ theo họ tên trên CMND/CCCD"
                        className={
                          this.state.loginError.fullname
                            ? 'is-invalid-date'
                            : ''
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span>
                          Email <span className="text-danger">*</span>
                        </span>
                      }
                      name="email"
                    >
                      <Input
                        className={
                          this.state.loginError.email ? 'is-invalid-date' : ''
                        }
                        placeholder="Điền chính xác email để nhận thông tin"
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        autoComplete="email"
                      />
                    </Form.Item>
                    <Form.Item label="Số điện thoại" name="phone">
                      <InputNumber
                        className={
                          this.state.loginError.phone ? 'is-invalid-date' : ''
                        }
                        placeholder="Số điện thoại liên hệ"
                        autoComplete="phone"
                        formatter={(value) => {
                          if (value) {
                            return `0${value}`.replace(/0*/, '0');
                          }

                          return value;
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Mật khẩu"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập Mật khẩu',
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Nhập mật khẩu"
                        className={
                          this.state.loginError.password
                            ? 'is-invalid-date'
                            : ''
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Nhập lại mật khẩu"
                      name="rePassword"
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng xác nhận lại mật khẩu',
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        placeholder="Xác nhận lại mật khẩu"
                        className={
                          this.state.loginError.rePassword
                            ? 'is-invalid-date'
                            : ''
                        }
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="b-r-8 btn-submit"
                        block
                      >
                        {this.state.loading ? (
                          <span className="font-face-lt">
                            <img
                              alt=""
                              className="icon-loading"
                              src={config.getLoadingDefault()}
                            />{' '}
                            <span>Đang gửi</span>
                          </span>
                        ) : (
                          <span className="font-face-lt">
                            <span>Đăng ký</span>
                          </span>
                        )}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
