import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../app/config';
import { getErrorMessages } from '../../app/utils/functions';

const LIMIT_IMAGE_SIZE = 2 * 1024 * 1024; // 2Mb
class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  onChangeEvent(event, content) {
    this.props.onChange(event, content.getContent());
  }

  render() {
    return (
      <div>
        <Editor
          id={this.props.id}
          initialValue={this.props.value}
          init={{
            height: this.props.height || 150,
            content_style: this.props.style || '',
            menubar: false,
            statusbar: false,
            paste_as_text: true,
            plugins: ['image link paste table textcolor charmap'],
            toolbar:
              'bold italic underline alignleft aligncenter alignright indent outdent forecolor backcolor table superscript subscript tiny_mce_wiris_formulaEditor charmap uploadimage',
            setup: (editor) => {
              editor.addButton('uploadimage', {
                title: 'Tải lên ảnh',
                icon: 'image',
                onclick: () => {
                  const seft = this;
                  const input = document.createElement('input');
                  input.type = 'file';

                  input.onchange = (e) => {
                    const file = e.target.files[0];
                    if (file && !file.name) {
                      toast.error('Vui lòng chọn file', {
                        position: toast.POSITION.TOP_RIGHT,
                      });

                      return false;
                    }
                    if (file.size > LIMIT_IMAGE_SIZE) {
                      toast.error('Vui lòng tải file nhỏ hơn 2Mb', {
                        position: toast.POSITION.TOP_RIGHT,
                      });

                      return false;
                    }
                    let formData;
                    formData = new FormData();
                    formData.append('file', file);
                    // upload
                    seft.setState({
                      loading: true,
                    });
                    axios({
                      method: 'post',
                      url: `${config.getApiDomain()}/item/upload-image`,
                      data: formData,
                      headers: { 'Content-Type': 'multipart/form-data' },
                    })
                      .then((response) => {
                        if (response.status != 200) {
                          toast.error(getErrorMessages(response.data), {
                            position: toast.POSITION.TOP_RIGHT,
                          });

                          return false;
                        }
                        if (response.data.code != 200) {
                          toast.error(getErrorMessages(response.data), {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                          seft.setState({
                            loading: false,
                          });

                          return false;
                        }
                        const src = response.data.data.location;
                        editor.insertContent(`<img src=${src}>`);
                        seft.setState({
                          loading: false,
                        });
                      })
                      .catch((error) => {
                        console.log('Error:', error.response);
                        toast.error(getErrorMessages(error.response), {
                          position: toast.POSITION.TOP_RIGHT,
                        });
                        seft.setState({
                          loading: false,
                        });
                      });
                  };
                  input.click();
                },
              });
            },
            external_plugins: {
              // 'tiny_mce_wiris': '/assets/js/tinymce/mathtype-tinymce4/plugin.min.js',
              tiny_mce_wiris:
                'https://mathtype.xcbt.online/tiny_mce_wiris/plugin.min.js',
            },
            forced_root_block: '',
            entity_encoding: 'raw',
            resize: true,
            content_css: '/assets/js/tinymce/custom_content.css',
          }}
          // onChange={this.onItemBodyEdit}
          onChange={(event, content) => this.onChangeEvent(event, content)}
          onKeyUp={(event, content) => this.onChangeEvent(event, content)}
        />
        {this.state.loading && (
          <div className="data-loading">
            <img src="/assets/img/loading.svg" alt="" />
          </div>
        )}
      </div>
    );
  }
}

export default TextEditor;
