import { Breadcrumb } from 'antd';

import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import routes from '../../routes';

class BreadcrumbX extends React.Component {
  state = {};

  render() {
    const { location, extra } = this.props;

    const pathSnippets = location.pathname.split('/').filter((i) => i);

    const extraBreadcrumbItems = pathSnippets
      .map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        const x = routes.findIndex((item) => item.path == url);

        if (routes[x]) {
          return (
            <Breadcrumb.Item key={index}>
              <Link to={routes[x].path}>{routes[x].name}</Link>
            </Breadcrumb.Item>
          );
        }

        return null;
      })
      .filter((item) => !!item);

    const extraBreadcrumb = extra.map((_, index) => (
      <Breadcrumb.Item key={_.path}>{_.name}</Breadcrumb.Item>
    ));

    const breadcrumbItems = extraBreadcrumbItems.concat(extraBreadcrumb);

    return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(BreadcrumbX);
