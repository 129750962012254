const defaultState = {
  user: {},
  config: {
    item_levels: [
      { value: 1, name: 'Biết' },
      { value: 3, name: 'Hiểu' },
      { value: 5, name: 'Vận dụng' },
      { value: 7, name: 'Vận dụng cao' },
    ],
  },
  userToken: '',
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return { ...state, user: action.user };
    // case 'UPDATE_CONFIG':
    //   return { ...state, config: action.config }
    case 'UPDATE_USER_TOKEN':
      return { ...state, userToken: action.userToken };
    default:
      return state;
  }
};

export default reducer;
