import axios from 'axios';
import config from '../../app/config';
import QS from '../../app/utils/query-string';

export function getAListPartners(params) {
  const apiUrl = `${config.getApiDomain()}/admin/partners`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
    params: params,
  }).then((response) => response);
}

export function createPartner(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/admin/save-partner`,
    data: QS.stringify(data),
  }).then((response) => response);
}

export function getPartner(id) {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/admin/partner`,
    params: { id },
  }).then((response) => response);
}
