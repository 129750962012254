import axios from 'axios';
import QS from '../../app/utils/query-string';
import config from '../../app/config';

export function getListExamRegistered() {
  const apiUrl = `${config.getApiDomain()}/exams/get-list-exam-registered`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  });
}
/*
export function createOrder(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders/create`,
    data: QS.stringify(data),
  })
} */

/* export function updateOrder(id, data) {
  data.id = id;

  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders/update`,
    data: QS.stringify(data),
  })
} */

export function updateSubjects(id, data) {
  data.id = id;

  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders/update-subject`,
    data: QS.stringify(data),
  });
}

export function getProfilesByEmail(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/search`,
    data: QS.stringify(data),
  });
}

export function getPaymentMethod() {
  const apiUrl = `${config.getApiDomain()}/orders-payment-method`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  });
}

export function createPayment(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders-payment/create`,
    data: QS.stringify(data),
  });
}

export function createGuestPayment(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders-payment/guest-create`,
    data: QS.stringify(data),
  });
}

export function checkInvoice(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders-payment/check-invoice`,
    data: QS.stringify(data),
  });
}

export function checkGuestInvoice(data) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/orders-payment/check-guest-invoice`,
    data: QS.stringify(data),
  });
}

export function getProfiles() {
  const apiUrl = `${config.getApiDomain()}/profile`;
  const method = 'get';

  return axios({
    method,
    url: apiUrl,
  });
}

export function deleteProfile(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/delete`,
    data: QS.stringify({ id }),
  });
}

export function viewProfile(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/profile/view`,
    data: QS.stringify({ id }),
  });
}

export function bookmarkExam(id) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/bookmark-exam`,
    data: QS.stringify({ id }),
  });
}

export function getExam(url) {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/exams/view`,
    params: {
      url,
    },
  });
}

export function getExamRegistered(url) {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/exams/view-registered`,
    params: {
      url,
    },
  });
}

export function getListExams() {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/exams/list`,
  });
}

export function guestGetListExams() {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/exams/guest-list`,
  });
}

export function updateStudentTablePoint(userId, tablePoint, images) {
  const formData = new FormData();
  if (Object.keys(images).length) {
    Object.keys(images).forEach((key) => {
      formData.append(key, images[key]);
    });
  }

  formData.append('table_point', JSON.stringify(tablePoint));

  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/user/update-table-point`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params: {
      id: userId,
    },
  });
}

export function getTestSites(examId) {
  const apiUrl = `${config.getApiDomain()}/exam-plan/get-test-sites?exam_id=${examId}`;

  return axios.get(apiUrl);
}

export function registerExam(examId, profileId, testSiteId) {
  return axios({
    method: 'post',
    url: `${config.getApiDomain()}/exams/register`,
    data: QS.stringify({ examId, profileId, testSiteId }),
  });
}

export function getExamPlanDetail(examPlanId) {
  return axios({
    method: 'get',
    url: `${config.getApiDomain()}/exams/get-exam-plan-detail?exam_plan_id=${examPlanId}`,
  });
}
