import React, { useReducer, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';

// Create Context Object
const TsaRegisterContext = createContext();

const initialState = {
  loading: true,
  exam: {},
  user: {},
  loadingProfile: true,
  profile: {},
  // chooseProfile: {},
  currentStep: 0,
  disabled: true,
  checkedRule: false,
  orderId: '',
  keyword: '',
  paymentMethodGroup: [],
  paymentMethodData: [],
  paymentMethod: {},
  invoice: null,
  billing: null,
  error: false,
  modal: false,
  modalDetail: false,
  modalSelectPayMethod: false,
  searchEmail: '',
  loadingStep3: false,
  tablePoint: null,
  loadingSaveTablePoint: false,
  testSiteIdSelected: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CONTEXT':
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export const TsaRegisterContextService = () => {
  const [state, dispatch] = useContext(TsaRegisterContext);

  if (!state) {
    throw new Error('state not found in A72ContextService!');
  }

  const TsaRegisterDispatcher = {
    updateContext(data) {
      dispatch({
        type: 'UPDATE_CONTEXT',
        data: data,
      });
    },
    cleanContext() {
      dispatch(null);
    },
  };

  return [state, TsaRegisterDispatcher];
};

export const TsaRegisterContextProvider = (props) => {
  const location = useLocation();
  let pushState = {};

  if (location.state && location.state.initialState) {
    pushState = location.state.initialState;
  }

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...pushState,
    ...props.data,
  });

  return (
    <TsaRegisterContext.Provider value={[state, dispatch]}>
      {props.children}
    </TsaRegisterContext.Provider>
  );
};

export default TsaRegisterContext;
