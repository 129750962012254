const priority_objects = [
  { value: '-1', label: 'Không thuộc đối tượng ưu tiên' },
  { value: '01', label: 'Đối tượng 01' },
  { value: '02', label: 'Đối tượng 02' },
  { value: '03', label: 'Đối tượng 03' },
  { value: '04', label: 'Đối tượng 04' },
  { value: '05', label: 'Đối tượng 05' },
  { value: '06', label: 'Đối tượng 06' },
  { value: '07', label: 'Đối tượng 07' },
];

export default priority_objects;
