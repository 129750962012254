import React, { Component } from 'react';
import {
  Card,
  Image,
  Form,
  Input,
  Button,
  Badge,
  Drawer,
  message,
  Avatar,
  Modal,
  Tooltip,
} from 'antd';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import {
  ExclamationCircleOutlined,
  FormOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import Scroll from 'react-scroll';
import Auth from 'app/auth';
import config from 'app/config';
import {
  getErrorMessages,
  formatProfile,
  nonAccentVietnamese,
} from 'app/utils/functions';
import {
  getProfiles,
  searchUserByCode,
  listRequestUser,
  deleteConnective,
  requestUser,
  getDataListExam,
  addConnective,
  listConnectiveClass,
  editCode,
  sendVerifyEmail,
} from '../../service';
import VerifyEmailAlert from '../../Components/VerifyEmailAlert';
import UploadFile from '../../Components/UploadFile';
import ProfileItem from '../../Components/ProfileItem';
import ic_microscope from 'assets/img/theme/microscope.png';
import icTestTaker from 'themes/default/svg-same-color/icon-test-taker.svg';
import icTestTakerTodo from 'themes/default/svg-same-color/icon-test-taker-todo.svg';
import ProfileFormInfo from '../../Components/ProfileFormInfo';
import icNetwork from 'themes/default/svg/icon-network.svg';
import RequestForm from '../../Components/ClassFormRequest';
import ProfileFriend from '../../Components/ProfileFriend';
import QS from 'app/utils/query-string';
import TemplateList from '../../../Profile/Screens/TemplateList';
import TemplateItem from '../../../Profile/Screens/TemplateItem';
import { Link } from 'react-router-dom';
import theme from 'themes';
import PATHS from 'themes/paths';

const CODE_REPO = {
  SYLL: 'syll',
  VNUHCM: 'vnuhcm',
  HUST: 'hust',
  BASIC: 'basic',
};

const { confirm } = Modal;
const { scroller } = Scroll;
const { Meta } = Card;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const template = [
  {
    id: 0,
    code: 'BASIC',
    name: 'Hồ sơ cơ bản',
    link: '/profile/basic',
    useHref: true,
  },
  // {
  //   id: 1,
  //   code: 'SYLL',
  //   name: 'Sơ yếu lý lịch',
  //   link: '/profile/syll',
  //   useHref: true,
  // },
  // {
  //   id: 2,
  //   code: 'VNUHCM',
  //   name: 'Đánh giá năng lực',
  //   link: '/profile/vnuhcm',
  //   useHref: true,
  // },
  {
    id: 3,
    code: 'HUST',
    name: 'Hồ sơ dự thi',
    link: '/profile/hust',
    useHref: true,
  },
];
class Profile extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      lstConnected: [],
      lstRequest: [],
      lstInvited: [],
      lstUser: [],
      activeTab: '1',
      collapse: false,
      display: 1,
      editUserObj: {
        fullname: '',
        phone: '',
        school: '',
        address: '',
        birthday: '',
      },
      user: '',
      total: [],
      list_class: [],
      editCode: false,
      code: this.props.user.code,
      scrollStatus: false,
      templateList: template,
      list_profile: [],
      sendMailStatus: 0,
    };
    this.formRef = React.createRef();
    this.profiles = [];
  }

  collapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse: !collapse });
  };

  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === 'endDate' &&
      new Date(`${this.state.startDate._d}`) > new Date(`${date._d}`)
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else if (
      this.state.endDate &&
      who === 'startDate' &&
      new Date(`${this.state.endDate._d}`) < new Date(`${date._d}`)
    ) {
      this.setState({
        startDate: date,
        endDate: date,
      });
    } else {
      this.setState({
        [who]: date,
      });
    }
  };

  toggle = async (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      let scrollStatus = false;
      if (window.scrollY >= 650) {
        scrollStatus = true;
      }
      this.setState({ scrollStatus });
    });
    if (!this.props.user) {
      message.error('Có lỗi xảy ra!');
    } else {
      if (this.props.user.birthday) {
        const splitted = this.props.user.birthday.split(' ', 2);
        this.props.user.birthday = splitted[0]
          ? splitted[0]
          : this.props.user.birthday;
      }
      this.setState({
        isEdit: true,
        editUserObj: this.props.user,
      });
    }
    this.getlistRequestUser();
    this.getListExam();
    this.getListConnectiveClass();
    this.getProfiles();

    const search = QS.parse(this.props.location.search)
      ? QS.parse(this.props.location.search)
      : {};
    if (search.tab) {
      this.setState({ activeTab: search.tab }, () => {
        this.onMenuClick({ type: 4 });
      });
    }
    if (this.props.location && this.props.location.pathname) {
      localStorage.setItem('home_url', this.props.location.pathname);
    }
  }

  getListConnectiveClass = async () => {
    const response = await listConnectiveClass();
    if (response.data.code == 200) {
      this.setState({ list_class: response.data.data });
    } else {
      return message.error(getErrorMessages(response.data));
    }
  };

  getProfiles = async () => {
    const response = await getProfiles();
    if (response.data.code == 200) {
      this.profiles = response.data.data.profiles.map((item) => {
        try {
          item.data =
            typeof item.data == 'string' ? JSON.parse(item.data) : item.data;
        } catch (e) {
          item.data = {};
        }

        item.nonVNFullname = nonAccentVietnamese(item.data.name || '');
        item.nonVNName = nonAccentVietnamese(item.name || '');

        return item;
      });
      this.setState({ list_profile: this.profiles });
    } else {
      return message.error(getErrorMessages(response.data));
    }
  };

  delConnective = async (id) => {
    const response = await deleteConnective(id);
    if (response.data.code == 200) {
      message.success(response.data.message);
      this.getlistRequestUser();
    } else {
      message.error(response.data.message);
    }
  };

  getlistRequestUser = async () => {
    const response = await listRequestUser(this.props.user.id);
    if (response.data.code == 200) {
      this.setState({
        lstRequest: response.data.data.request,
        lstConnected: response.data.data.connected,
        lstInvited: response.data.data.invited,
        lstUser: response.data.data.lst_user,
      });
    }
  };

  onBack = () => {
    this.setState({
      visible: 1,
    });
  };

  showDrawer = async (visible, code, type) => {
    if (!code) {
      return message.error('Bạn chưa nhập mã hồ sơ');
    }
    if (!code || code == this.props.user.code) {
      return message.error('Không tìm thấy hồ sơ');
    }
    const response = await searchUserByCode(code);
    if (response.data.code == 200) {
      this.setState({ type, user: response.data.data, visible });
    } else {
      message.error(response.data.message);
      this.setState({ user: '' });
    }
  };

  onSubmitRequest = async () => {
    const { user, type } = this.state;
    if (!user || user.id == this.props.user.id) {
      return message.error('Không tìm thấy hồ sơ');
    }
    const response = await requestUser(user.id, type);
    if (response.data.code == 200) {
      message.success('Đã gửi yêu cầu liên kết');
      this.onClose();
      this.getlistRequestUser();
    } else {
      message.error(response.data.message);
    }
  };

  confirmInvited = async (id) => {
    const response = await addConnective(id);
    if (response.data.code == 200) {
      message.success('Liên kết hồ sơ thành công.');
      this.getlistRequestUser();
    } else {
      message.error(response.data.message);
    }
  };

  sendVerifyEmail = async () => {
    this.setState({
      sendMailStatus: 1,
    });
    const response = await sendVerifyEmail();
    this.setState({
      sendMailStatus: 2,
    });
    if (response.data.code == 200) {
      message.success(response.data.message);
      this.getlistRequestUser();
    } else {
      message.error(response.data.message);
    }
  };

  getListExam = async () => {
    const self = this;
    const response = await getDataListExam(this.props.user.id);
    if (response.data.code == 200) {
      self.setState({ total: response.data.data.total });
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  onMenuClick = (item) => {
    scroller.scrollTo(`profile${item.type}`, {
      duration: 300,
      delay: 0,
      smooth: true,
      offset: -70, // Scrolls to element + 50 pixels down the page
    });
  };

  openEditCode = () => {
    this.setState(
      {
        editCode: !this.state.editCode,
        code: !this.state.editCode ? this.state.code : this.props.user.code,
      },
      () => {
        if (this.state.editCode) {
          this.textInput.focus();
        }
      },
    );
  };

  Copy = () => {
    navigator.clipboard.writeText(this.props.user.code);
    message.success(`Đã copy ${this.props.user.code}`);
  };

  editCodeSubmit = async (code = '') => {
    let newCode = code || this.state.code;
    try {
      newCode = newCode.trim().toUpperCase();
    } catch (error) {}
    // if(newcode==this.props.user.code) return message.error('Mã hồ sơ này bạn đang sử dụng.');
    const response = await editCode(newCode);
    if (response.data.code == 200) {
      message.success(
        code
          ? 'Đã khôi phục mã hồ sơ về mặc định'
          : `Mã hồ sơ mới của bạn là: ${newCode}`,
      );
      this.setState(
        {
          code: newCode,
          editCode: false,
        },
        () => {
          Auth.init();
        },
      );
    } else {
      message.error(getErrorMessages(response.data));
    }
  };

  onFormChange = (event) => {
    const code = event.target.value;
    this.setState({ code });
  };

  onClose = () => {
    this.setState({ activeTab: '4' }, () => {
      this.onMenuClick({ type: 4 });
      this.getlistRequestUser();
    });

    this.setState({
      visibleDrawer: false,
      visible: 1,
      user: '',
    });
  };

  onSearch = (e) => {
    console.log(this.profiles, 'this.profiles');
    const keyword = nonAccentVietnamese(e.target.value.toLowerCase());
    const filterTemplate = this.profiles.filter(
      (item) =>
        item.code.toLowerCase().indexOf(keyword) >= 0 ||
        item.nonVNName.toLowerCase().indexOf(keyword) >= 0 ||
        item.nonVNFullname.toLowerCase().indexOf(keyword) >= 0,
    );
    this.setState({ list_profile: filterTemplate });
  };

  openRequest = () => {
    confirm({
      className: 'type-profile',
      width: '60%',
      centered: true,
      title: (
        <>
          Hãy chọn mẫu hồ sơ{' '}
          <i style={{ fontSize: 13 }}>
            (Mỗi loại hồ sơ chỉ được tạo duy nhất 1 hồ sơ)
          </i>
        </>
      ),
      icon: <ExclamationCircleOutlined />,
      content: [<TemplateList key="type-profile" data={template} />],
      okButtonProps: { style: { display: 'none' } },
      cancelText: 'Hủy',
      onOk() {},
      onCancel() {},
    });
  };

  render() {
    const {
      list_profile,
      code,
      editCode,
      editUserObj,
      lstConnected,
      lstInvited,
      lstRequest,
      activeTab,
      visible,
      user,
      visibleDrawer,
      list_class,
      total,
      lstUser,
    } = this.state;
    const lstConnected1 = lstConnected.filter(
      (item) => item.id_to == this.props.user.id,
    );
    const lstConnected2 = lstConnected.filter(
      (item) => item.id_from == this.props.user.id,
    );

    return (
      <Container>
        <Row>
          {!this.props.user.email_verified && (
            <Col xs={12} className="mb-3">
              <VerifyEmailAlert />
            </Col>
          )}
          <Col
            xs={12}
            md={6}
            lg={6}
            xl={3}
            className="mb-4 offset-lg-3 offset-xl-0"
          >
            <Card className="x-card">
              <div className="statistic-card statistic-card-info">
                <UploadFile
                  user={editUserObj}
                  importSuccess={this.importExcelSuccess}
                  onStartUpload={() => {
                    this.setState({ uploading: true });
                  }}
                  onFinishUpload={() => {
                    this.setState({ uploading: false });
                  }}
                />
                <Meta
                  className="text-center mt-2"
                  title={
                    this.props.user.fullname
                      ? this.props.user.fullname
                      : this.props.user.username
                  }
                  description={
                    <p className="flex text-center align-center">
                      <Tooltip placement="top" title="Mã hồ sơ">
                        {!editCode ? (
                          <span
                            className="secondary ml-2 mr-2 width-auto"
                            onClick={() => this.Copy()}
                          >
                            {code}
                          </span>
                        ) : (
                          <Input
                            className="width-auto"
                            ref={(input) => {
                              this.textInput = input;
                            }}
                            style={{
                              textTransform: 'uppercase',
                            }}
                            placeholder=""
                            defaultValue={code}
                            bordered={false}
                            onChange={this.onFormChange}
                          />
                        )}
                      </Tooltip>
                      <>
                        {editCode && (
                          <Tooltip placement="top" title="Lưu lại">
                            <CheckCircleOutlined
                              onClick={() => {
                                this.editCodeSubmit();
                              }}
                              className="f-s-md vertical-baseline primary cursor mr-2"
                            />
                          </Tooltip>
                        )}
                        {editCode && (
                          <Tooltip placement="top" title="Khôi phục mặc định">
                            <SyncOutlined
                              onClick={() => {
                                this.editCodeSubmit(
                                  this.props.user.code_default,
                                );
                              }}
                              className="f-s-md vertical-baseline secondary cursor mr-2"
                            />
                          </Tooltip>
                        )}
                        {editCode && (
                          <Tooltip placement="top" title="Hủy">
                            <CloseCircleOutlined
                              onClick={() => {
                                this.openEditCode();
                              }}
                              className="f-s-md vertical-baseline danger cursor"
                            />
                          </Tooltip>
                        )}
                        {!editCode && (
                          <Tooltip placement="top" title="Sửa mã hồ sơ">
                            <FormOutlined
                              onClick={() => {
                                this.openEditCode();
                              }}
                              className="f-s-md vertical-baseline primary cursor"
                            />
                          </Tooltip>
                        )}
                      </>
                    </p>
                  }
                />
              </div>
            </Card>
          </Col>
          {!theme.config.excludePaths.includes(PATHS.classes) && (
            <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
              <Card className="x-card">
                <div className="statistic-card flex flex-column">
                  <div className="text-right block">
                    <Image src={ic_microscope} size={56} preview={false} />
                  </div>
                  <Link to={PATHS.classes}>
                    <div className="bage-custom primary-light">
                      {list_class &&
                      list_class.active &&
                      list_class.active.length > 0
                        ? list_class.active.length
                        : 0}
                    </div>
                  </Link>
                  <div>Lớp học</div>
                </div>
              </Card>
            </Col>
          )}
          <Col xs={12} md={6} lg={4} xl={3} className="mb-4">
            <Card className="x-card">
              <div className="statistic-card flex flex-column">
                <div className="text-right block">
                  <Image src={icTestTaker} size={56} preview={false} />
                </div>
                <Link to={`${PATHS.testTaker}?type=2`}>
                  <div className="bage-custom success-light">
                    {total && total.done ? total.done : 0}
                  </div>
                </Link>
                <div>Bài đã làm</div>
              </div>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4} xl={3}>
            <Card className="x-card">
              <div className="statistic-card flex flex-column">
                <div className="text-right block">
                  <Image src={icTestTakerTodo} size={56} preview={false} />
                </div>
                <Link to={`${PATHS.testTaker}?type=1`}>
                  <div className="bage-custom warning-light">
                    {list_class && list_class.total ? list_class.total.must : 0}
                  </div>
                </Link>
                <div>Bài cần làm</div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} lg={6} xl={6}>
            <Card
              className="x-card mb-4 info-profile"
              title="Thông tin tài khoản"
            >
              <Form
                {...layout}
                name="basic"
                className="profile-form"
                initialValues={{
                  username: this.props.user.username,
                  email: this.props.user.email,
                }}
              >
                <Form.Item label="Email" name="email">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Xác thực email">
                  {this.props.user.email_verified ? (
                    <>
                      Đã xác thực <CheckCircleTwoTone />
                    </>
                  ) : (
                    'Chưa xác thực'
                  )}
                </Form.Item>
                <Form.Item
                  label="     "
                  name="password"
                  className="password-input"
                >
                  <Link to={PATHS.changePassword}>
                    <Button type="primary" className="b-r-4 primary-light">
                      Đổi mật khẩu{' '}
                    </Button>
                  </Link>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col xs={12} lg={6} xl={6}>
            <Card
              className="x-card mb-4 info-profile"
              title="Thông tin cá nhân"
            >
              <div className="profile-form">
                <ProfileFormInfo display="inline" />
              </div>
            </Card>
          </Col>
          <Col xs={12} className="mt-2">
            <Card
              className="x-card mb-4 card-profile-conective"
              title={
                <>
                  <div className="social__title">
                    <span className="title-classes title-classes-small">
                      Hồ sơ
                    </span>
                  </div>
                  <Row className="search-or-add-profile">
                    <Col xs="12" md={6} sm={12}>
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Tìm kiếm"
                        className="filter-tem-right-pro"
                        allowClear
                        onChange={this.onSearch}
                      />
                    </Col>
                    {/* <Col xs="12" md={6} sm={12} className="text-right">
                      <Button
                        type="primary"
                        className="btn-add-profile b-r-4"
                        onClick={() => this.openRequest()}
                      >
                        <i
                          className="fa fa-plus-circle mr-1"
                          aria-hidden="true"
                        />{' '}
                        Thêm hồ sơ
                      </Button>
                    </Col> */}
                  </Row>
                </>
              }
            >
              <Row>
                {list_profile &&
                  list_profile.length > 0 &&
                  list_profile.map((item) => {
                    item.link = `/profile/${CODE_REPO[item.code]}/${item.id}`;

                    return (
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={6}
                        xxl={4}
                        key={item.id}
                      >
                        <TemplateItem
                          refreshData={this.getProfiles}
                          item={item}
                          edit
                        />
                      </Col>
                    );
                  })}
                {list_profile && list_profile.length === 0 && (
                  <div className="text-center">Bạn chưa tạo hồ sơ</div>
                )}
              </Row>
            </Card>
          </Col>
          <Col xs={12} className="mt-2">
            <Card
              className="x-card mb-4 card-profile-conective"
              title={
                <div className="social__title">
                  <span className="title-classes title-classes-small">
                    Liên kết tài khoản
                  </span>
                  <div className="action-group-right flex-end">
                    <div
                      className="site-button-ghost-wrapper mr-3"
                      style={{ flex: 1 }}
                    >
                      <Button
                        className={`${activeTab === '1' ? 'active' : ''} mr-2`}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Đang theo dõi{' '}
                        <Badge
                          count={
                            lstConnected2.length > 0 ? lstConnected2.length : 0
                          }
                          className="site-badge-count"
                        />
                      </Button>
                      <Button
                        className={`${activeTab === '2' ? 'active' : ''} mr-2`}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        Theo dõi bạn{' '}
                        <Badge
                          count={
                            lstConnected1.length > 0 ? lstConnected1.length : 0
                          }
                          className="site-badge-count"
                        />
                      </Button>
                      <Button
                        className={`${activeTab === '3' ? 'active' : ''}`}
                        onClick={() => {
                          this.toggle('3');
                        }}
                      >
                        Đề nghị liên kết{' '}
                        <Badge
                          count={lstInvited.length > 0 ? lstInvited.length : 0}
                          className="site-badge-count"
                        />
                      </Button>
                      <Button
                        className={`${activeTab === '4' ? 'active' : ''}`}
                        onClick={() => {
                          this.toggle('4');
                        }}
                      >
                        Yêu cầu đã gửi{' '}
                        <Badge
                          count={lstRequest.length > 0 ? lstRequest.length : 0}
                          className="site-badge-count"
                        />
                      </Button>
                    </div>
                    <ProfileFriend onClose={() => this.onClose()} />
                  </div>
                </div>
              }
            >
              <TabContent className="tab-cus" activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    {lstConnected2.length > 0 ? (
                      lstConnected2.map((item) => {
                        const repairItem = formatProfile(
                          lstUser,
                          item,
                          this.props.user.id,
                        );

                        return (
                          <Col
                            xs="12"
                            md="6"
                            lg={3}
                            className="mb-4"
                            key={repairItem.id}
                          >
                            <ProfileItem
                              item={repairItem}
                              type={1}
                              delConnective={this.delConnective}
                              confirmInvited={this.confirmInvited}
                            />
                          </Col>
                        );
                      })
                    ) : (
                      <div className="p-1 text-center">
                        <i> Không có dữ liệu</i>
                      </div>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    {lstConnected1.length > 0 ? (
                      lstConnected1.map((item) => {
                        item = formatProfile(lstUser, item, this.props.user.id);

                        return (
                          <Col
                            xs="12"
                            md="6"
                            lg={3}
                            className="mb-4"
                            key={item.id}
                          >
                            <ProfileItem
                              item={item}
                              type={3}
                              delConnective={this.delConnective}
                              confirmInvited={this.confirmInvited}
                            />
                          </Col>
                        );
                      })
                    ) : (
                      <div className="p-1 text-center">
                        <i> Không có dữ liệu</i>
                      </div>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    {lstInvited.length > 0 ? (
                      lstInvited.map((item) => {
                        item = formatProfile(lstUser, item, this.props.user.id);

                        return (
                          <Col
                            xs="12"
                            md="6"
                            lg={3}
                            className="mb-4"
                            key={item.id}
                          >
                            <ProfileItem
                              item={item}
                              type={2}
                              delConnective={this.delConnective}
                              confirmInvited={this.confirmInvited}
                            />
                          </Col>
                        );
                      })
                    ) : (
                      <div className="p-1 text-center">
                        <i> Không có dữ liệu</i>
                      </div>
                    )}
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    {lstRequest.length > 0 ? (
                      lstRequest.map((item) => {
                        item = formatProfile(lstUser, item, item.created_by);

                        return (
                          <Col
                            xs="12"
                            md="6"
                            lg={3}
                            className="mb-4"
                            key={item.id}
                          >
                            <ProfileItem
                              item={item}
                              type={3}
                              delConnective={this.delConnective}
                              confirmInvited={this.confirmInvited}
                            />
                          </Col>
                        );
                      })
                    ) : (
                      <div className="p-1 text-center">
                        <i> Không có dữ liệu</i>
                      </div>
                    )}
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
        <Drawer
          closeIcon={
            <i className="fa fa-times-circle danger" aria-hidden="true" />
          }
          title=""
          width={400}
          onClose={this.onClose}
          visible={visibleDrawer}
          bodyStyle={{ paddingBottom: 80, marginTop: 80 }}
        >
          {visible == 2 && (
            <div className="text-center">
              <Avatar
                size={{
                  xs: 24,
                  sm: 32,
                  md: 40,
                  lg: 64,
                  xl: 80,
                  xxl: 80,
                }}
                src={user.avatar ? user.avatar : config.getAvatarDefault()}
              />
              <p className="mt-2">
                Bạn muốn gửi yêu cầu liên kết{' '}
                <span className="secondary">
                  {user.fullname ? `${user.fullname} - ` : ''} {user.username}
                </span>
              </p>
              <div className="btn-gr-join flex flex-content-evenly">
                <Button className="primary" onClick={this.onBack}>
                  Nhập lại mã hồ sơ
                </Button>
                <Button type="primary" onClick={this.onSubmitRequest}>
                  Gửi yêu cầu
                </Button>
              </div>
            </div>
          )}

          {visible == 1 && (
            <div className="text-center">
              <Image
                size={{
                  xs: 24,
                  sm: 32,
                  md: 40,
                  lg: 64,
                  xl: 80,
                  xxl: 80,
                }}
                src={icNetwork}
                preview={false}
                shape="square"
              />
              <RequestForm
                visibleDrawer={visibleDrawer}
                type={2}
                showDrawer={this.showDrawer}
              />
            </div>
          )}
        </Drawer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Profile);
